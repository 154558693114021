/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Tab } from "@mui/material";
import { TFunction } from "i18next";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import AllInclusiveOutlinedIcon from "@mui/icons-material/AllInclusiveOutlined";

import SegmentedToggle from "../../../../../../SegmentedToggle/SegmentedToggle";
import { SEGMENTED_TOGGLE_TAB_SIZES } from "../../../../../../SegmentedToggle/types";

import { TABLE_NAMES, useMediaQueries } from "../../../../../../../shared";
import SelectedIcon from "../../../../../../../assets/customIcons/actions/SelectedIcon";

interface SchedulesSourceTabsProps {
  tabValue: number;
  onTabChange: (_event: React.ChangeEvent<{}>, newValue: number) => void;
  t: TFunction<"translation", undefined>;
  disableSelectedTab: boolean;
  disableFileteredTab: boolean;
  resourceName: TABLE_NAMES;
}

const SchedulesSourceTabs = ({
  tabValue,
  onTabChange,
  t,
  disableSelectedTab,
  disableFileteredTab,
  resourceName,
}: SchedulesSourceTabsProps) => {
  const { fromSm } = useMediaQueries();

  return (
    <SegmentedToggle
      data-testid="tri-source-tabs"
      value={tabValue}
      size={SEGMENTED_TOGGLE_TAB_SIZES.Small}
      fullWidth={fromSm}
      tabWidth="277px"
      onChange={onTabChange}
      css={css({ marginBottom: "16px" })}
    >
      <Tab
        data-testid="selected-items-tab"
        label={t("Button##selected")}
        icon={<SelectedIcon />}
        className={!disableSelectedTab ? "enabled" : undefined}
        disabled={disableSelectedTab}
      />

      <Tab
        data-testid="filtered-items-tab"
        label={t("Button##filtered")}
        icon={<FilterAltOutlinedIcon />}
        className={!disableFileteredTab ? "enabled" : undefined}
        disabled={disableFileteredTab}
      />
      <Tab
        data-testid="all-items-tab"
        label={t(`Button##all ${resourceName}`)}
        icon={<AllInclusiveOutlinedIcon />}
        className="enabled"
        disabled={false}
      />
    </SegmentedToggle>
  );
};

export default SchedulesSourceTabs;
