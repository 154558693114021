import { CSSObject } from "@emotion/react";
import { ThemeColors } from "./../../shared/types/types";

export const getLeftMargin = (
  toSm: boolean | undefined,
  toLg: boolean | undefined,
  hasLoadingCustomStyle: boolean | undefined
) => {
  if (toSm) {
    return "16px";
  }

  if (toLg) {
    return "8px";
  }

  return hasLoadingCustomStyle ? "24px" : "16px";
};

interface StyleProps {
  toSm?: boolean;
  toLg?: boolean;
  fromMd?: boolean;
  hasLoadingCustomStyle?: boolean;
  style?: {};
  isLoading?: boolean;
  colors?: ThemeColors;
}

export const useStyles = ({
  toSm,
  toLg,
  fromMd,
  hasLoadingCustomStyle,
  style,
  isLoading,
  colors,
}: StyleProps = {}) =>
  ({
    filterButtonLoader: {
      marginLeft: getLeftMargin(toSm, toLg, hasLoadingCustomStyle),
    },
    filtersMainBlock: {
      display: "flex",
      alignItems: "center",
      "@media(max-width: 839px)": {
        flex: "1",
      },

      "@media(max-width: 599px)": {
        flex: "unset",
        marginLeft: "8px",
      },
    },
    filterMenu: {
      "& .MuiMenu-paper": {
        width: "296px",
      },
    },
    filterMenuInnerDiv: {
      padding: "16px",
    },
    filtersListBlock: {
      "& > div + div": {
        marginTop: "12px",
      },
    },
    filterDropdownCustomWidth: {
      width: "246px",

      "@media(max-width: 839px)": {
        width: "unset",
        flex: "1",
      },
    },
    renderRowLi: {
      ...style,
      opacity: isLoading ? "0" : "1",

      "& > div:hover": {
        background: `${colors?.gray100} !important`,
      },
    },
    cloneElementStyle: {
      padding: "12px 16px",
      justifyContent: "space-between",
      alignItems: "flex-start",
      columnGap: "8px",
      background: colors?.white,
    },
    filteredValuesDiv: {
      display: "flex",
      alignItems: "center",
      borderTop: `1px solid ${colors?.gray200}`,
      padding: isLoading ? "8px 24px 8px 24px" : "7px 24px 8px 24px",
      minHeight: "36px",
    },
    filtersTabsDiv: {
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
      columnGap: "6px",
      rowGap: "6px",
    },
    separateFilterDropdown: {
      marginRight: "16px",
      width: "246px",

      "@media(max-width: 839px)": {
        width: "unset",
        flex: "1",
        marginRight: "0",
      },
    },
  } as CSSObject);
