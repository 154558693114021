/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { Paper, Typography } from "@mui/material";

import {
  DownloadHistoryDataDto,
  DownloadStatusLiveStatusesType,
} from "../types";
import DownloadHistorySingleDay from "../DownloadHistorySingleDay/DownloadHistorySingleDay";
import { useStyles } from "../styles";

import AccordionDetailsCard from "../../AccordionDetailsCard/AccordionDetailsCard";

import useDownloadStatusFunctions from "../../../hooks/useDownloadStatusFunctions";

import { useMediaQueries } from "../../../../../../../shared";
import TextLink from "../../../../../../TextLink/TextLink";
import { TEXT_LINK_SIZES } from "../../../../../../TextLink/types";

interface DownloadHistoryProps {
  downloadHistoryData: DownloadHistoryDataDto[] | undefined;
  downloadLiveStatuses: DownloadStatusLiveStatusesType | null;
  statusContentHeight: number;
  isHistoryLoading?: boolean;
  showErrorBanner?: boolean;
}

const DownloadHistory = ({
  downloadHistoryData,
  downloadLiveStatuses,
  statusContentHeight,
  isHistoryLoading,
  showErrorBanner,
}: DownloadHistoryProps) => {
  const { renderStepsLoader } = useDownloadStatusFunctions({
    downloadLiveStatuses,
  });
  const { fromMd } = useMediaQueries();
  const { t } = useTranslation();

  const [showFullList, setShowFullList] = useState<boolean>(false);

  const { historyEmptyStatePaper } = useStyles({
    fromMd,
    statusContentHeight,
    showErrorBanner,
  });

  const INITIAL_ITEMS_TO_SHOW = 3;
  const HISTORY_LOADER_HEIGHT = "168px";

  const showHistoryDetails =
    !isHistoryLoading && downloadHistoryData && downloadHistoryData.length > 0;

  const hasMoreThanInitialItems =
    downloadHistoryData && downloadHistoryData.length > INITIAL_ITEMS_TO_SHOW;

  const showDownloadHistoryList = (
    downloadHistoryData: DownloadHistoryDataDto[]
  ) =>
    downloadHistoryData?.map(
      (historyObject: DownloadHistoryDataDto, index: number) =>
        showFullList || (!showFullList && index < INITIAL_ITEMS_TO_SHOW) ? (
          <DownloadHistorySingleDay
            key={historyObject.date}
            historyObject={historyObject}
            itemsCount={downloadHistoryData.length}
            index={index}
          />
        ) : null
    );

  const handleTextlinkClick = () => {
    setShowFullList(true);
  };

  const showHistoryEmptyState = !showHistoryDetails && (
    <Paper elevation={0} variant="outlined" css={css(historyEmptyStatePaper)}>
      <Typography variant="body1">
        {t("Download history##no history")}
      </Typography>
    </Paper>
  );

  const showHistoryAccordionList = showHistoryDetails && (
    <>
      <AccordionDetailsCard
        showTitleArea={false}
        customStyle={{ margin: fromMd ? "16px 0 0 0" : 0 }}
      >
        {showDownloadHistoryList(downloadHistoryData)}
      </AccordionDetailsCard>

      {!showFullList && hasMoreThanInitialItems && (
        <TextLink
          size={TEXT_LINK_SIZES.Small}
          onClick={handleTextlinkClick}
          customStyle={{
            marginTop: "8px",
          }}
        >
          {t("General##show older")}
        </TextLink>
      )}
    </>
  );

  return (
    <div data-testid="download-history">
      {isHistoryLoading && (
        <div css={css({ marginTop: "16px" })}>
          {renderStepsLoader(HISTORY_LOADER_HEIGHT, "download-history-loader")}
        </div>
      )}

      {!isHistoryLoading && (showHistoryAccordionList || showHistoryEmptyState)}
    </div>
  );
};

export default DownloadHistory;
