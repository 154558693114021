/** @jsxImportSource @emotion/react */
import { css, CSSObject } from "@emotion/react";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";

import ScheduleCardActions from "./ScheduleCardActions";
import ScheduleCardLoader from "../ScheduleCardLoader";
import ScheduleCardTitle from "./ScheduleCardTitle";
import SingleScheduleCardContent from "./ScheduleCardContent";

import { SCHEDULES_NAMES, ScheduleDataDto } from "../../../types";
import { useStyles } from "../../../styles";

import BannerMessage from "../../../../BannerMessage/BannerMessage";
import { BANNER_MESSAGE_STATUSES } from "../../../../BannerMessage/types";

import { TABLE_NAMES, useMediaQueries } from "../../../../../shared";
import { useAuth } from "../../../../../context/Auth/AuthProvider";
import { USER_PERMISSIONS } from "../../../../../roles/types/enums";
import LinkMainIcon from "../../../../../assets/customIcons/schedules/LinkMainIcon";
import LinkAttachedIcon from "../../../../../assets/customIcons/schedules/LinkAttachedIcon";
import LinkDetachedIcon from "../../../../../assets/customIcons/schedules/LinkDetachedIcon";

interface SingleScheduleCardProps {
  scheduleData: ScheduleDataDto | null;
  globalScheduleExists: boolean;
  resourceName: TABLE_NAMES;
  getSchedulesData: () => Promise<void>;
  index?: number;
  isScheduleDataLoading?: boolean;
  isCreateNewSchedule?: boolean;
  isSelected?: boolean;
  handleCardClick?: (
    isCreateNewSchedule: boolean | undefined,
    scheduleData: ScheduleDataDto | null
  ) => void;
}

const SingleScheduleCard = ({
  scheduleData,
  globalScheduleExists,
  resourceName,
  getSchedulesData,
  index,
  isScheduleDataLoading,
  isCreateNewSchedule,
  isSelected,
  handleCardClick,
}: SingleScheduleCardProps) => {
  const { getAccessStatus, user } = useAuth();
  const { t } = useTranslation();

  const { toSm, to939, toLg, fromLg } = useMediaQueries();

  const isVehiclesSchedule = resourceName === TABLE_NAMES.Vehicles;

  const {
    singleScheduleCard,
    singleScheduleCardClickable,
    createNewSchedule,
    createNewScheduleIcon,
  } = useStyles(isSelected, isCreateNewSchedule, isVehiclesSchedule);

  const isClickable = globalScheduleExists && !isScheduleDataLoading;

  const mediaQuery = toSm || to939 || toLg || fromLg;

  const generateResourceMinWidth = () => {
    switch (mediaQuery) {
      case toSm:
        return "100%";
      case to939:
        return "calc((100% / 2) - 8px)";
      default:
        return "calc((100% / 3) - 11px)";
    }
  };

  const titleWidth = "calc(100% - 30px)";

  const allowedToInteract = getAccessStatus(USER_PERMISSIONS.ManageSchedules);

  const showGlobalScheduleInheritanceIcon =
    scheduleData?.name === SCHEDULES_NAMES.GlobalSchedule &&
    scheduleData?.inheritanceEnabled;

  const showRootCompanyInheritanceIcon = user?.rootCompanySelected;

  const showChildrenAttachedInheritanceIcon =
    !user?.rootCompanySelected && scheduleData?.attachedToRoot;

  const showChildrenDetachedInheritanceIcon =
    !user?.rootCompanySelected && !scheduleData?.attachedToRoot;

  const generateLinkedIcon = () => {
    const rootCompanyInheritanceIcon = showRootCompanyInheritanceIcon && (
      <LinkMainIcon />
    );
    const childrenAttachedInheritanceIcon = showChildrenAttachedInheritanceIcon && (
      <LinkAttachedIcon />
    );
    const childrenDetachedInheritanceIcon = showChildrenDetachedInheritanceIcon && (
      <LinkDetachedIcon />
    );

    return (
      rootCompanyInheritanceIcon ||
      childrenAttachedInheritanceIcon ||
      childrenDetachedInheritanceIcon
    );
  };

  const generateLinkedIconTooltip = () => {
    const rootCompanyInheritanceIconTooltip =
      showRootCompanyInheritanceIcon &&
      t("Schedules##global schedule##inherited settings");

    const childrenAttachedInheritanceIconTooltip =
      showChildrenAttachedInheritanceIcon &&
      t("Schedules##global schedule##connected to root company");

    const childrenDetachedInheritanceIconTooltip =
      showChildrenDetachedInheritanceIcon &&
      t("Schedules##global schedule##not connected to root company");

    return (
      rootCompanyInheritanceIconTooltip ||
      childrenAttachedInheritanceIconTooltip ||
      childrenDetachedInheritanceIconTooltip
    );
  };

  const handleClick = () => {
    allowedToInteract && handleCardClick?.(isCreateNewSchedule, scheduleData);
  };

  const descriptionType = allowedToInteract
    ? "description"
    : "description read-only";

  return (
    <div
      data-testid="single-schedule-card"
      css={css({ position: "relative", width: generateResourceMinWidth() })}
    >
      {!isCreateNewSchedule && !isScheduleDataLoading && allowedToInteract && (
        <ScheduleCardActions
          resourceName={resourceName}
          scheduleName={scheduleData?.name ?? SCHEDULES_NAMES.GlobalSchedule}
          scheduleId={scheduleData?.id ?? null}
          scheduleData={scheduleData}
          getSchedulesData={getSchedulesData}
          user={user}
        />
      )}

      <div
        onClick={handleClick}
        css={css([
          singleScheduleCard,
          isClickable && allowedToInteract && singleScheduleCardClickable,
        ])}
      >
        {isScheduleDataLoading ? (
          index !== 2 && <ScheduleCardLoader />
        ) : (
          <>
            {isCreateNewSchedule && (
              <div css={css(createNewSchedule)} id="create-new-schedule-card">
                <AddOutlinedIcon
                  sx={createNewScheduleIcon as CSSObject}
                  onClick={handleClick}
                />
                <Typography variant="body2">
                  {t("Schedules##create new schedule")}
                </Typography>
              </div>
            )}

            {scheduleData && (
              <>
                <ScheduleCardTitle
                  titleWidth={titleWidth}
                  resourceName={resourceName}
                  scheduleName={scheduleData.name}
                  globalLinkIcon={
                    showGlobalScheduleInheritanceIcon
                      ? generateLinkedIcon()
                      : null
                  }
                  globalLinkTooltip={
                    showGlobalScheduleInheritanceIcon
                      ? generateLinkedIconTooltip()
                      : undefined
                  }
                />

                <SingleScheduleCardContent
                  scheduleData={scheduleData}
                  isVehiclesSchedule={isVehiclesSchedule}
                />
              </>
            )}

            {/* Not configerd schedule card */}
            {!globalScheduleExists && !scheduleData && (
              <>
                <ScheduleCardTitle
                  titleWidth={titleWidth}
                  resourceName={resourceName}
                  scheduleName={SCHEDULES_NAMES.GlobalSchedule}
                />

                <BannerMessage
                  data-testid="no-global-schedule-configured-banner"
                  status={BANNER_MESSAGE_STATUSES.Warning}
                  title={t("Schedules##global schedule##not configured title")}
                  description={t(
                    `Schedules##global schedule##not configured ${descriptionType}`
                  )}
                />
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default SingleScheduleCard;
