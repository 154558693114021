const LinkMainIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.85704 7.02694L11.747 5.13694C11.747 5.13694 12.687 4.19694 11.277 2.77694C9.86704 1.36694 8.91704 2.30694 8.91704 2.30694C8.91704 2.30694 7.50704 3.71694 6.55704 4.66694C5.61704 5.60694 5.61704 6.55694 6.08704 7.02694"
        stroke="#111729"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.19694 7.02734L2.30694 8.91734C2.30694 8.91734 1.36694 9.85734 2.77694 11.2773C4.18694 12.6873 5.13694 11.7473 5.13694 11.7473C5.13694 11.7473 6.31694 10.5673 7.25694 9.62734C8.19694 8.68734 8.43694 7.50734 7.96694 7.03734"
        stroke="#111729"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.657 13.6572L10.207 14.5372C10.147 14.5772 10.077 14.5972 10.007 14.5872C9.93698 14.5872 9.87698 14.5572 9.82698 14.5172C9.77698 14.4772 9.73698 14.4272 9.70698 14.3672C9.67698 14.3072 9.67698 14.2372 9.68698 14.1572L10.077 12.4972L8.78698 11.3872C8.72698 11.3372 8.69698 11.2772 8.67698 11.2072C8.66698 11.1372 8.66698 11.0772 8.68698 11.0072C8.70698 10.9472 8.74698 10.8872 8.79698 10.8472C8.84698 10.8072 8.90698 10.7772 8.98698 10.7672L10.687 10.6172L11.347 9.05717C11.377 8.98717 11.417 8.93717 11.487 8.89717C11.547 8.85717 11.607 8.84717 11.677 8.84717C11.747 8.84717 11.807 8.86717 11.867 8.89717C11.927 8.92717 11.977 8.98717 12.007 9.05717L12.667 10.6172L14.367 10.7672C14.447 10.7772 14.517 10.8072 14.557 10.8472C14.607 10.8872 14.637 10.9372 14.667 11.0072C14.687 11.0672 14.697 11.1372 14.677 11.2072C14.667 11.2772 14.627 11.3372 14.567 11.3872L13.277 12.4972L13.667 14.1572C13.687 14.2372 13.677 14.2972 13.647 14.3672C13.617 14.4272 13.577 14.4772 13.527 14.5172C13.477 14.5572 13.417 14.5772 13.347 14.5872C13.277 14.5872 13.207 14.5772 13.147 14.5372L11.697 13.6572H11.657Z"
        fill="#111729"
      />
    </svg>
  );
};

export default LinkMainIcon;
