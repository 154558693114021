import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";

import AddVehicleDialogContent from "./AddVehicleDialogContent";
import useTutorialMode from "../../../../../TutorialMode/hooks/useTutorialMode";
import { STEP_GROUP } from "../../../../../TutorialMode/types";
import Dialog from "../../../../../Dialog/Dialog";
import { DialogWidth } from "../../../../../Dialog/types/enums";
import useTableItemDetails from "../../../../../DetailsSection/hooks/useTableItemdetails";
import useVehiclesFormInputs from "../../../../../../shared/hooks/useVehiclesFormInputs";
import {
  GeneralDialogProps,
  INPUT_LENGTH,
  useMediaQueries,
  VEHICLES_ACTIONS,
} from "../../../../../../shared";
import { axiosPrivate } from "../../../../../../api/axios";
import {
  searchQueryParams,
  tableDetailsResource,
} from "../../../../../../atoms/atoms";
import useResponse from "../../../../../../shared/hooks/useResponse";
import DialogActions from "../../../../../../shared/components/buttons/DialogActions";

const AddVehiclesDialog = ({ isOpen, onClose }: GeneralDialogProps) => {
  const { t } = useTranslation();
  const { toMd } = useMediaQueries();
  const { getTableItemDetails } = useTableItemDetails();
  const { handleCommonError } = useResponse();
  const {
    stepGroup,
    callTutorialWithTimeout,
    checkIfCorrectTutorialStep,
    setTutorialDetails,
  } = useTutorialMode();

  const {
    descriptionInputError,
    descriptionValue,
    imeiError,
    imeiIsValid,
    imeiValue,
    isVehicleDeactivated,
    nameInputError,
    vehicleName,
    handleChangeDescription,
    handleChangeImeiValue,
    handleChangeNameValue,
    handleToggleDeactivateVehicle,
    resetAllValues,
    setImeiError,
    validateDescriptionLength,
    validateImei,
    validateNameLength,
  } = useVehiclesFormInputs();

  const resource = useRecoilValue(tableDetailsResource);
  const searchParams = useRecoilValue(searchQueryParams);

  const [isLoading, setIsLoading] = useState(false);

  const nameIsValid =
    vehicleName && vehicleName.length <= INPUT_LENGTH.VehicleName;

  const descriptionIsValid =
    (descriptionValue && descriptionValue.length <= INPUT_LENGTH.Description) ||
    !descriptionValue;

  const isPayloadReadyToConfirm =
    vehicleName &&
    imeiValue &&
    nameIsValid &&
    descriptionIsValid &&
    imeiIsValid;

  const handleCloseAddVehicleDialog = (checkForTutorial?: boolean) => {
    resetAllValues();
    onClose(VEHICLES_ACTIONS.AddVehicle);

    if (
      checkIfCorrectTutorialStep(STEP_GROUP.TutorialAddVehicle, 0) &&
      checkForTutorial
    ) {
      callTutorialWithTimeout(true, 0, stepGroup, true, 200);
    }
  };

  const payload = {
    imei: imeiValue,
    name: vehicleName,
    comment: descriptionValue,
    disable: isVehicleDeactivated,
  };

  const handleSubmit = async () => {
    if (!imeiIsValid) {
      setImeiError(t("Dialog##imei not valid"));
    } else {
      try {
        setIsLoading(true);
        const response = await axiosPrivate.post(resource as string, payload);
        const idFromResponseLocation = response.headers.location;

        getTableItemDetails({
          itemId: idFromResponseLocation,
          successMessage: t("Alert##add vehicle success"),
          leaveTableDetailsNotUpdated: true,
          valueInSearchField: searchParams.vehiclesPage ?? undefined,
        });

        handleCloseAddVehicleDialog(false);

        if (checkIfCorrectTutorialStep(STEP_GROUP.TutorialAddVehicle, 0)) {
          setTutorialDetails({
            isTutorialOpen: toMd,
            stepIndex: toMd ? 2 : 1,
            stepGroup: STEP_GROUP.TutorialApplySchedule,
            isTutorialActive: true,
          });
        }
      } catch (error) {
        handleCommonError(error, t);
      }
    }
    setIsLoading(false);
  };

  return (
    <Dialog
      title={t("Dialog##add vehicle")}
      description={t("Dialog##add vehicle description")}
      extendToMaxWidth={toMd}
      isTitleSeparator
      isActionsSeparator
      open={isOpen}
      close={handleCloseAddVehicleDialog}
      submit={handleSubmit}
      width={!toMd ? DialogWidth.MinWidth : undefined}
      TransitionProps={{
        onExited: () => {
          resetAllValues();
        },
      }}
      actions={
        <DialogActions
          onClose={handleCloseAddVehicleDialog}
          isLoading={isLoading}
          isreadyToConfirm={isPayloadReadyToConfirm}
          confirmationButtonText={t("Button##add")}
          confirmationButtonTestId="add-vehicle-button"
          cancelButtonText={t("Button##close")}
          cancelButtonTestId="close-add-vehicle"
        />
      }
    >
      <AddVehicleDialogContent
        oncNameChange={handleChangeNameValue}
        onImeiValueChange={handleChangeImeiValue}
        onDescriptionChange={handleChangeDescription}
        toggleVehicleDeactivate={handleToggleDeactivateVehicle}
        imeiErrorText={imeiIsValid ? "" : imeiError}
        validateImei={validateImei}
        validateName={validateNameLength}
        nameErrorText={nameIsValid ? "" : nameInputError}
        validatedescription={validateDescriptionLength}
        descriptionErrorText={descriptionIsValid ? "" : descriptionInputError}
        isLoading={isLoading}
      />
    </Dialog>
  );
};

export default AddVehiclesDialog;
