import { AxiosError } from "axios";
import { TFunction } from "i18next";

export const generateErrorMessageToDisplay = (
  err: AxiosError<any, any>,
  t: TFunction
) => {
  const response = err?.response;
  const messageFromError = response?.data?.message;
  const messageToDisplay = messageFromError || t("General##unauthorized");

  return messageToDisplay;
};
