/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useContext } from "react";

import DeviceStatus from "./DeviceStatus/DeviceStatus";
import CompanyCards from "./CompanyCards/CompanyCards";

import { useStyles } from "../styles";

import { ThemeContext } from "../../../context/theme/ThemeContextProvider";
import { useMediaQueries } from "../../../shared";

const RightSide = () => {
  const { colors } = useContext(ThemeContext);
  const { to1099, from1099, fromLg } = useMediaQueries();

  const { rightSide } = useStyles({
    colors,
    to1099,
    from1099,
    fromLg,
  });

  return (
    <div css={css(rightSide)}>
      <DeviceStatus />
      <CompanyCards />
    </div>
  );
};

export default RightSide;
