const LinkAttachedIcon = () => {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.8504 8.02188L12.736 6.13628C12.736 6.13628 13.6788 5.19347 12.2646 3.77926C10.8504 2.36505 9.90762 3.30785 9.90762 3.30785C9.90762 3.30785 8.49336 4.72207 7.55056 5.66487C6.60778 6.60768 6.60778 7.55048 7.07916 8.02188"
          stroke="#111729"
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.19348 8.02197L3.30786 9.90757C3.30786 9.90757 2.36504 10.8504 3.77926 12.2646C5.19348 13.6788 6.13628 12.736 6.13628 12.736C6.13628 12.736 7.31478 11.5575 8.25758 10.6147C9.20044 9.67191 9.43611 8.49337 8.96471 8.02197"
          stroke="#111729"
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  };
  
  export default LinkAttachedIcon;
  