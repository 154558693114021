import {
  Column,
  COLUMN_NAMES,
  COLUMN_RESOURCES,
  ROUTES,
  TabsType,
} from "../../shared";

export const VEHICLES_FILES_TABLE_COLUMNS: Column[] = [
  {
    name: COLUMN_NAMES.Imei,
    isSticky: true,
    resource: COLUMN_RESOURCES.Imei,
    sortResource: COLUMN_RESOURCES.Imei,
    isDefaultActive: true,
  },
  {
    name: COLUMN_NAMES.VehicleNumber,
    isSticky: false,
    resource: COLUMN_RESOURCES.Number,
    sortResource: COLUMN_RESOURCES.VehicleNumber,
    isDefaultActive: true,
  },
  {
    name: COLUMN_NAMES.VehicleFileName,
    isSticky: false,
    resource: COLUMN_RESOURCES.FileName,
    isDefaultActive: true,
  },
  {
    name: COLUMN_NAMES.dataTypes,
    isSticky: false,
    resource: COLUMN_RESOURCES.FileTypes,
    isDefaultActive: true,
  },
  {
    name: COLUMN_NAMES.ActivityPeriod,
    isSticky: false,
    resource: COLUMN_RESOURCES.ActivitiesFrom,
    // nested property to sort:
    // sortResource: COLUMN_RESOURCES.ActivitiesFrom,
    secondCellValue: COLUMN_RESOURCES.ActivitiesTo,
    isDefaultActive: true,
  },
  {
    name: COLUMN_NAMES.DownloadTime,
    isSticky: false,
    resource: COLUMN_RESOURCES.DownloadTime,
    sortResource: COLUMN_RESOURCES.DownloadTime,
    isDefaultActive: true,
  },
];

export const DRIVERS_FILES_TABLE_COLUMNS: Column[] = [
  {
    name: COLUMN_NAMES.Driver,
    isSticky: true,
    resource: COLUMN_RESOURCES.DriverName,
    sortResource: COLUMN_RESOURCES.Driver,
    isDefaultActive: true,
  },
  {
    name: COLUMN_NAMES.CardNumber,
    isSticky: false,
    resource: COLUMN_RESOURCES.CardNumber,
    sortResource: COLUMN_RESOURCES.CardNumber,
    isDefaultActive: true,
  },
  {
    name: COLUMN_NAMES.DriverFileName,
    isSticky: false,
    resource: COLUMN_RESOURCES.FileName,
    isDefaultActive: true,
  },

  {
    name: COLUMN_NAMES.DownloadTime,
    isSticky: false,
    resource: COLUMN_RESOURCES.DownloadTime,
    sortResource: COLUMN_RESOURCES.DownloadTime,
    isDefaultActive: true,
  },
];

export const FILES_PAGE_TABS: TabsType[] = [
  {
    label: "General##vehicles",
    route: ROUTES.VehicleFiles,
  },
  {
    label: "General##drivers",
    route: ROUTES.DriverFiles,
  },
];
