/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";

import {
  containerWithDetailsStyle,
  containerWithoutDetailsStyle,
} from "./styleFunctions";

import Navbar from "../Navbar/Navbar";
import {
  SECTION_OPEN_DELAY_NOT_RESET,
  SECTION_OPEN_DELAY_ON_RESET,
} from "../DetailsSection/constants";
import MainInfoBanner from "../MainInfoBanner/MainInfoBanner";

import {
  desktopDrawerState,
  mainBannerHeightState,
  prevLocationState,
  resetDetailsSectionOnPageChange,
  tableData,
  tableDetailsSectionState,
} from "../../atoms/atoms";
import { desktopDetailsSectionWidth, useMediaQueries } from "../../shared";
import usePreviousLocation from "../../shared/hooks/usePreviousLocation";
import { routesWithoutDetails } from "../../shared/functions/functions";
import useGeneralHeights from "../../shared/hooks/useGeneralHeights";

interface MainContainerProps {
  children: React.ReactNode;
}

const MainContainer = ({ children, ...props }: MainContainerProps) => {
  const { toMd, toLg } = useMediaQueries();
  const location = useLocation();
  const [, setPreviousLocation] = useRecoilState(prevLocationState);
  const prevLocation: any = usePreviousLocation(location);

  const mainBannerHeight = useRecoilValue(mainBannerHeightState);

  const {
    OPEN_DESKTOP_DRAWER_WIDTH_WITH_PADDING,
    CLOSE_DESKTOP_DRAWER_WIDTH_WITH_PADDING,
  } = useGeneralHeights(mainBannerHeight);

  const tableRowData = useRecoilValue(tableData);
  const isDesktopDrawerOpen = useRecoilValue(desktopDrawerState);
  const isDesktopDetailsSectionOpen = useRecoilValue(tableDetailsSectionState);
  const [isDetailsResetNeeded, setIsDetailsResetNeeded] = useRecoilState(
    resetDetailsSectionOnPageChange
  );

  const delay =
    isDetailsResetNeeded && isDesktopDetailsSectionOpen
      ? SECTION_OPEN_DELAY_ON_RESET
      : SECTION_OPEN_DELAY_NOT_RESET;

  useEffect(() => {
    // check if delay on open details section is needed
    if (prevLocation && prevLocation.pathname !== location.pathname) {
      setIsDetailsResetNeeded(true);
      setPreviousLocation(prevLocation.pathname);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, setIsDetailsResetNeeded]);

  const generateMarginLeft: any = () => {
    if (toMd) {
      return "0";
    }
    if (!isDesktopDrawerOpen) {
      return CLOSE_DESKTOP_DRAWER_WIDTH_WITH_PADDING;
    }
    return OPEN_DESKTOP_DRAWER_WIDTH_WITH_PADDING;
  };

  const generateMarginRight = () => {
    if (!routesWithoutDetails(location.pathname)) {
      if (toMd) {
        return "0";
      }
      if (isDesktopDetailsSectionOpen && tableRowData.length > 0) {
        return desktopDetailsSectionWidth(toLg);
      } else {
        return "0";
      }
    }
    return "unset";
  };

  return (
    <>
      <MainInfoBanner />

      <Navbar />
      {routesWithoutDetails(location.pathname) ? (
        <main
          {...props}
          className="remaining-height"
          css={css(containerWithoutDetailsStyle(generateMarginLeft))}
        >
          {children}
        </main>
      ) : (
        <main
          {...props}
          className="remaining-height"
          css={css(
            containerWithDetailsStyle(
              generateMarginLeft,
              generateMarginRight,
              delay
            )
          )}
        >
          {children}
        </main>
      )}
    </>
  );
};

export default MainContainer;
