import { DownloadHistoryObjectType } from "../../types";

interface UseDownloadStatusHistoryFunctionsProps {
  formattedDate: any;
}

const useDownloadStatusHistoryFunctions = ({
  formattedDate,
}: UseDownloadStatusHistoryFunctionsProps) => {
  const mapDataAndChangeDateToLocalTime = (data: DownloadHistoryObjectType[]) =>
    // return history array with local time values
    data.map((item) => ({
      ...item,
      date: formattedDate(item.date),
    }));

  // Function to group and sort the data
  const createHistoryObjectArray = (data: any[]) => {
    const grouped: { [key: string]: DownloadHistoryObjectType[] } = {};

    // Grouping data by date (YYYY-MM-DD part only)
    data.forEach((item) => {
      const dateKey = item.date.split(" ")[0] + " 00:00:00"; // Extract date part and set fixed time

      if (!grouped[dateKey]) grouped[dateKey] = [];
      grouped[dateKey].push(item);
    });

    // Create a new sorted array without modifying the original
    const sortedHistory = (history: DownloadHistoryObjectType[]) =>
      history.slice().sort((a, b) => (b.date > a.date ? 1 : -1));

    // Convert grouped object into sorted array
    return Object.entries(grouped)
      .map(([date, history]) => ({
        date,
        history: sortedHistory(history), // Sort history by time descending
      }))
      .sort((a, b) => (b.date > a.date ? 1 : -1)); // Sort main array by date descending
  };

  return { createHistoryObjectArray, mapDataAndChangeDateToLocalTime };
};

export default useDownloadStatusHistoryFunctions;
