/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useTranslation } from "react-i18next";

import ScheduleCardRow from "./ScheduleCardRow";
import ScheduleCardFileTypesRow from "./ScheduleCardFileTypesRow";

import { useStyles } from "../../../styles";
import { ScheduleDataDto } from "../../../types";
import useScheduleRepeatValues from "../../../hooks/useScheduleRepeatValues/useScheduleRepeatValues";

import { useDateTime } from "../../../../../shared";

interface SingleScheduleCardContentProps {
  scheduleData: ScheduleDataDto;
  isVehiclesSchedule: boolean;
}

const SingleScheduleCardContent = ({
  scheduleData,
  isVehiclesSchedule,
}: SingleScheduleCardContentProps) => {
  const { t } = useTranslation();
  const { currentTimeFormatHoursMinutes, formattedDate } = useDateTime();
  const { getRepeatString } = useScheduleRepeatValues();

  const { scheduleCardRows, scheduleCardFirstColumn } = useStyles();

  const { repeat, customRepeat, nextDate } = scheduleData;

  const repeatString = getRepeatString(repeat, customRepeat);

  return (
    <div css={css(scheduleCardRows)}>
      <ScheduleCardRow
        resourceName={t("Schedules##next date")}
        resourceValue={formattedDate(nextDate, currentTimeFormatHoursMinutes)!}
        firstColumnCSS={scheduleCardFirstColumn}
      />

      <ScheduleCardRow
        resourceName={t("Schedules##repeat")}
        resourceValue={repeatString}
        firstColumnCSS={scheduleCardFirstColumn}
      />

      {isVehiclesSchedule && (
        <ScheduleCardFileTypesRow
          scheduleData={scheduleData}
          firstColumnCSS={scheduleCardFirstColumn}
        />
      )}
    </div>
  );
};

export default SingleScheduleCardContent;
