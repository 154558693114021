import { useContext, useEffect } from "react";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import { useTranslation } from "react-i18next";

import ProtectedRoute from "./ProtectedRoute";
import RestrictedRoute from "./RestrictedRoute";
import GlobalStyles from "./GlobalStyles";
import RoutesContent from "./RoutesContent";

import MainContainer from "../components/MainContainer/MainContainer";
import Snackbar from "../components/Snackbar/Snackbar";
import { useAuth } from "../context/Auth/AuthProvider";
import Login from "../Pages/Auth/Login";
import Drivers from "../Pages/Drivers/Drivers";
import Files from "../Pages/Files/VehicleFiles/VehicleFiles";
import FileSynchronization from "../Pages/FileSynchronization/FileSynchronization";
import Users from "../Pages/Users/Users";
import Vehicles from "../Pages/Vehicles/Vehicles";
import { LANGUAGES, NAVIGATION_ITEMS, ROUTES } from "../shared";
import AuthLoader from "../shared/components/loaders/AuthLoader";
import LanguageContext from "../context/language/LanguageContext";
import { setStoredLanguage } from "../shared/functions/functions";
import { isStoredTokens } from "../shared/functions/auth";
import PasswordResetRequest from "../Pages/Auth/components/PasswordReset/PasswordResetRequest/PasswordResetRequest";
import ResetPassword from "../Pages/Auth/components/PasswordReset/ResetPassword/ResetPassword";
import DriversFiles from "../Pages/Files/DriversFiles/DriversFiles";
import Companies from "../Pages/Companies/Companies";
import ErrorBoundary from "../shared/components/ErrorBoundary/ErrorBoundary";
import CreateAccount from "../Pages/Auth/components/UserInvitation/CreateAccount/CreateAccount";
import UsersInvitations from "../Pages/Users/UsersInvitations/UsersInvitations";
import Dashboard from "../Pages/Dashboard/Dashboard";
import { isItemVisible } from "../shared/layout";

const Routes = () => {
  const { language } = useContext(LanguageContext);

  const {
    isAuthLoading,
    setIsAuthLoading,
    getUserData,
    tokenInfo,
    user,
  } = useAuth();

  const { i18n } = useTranslation();

  useEffect(() => {
    !isStoredTokens() && setIsAuthLoading(false);
    tokenInfo && getUserData(true);
    i18n.changeLanguage(language);
    setStoredLanguage(language || LANGUAGES.English);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n, language, tokenInfo]);

  if (isAuthLoading) {
    return <AuthLoader />;
  }

  const navigateToDashboard = isItemVisible(NAVIGATION_ITEMS.Dashboard) && (
    <Navigate to={ROUTES.Dashboard} replace />
  );

  const navigateToVehicles = <Navigate to={ROUTES.Vehicles} replace />;

  const navigateHome = () =>
    tokenInfo && (navigateToDashboard || navigateToVehicles);

  // Define routes accessible only to authenticated users
  const routesForAuthenticatedOnly = [
    {
      path: ROUTES.Home,
      element: <ProtectedRoute />, // Wrap the component in ProtectedRoute
      children: [
        {
          path: ROUTES.Home,
          element: navigateHome(),
        },
        {
          path: ROUTES.Dashboard,
          element: tokenInfo && (
            <MainContainer>
              <RestrictedRoute itemName={NAVIGATION_ITEMS.Dashboard}>
                <Dashboard />
              </RestrictedRoute>
            </MainContainer>
          ),
        },
        {
          path: ROUTES.Vehicles,
          element: tokenInfo && (
            <MainContainer>
              <RestrictedRoute itemName={NAVIGATION_ITEMS.Vehicles}>
                <Vehicles />
              </RestrictedRoute>
            </MainContainer>
          ),
        },
        {
          path: ROUTES.Drivers,
          element: tokenInfo && (
            <MainContainer>
              <RestrictedRoute itemName={NAVIGATION_ITEMS.Drivers}>
                <Drivers />
              </RestrictedRoute>
            </MainContainer>
          ),
        },
        {
          path: ROUTES.VehicleFiles,
          element: tokenInfo && (
            <MainContainer>
              <RestrictedRoute itemName={NAVIGATION_ITEMS.Files}>
                <Files />
              </RestrictedRoute>
            </MainContainer>
          ),
        },
        {
          path: ROUTES.DriverFiles,
          element: tokenInfo && (
            <MainContainer>
              <RestrictedRoute itemName={NAVIGATION_ITEMS.Files}>
                <DriversFiles />
              </RestrictedRoute>
            </MainContainer>
          ),
        },
        {
          path: ROUTES.Companies,
          element: tokenInfo && (
            <MainContainer>
              <RestrictedRoute itemName={NAVIGATION_ITEMS.Companies}>
                <Companies />
              </RestrictedRoute>
            </MainContainer>
          ),
        },
        {
          path: ROUTES.Users,
          element: tokenInfo && (
            <MainContainer>
              <RestrictedRoute itemName={NAVIGATION_ITEMS.Users}>
                <Users />
              </RestrictedRoute>
            </MainContainer>
          ),
        },
        {
          path: ROUTES.UsersInvitations,
          element: tokenInfo && (
            <MainContainer>
              <RestrictedRoute itemName={NAVIGATION_ITEMS.Users}>
                <UsersInvitations />
              </RestrictedRoute>
            </MainContainer>
          ),
        },
        {
          path: ROUTES.FileSynchronization,
          element: tokenInfo && (
            <MainContainer>
              <RestrictedRoute itemName={NAVIGATION_ITEMS.FileSynchronization}>
                <FileSynchronization />
              </RestrictedRoute>
            </MainContainer>
          ),
        },
        {
          path: ROUTES.NotFound,
          element: navigateHome(),
        },
      ],
    },
  ];

  // Define routes accessible only to non-authenticated users
  const routesForNotAuthenticatedOnly = [
    {
      path: ROUTES.Home,
      element: <Login />,
    },
    {
      path: ROUTES.RequestReset,
      element: <PasswordResetRequest />,
    },
    {
      path: ROUTES.ResetPassword,
      element: <ResetPassword />,
    },
    {
      path: ROUTES.UserInvitation,
      element: <CreateAccount />,
    },
  ];

  const routerCurrentFutureFlags = {
    future: {
      v7_fetcherPersist: true,
      v7_relativeSplatPath: true,
      v7_normalizeFormMethod: true,
      v7_partialHydration: true,
      v7_skipActionErrorRevalidation: true,
    },
  };

  // Combine and conditionally include routes based on authentication status
  const router = createBrowserRouter(
    [
      {
        element: (
          <ErrorBoundary>
            <RoutesContent user={user} />
          </ErrorBoundary>
        ),

        children: [
          ...(!tokenInfo ? routesForNotAuthenticatedOnly : []),
          ...routesForAuthenticatedOnly,
        ],
      },
    ],
    // removes  React Router Future Flag Warnings
    routerCurrentFutureFlags
  );

  return (
    <>
      <GlobalStyles />
      <Snackbar />
      <RouterProvider
        router={router}
        // removes  React Router Future Flag Warning
        future={{
          v7_startTransition: true,
        }}
      />
    </>
  );
};

export default Routes;
