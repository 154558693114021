import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import useWebSocket from "react-use-websocket";
import { useTranslation } from "react-i18next";

import {
  DownloadLiveStatusDataType,
  DownloadStagesTypes,
  DownloadStatusesTypes,
} from "./types";
import DownloadMonitoringDialogContent from "./DownloadMonitoringDialogContent/DownloadMonitoringDialogContent";
import useDownloadStatusHistory from "./hooks/useDownloadStatusHistory";

import { DetailsDataType } from "../../../../types";

import Dialog from "../../../../../Dialog/Dialog";

import { useMediaQueries } from "../../../../../../shared";
import { ApiResources } from "../../../../../../api/resources";
import DialogActions from "../../../../../../shared/components/buttons/DialogActions";
import useAxiosPrivate from "../../../../../../api/hooks/useAxiosPrivate";
import useResponse from "../../../../../../shared/hooks/useResponse";

interface DownloadMonitoringDialogProps {
  open: boolean;
  onClose: () => void;
  detailsData: DetailsDataType;
}

const DownloadMonitoringDialog = ({
  open,
  onClose,
  detailsData,
}: DownloadMonitoringDialogProps) => {
  const { fromMd, toMd } = useMediaQueries();
  const { postData } = useAxiosPrivate();
  const { handleCommonError } = useResponse();
  const {
    getDownloadStatusHistory,
    downloadStatusHistory,
    isLoading,
  } = useDownloadStatusHistory();

  const { t } = useTranslation();

  const [socketUrl, setSocketUrl] = useState<string | null>(null);

  const isMounted = useRef<boolean>(false);
  const isHistoryMounted = useRef<boolean>(false);

  const options = useMemo(
    () => ({
      shouldReconnect: () => true,
      retryOnError: true,
    }),
    []
  );

  const { lastJsonMessage, getWebSocket } = useWebSocket(
    socketUrl,
    options,
    Boolean(socketUrl)
  );

  const dialogCustomWidth = fromMd ? 704 : undefined;

  useEffect(() => {
    // !isHistoryMounted.current is used to avoid unnecessary call to getDownloadStatusHistory
    if (open && !isHistoryMounted.current) {
      detailsData && getDownloadStatusHistory(detailsData.id, true);
      isHistoryMounted.current = true;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailsData, open]);

  useEffect(() => {
    const getWebsocketToken = async () => {
      try {
        const response = await postData(ApiResources.CreateWebsocketToken);
        setSocketUrl(
          `${process.env.REACT_APP_BASE_URL}${ApiResources.VehiclesLiveStatus}?accessToken=${response.data.token}&VehicleId=${detailsData?.id}`
        );
      } catch (error) {
        handleCommonError(error, t);
      }
    };

    //!isMounted.current is used to avoid the warning of "WebSocket connection is being closed before it has been fully established".
    if (open && !isMounted.current) {
      getWebsocketToken();
      isMounted.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  // update download history when process is terminated or downloading stage is completed
  useEffect(() => {
    lastJsonMessage &&
      detailsData &&
      checkIfHistoryShouldBeCalled(
        lastJsonMessage as DownloadLiveStatusDataType
      ) &&
      getDownloadStatusHistory(detailsData.id);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastJsonMessage, detailsData]);

  const checkIfHistoryShouldBeCalled = useCallback(
    (lastJsonMessage: DownloadLiveStatusDataType) => {
      const isProcessTerminated =
        lastJsonMessage.Status === DownloadStatusesTypes.ProcessTerminated;
      const isStageCompleted =
        lastJsonMessage.Status === DownloadStatusesTypes.StageComplete;
      const isDownloadingStage =
        lastJsonMessage.Stage === DownloadStagesTypes.Downloading;

      // return true if process is terminated or downloading stage is completed
      return isProcessTerminated || (isDownloadingStage && isStageCompleted);
    },
    []
  );

  const handleCloseDialog = () => {
    onClose();
    setSocketUrl(null);
    getWebSocket()?.close();
  };

  return (
    <>
      {detailsData && (
        <Dialog
          title={t(`Dialog##download status dialog title`, {
            vehicleNumber: detailsData.number,
          })}
          description={t(`Dialog##download status dialog description`)}
          open={open}
          close={handleCloseDialog}
          extendToMaxWidth={toMd || undefined}
          isActionsSeparator
          actions={
            <DialogActions
              onClose={handleCloseDialog}
              isLoading={false}
              hideAcceptButton
              isreadyToConfirm={null}
              cancelButtonText={t("Button##close")}
            />
          }
          isTitleSeparator
          width={dialogCustomWidth}
          customChildrenStyle={{
            scrollbarGutter: "stable",
            padding: fromMd ? "16px 16px 16px 24px" : "16px 12px 16px 24px",
          }}
        >
          <DownloadMonitoringDialogContent
            liveStatusData={lastJsonMessage as DownloadLiveStatusDataType}
            downloadHistoryData={downloadStatusHistory}
            isHistoryLoading={isLoading}
          />
        </Dialog>
      )}
    </>
  );
};

export default DownloadMonitoringDialog;
