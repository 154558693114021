/** @jsxImportSource @emotion/react */
import { css, CSSObject } from "@emotion/react";
import { useContext, useEffect, useRef, useState } from "react";
import moment, { Moment } from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import {
  DesktopDatePicker,
  DesktopDatePickerProps,
} from "@mui/x-date-pickers/DesktopDatePicker";
import { ExpandMoreRounded as SwitchViewIcon } from "@mui/icons-material";

import TextField from "./actionComponents/TextField";
import CustomDay from "./components/CustomDay";
import DateActionButtons from "./actionComponents/DateActionButtons";

import { useStyles } from "../styles";
import { DatePickerProps } from "../types";
import LeftArrowButton from "../actionComponents/LeftArrowButton";
import RightArrowButton from "../actionComponents/RightArrowButton";

import Label from "../../Label/Label";

import { ThemeContext } from "../../../context/theme/ThemeContextProvider";
import {
  DATE_FORMATS,
  INVALID_DATE,
  useDateTime,
  useMediaQueries,
} from "../../../shared";

const CustomDatePicker = ({
  setDate,
  externalErrorHandle,
  label,
  textFieldParams = {},
  customStyle,
  initialDate,
  createSchedule,
  everyNthDay,
  ...props
}: DatePickerProps & DesktopDatePickerProps<Moment>) => {
  const { colors } = useContext(ThemeContext);

  const { currentDateFormat, convertToCurrentTimezone } = useDateTime();
  const { toMd } = useMediaQueries();

  const [showActionBar, setShowActionBar] = useState(true);
  const [hasUserInteraction, setHasUserInteraction] = useState(false);

  const textStyles = {
    fontWeight: "600",
    lineHeight: "20px",
    letterSpacing: "0.15px",
  };

  const { datePickerOverride, datePickerSharedStyles } = useStyles(
    colors,
    textStyles,
    toMd,
    true
  );

  const initialValue: Moment | null = initialDate
    ? convertToCurrentTimezone(initialDate)
    : null;

  const [value, setValue] = useState<Moment | null>(initialValue || null);

  const isInitialRender = useRef(true);

  useEffect(() => {
    //Prevents handleFormChange from overloading other inputs on load
    if (value !== initialValue) handleValueChange();

    isInitialRender.current = false;
    // eslint-disable-next-line
  }, [value]);

  const handleValueChange = (resetValue?: boolean) => {
    if (value || !resetValue) {
      const isNotValid = !moment(value).isValid();

      const fwDate = isNotValid
        ? INVALID_DATE
        : moment(value).format(DATE_FORMATS.YearMonthDay);

      !isNotValid && setDate(fwDate);
    } else {
      setValue(null);
    }
  };

  const handleChange = (newValue: Moment | null) => {
    setHasUserInteraction(true);
    const utcDate = moment.utc(newValue);

    setValue(utcDate);
  };

  const handleClose = (props: any) => {
    props.onClose?.();
  };

  const userLocale = navigator.language || "en-US";

  const handleClearButtonClick = () => {
    let resetedValue;
    if (createSchedule) {
      resetedValue = null;
      setDate(INVALID_DATE);
    } else {
      resetedValue = initialValue;
    }

    setValue(resetedValue);
    setHasUserInteraction(false);
  };

  const handleApplyButtonClick = () => {
    props.onClose?.();
  };

  return (
    <div
      data-testid="custom-date-picker"
      css={css({
        ...customStyle,
      })}
    >
      <Label titleLeft={label} isRequired={textFieldParams.required} />

      <LocalizationProvider
        dateAdapter={AdapterMoment}
        adapterLocale={userLocale}
      >
        <DesktopDatePicker
          {...props}
          disabled={props.disabled}
          open={props.open}
          onOpen={props.onOpen}
          value={value}
          timezone={props.timezone ?? "UTC"}
          format={currentDateFormat}
          views={["year", "month", "day"]}
          onChange={handleChange}
          closeOnSelect={false}
          onClose={() => handleClose(props)}
          slots={{
            switchViewIcon: SwitchViewIcon,
            previousIconButton: LeftArrowButton,
            nextIconButton: RightArrowButton,
            textField: TextField,
            actionBar: () =>
              showActionBar
                ? DateActionButtons({
                    onClearButtonclick: handleClearButtonClick,
                    onApplyButtonclick: handleApplyButtonClick,
                    value: value,
                    hasUserInteraction: hasUserInteraction,
                    createSchedule: createSchedule,
                  })
                : null,

            day: (dayProps) =>
              CustomDay({
                nthDay: everyNthDay,
                value: value as any,
                colors,
                ...dayProps,
              }),
          }}
          // Hides action bar when not in day view
          onViewChange={(view: any) => {
            view === "day" ? setShowActionBar(true) : setShowActionBar(false);
            handleClearButtonClick();
          }}
          slotProps={{
            openPickerButton: {
              sx: {
                color: colors.textPlaceholder,
              },
            },

            textField: {
              timeZoneExplanatory: textFieldParams.timeZoneExplanatory,
              readOnly: true,

              // Open picker on input click
              onClick: (event: any) => {
                event.preventDefault();
                props.onOpen?.();
              },
            } as any,
            layout: {
              sx: [
                datePickerOverride as CSSObject,
                datePickerSharedStyles as CSSObject,
              ],
            },
          }}
        />
      </LocalizationProvider>
    </div>
  );
};

export default CustomDatePicker;
