import { useTranslation } from "react-i18next";

import { INPUT_LENGTH } from "../..";
import PaperBox from "../../../components/PaperBox/PaperBox";
import ToggleItemWithTooltip from "../../../components/ToggleItem/ToggleItemWithTooltip";
import { GenerateCompanySettingsPropsType } from "../../../Pages/Companies/types";

const useCompaniesFormInputs = ({ ...props }: any) => {
  const { t } = useTranslation();

  const {
    setNameInputError,
    setName,
    setDescriptionInputError,
    setDescription,
  } = props;

  const isNameValid = (name: string) => {
    const companyName = name.trim();

    return (
      name &&
      companyName.length <= INPUT_LENGTH.CompanyName &&
      companyName.length >= INPUT_LENGTH.MinCompanyName
    );
  };

  const isDescriptionValid = (description: string | null) =>
    (description && description.length <= INPUT_LENGTH.Description) ||
    !description;

  const validateNameLength = (e: any) => {
    const inputValue = e.target.value.trim();
    if (inputValue.length < INPUT_LENGTH.MinCompanyName) {
      setNameInputError(
        t("Dialog##name too short", {
          minNumber: INPUT_LENGTH.MinCompanyName,
        })
      );
    }
    if (inputValue.length > INPUT_LENGTH.CompanyName) {
      setNameInputError(
        t("Dialog##name not valid", {
          maxNumber: INPUT_LENGTH.CompanyName,
        })
      );
    }
    if (inputValue.length === 0) {
      setNameInputError(t("Dialog##name should not be empty"));
    }
  };

  const validateDescriptionLength = (e: any) => {
    const inputValue = e.target.value.trim();
    if (inputValue.length > INPUT_LENGTH.Description) {
      setDescriptionInputError(
        t("Dialog##description not valid", {
          maxNumber: INPUT_LENGTH.Description,
        })
      );
    }
  };

  const handleNameChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setName(e.target.value);
    validateNameLength(e);
    setNameInputError(null);
  };

  const handleChangeDescription = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setDescription(e.target.value);
    validateDescriptionLength(e);
    setDescriptionInputError(null);
  };

  const resetAllValues = () => {
    setName("");
    setDescription("");
    setNameInputError(null);
    setDescriptionInputError(null);
  };

  const companySettingsList = ({
    ...props
  }: GenerateCompanySettingsPropsType) => [
    {
      title: t("Dialog##auto add new drivers"),
      tooltipText: t("Dialog##auto add new drivers tooltip"),
      action: props.handleToggleAddNewDriver,
      isChecked: props.addNewDriver,
    },
    {
      title: t("Dialog##auto assign global schedule to drivers"),
      tooltipText: t("Dialog##auto assign global schedule to drivers tooltip"),
      action: props.handleToggleAssignGlobalScheduleToDriver,
      isChecked: props.assignGlobalScheduleToDriver,
    },
    {
      title: t("Dialog##auto assign global schedule to vehicles"),
      tooltipText: t("Dialog##auto assign global schedule to vehicles tooltip"),
      action: props.handleToggleAssignGlobalScheduleToVehicle,
      isChecked: props.assignGlobalScheduleToVehicle,
    },
  ];

  const generateCompanySettings = ({
    ...props
  }: GenerateCompanySettingsPropsType) => {
    const settingsList = companySettingsList({ ...props });

    return (
      <PaperBox
        boxTitle={t("Dialog##company settings")}
        customStyling={{ padding: "0 16px" }}
      >
        {settingsList.map((setting: any, index: number) => (
          <ToggleItemWithTooltip
            key={setting.title}
            isDisabled={props.isDisabled}
            totalItemsCount={settingsList.length}
            index={index}
            title={setting.title}
            tooltipText={setting.tooltipText}
            onToggle={setting.action}
            isChecked={setting.isChecked}
            testId={setting.title}
            readOnly={props.readonly}
          />
        ))}
      </PaperBox>
    );
  };

  return {
    validateNameLength,
    handleNameChangeValue,
    resetAllValues,
    validateDescriptionLength,
    handleChangeDescription,
    isNameValid,
    isDescriptionValid,
    generateCompanySettings,
  };
};

export default useCompaniesFormInputs;
