/** @jsxImportSource @emotion/react */
import { CSSObject } from "@emotion/react";
import { ExpandMoreRounded as SwitchViewIcon } from "@mui/icons-material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Box, Popper } from "@mui/material";
import { LocalizationProvider, DateCalendar } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment, { Moment } from "moment";
import { useRecoilState, useRecoilValue } from "recoil";
import { useLocation } from "react-router-dom";
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation";
import { useTranslation } from "react-i18next";

import { useStyles } from "./styles";
import ActionButtons from "./components/ActionButtons";
import CustomPickersDayBox from "./components/CustomPickersDayBox";

import { FILTER_KEYS, FILTER_NAMES } from "../Filters/types";
import InputField from "../InputField/InputField";
import { pageGlobalStateObjectResult } from "../Table/functions";
import useFilteredValues from "../Filters/hooks/useFilteredValues";
import useFilterDropdown from "../Filters/hooks/useFilterDropdown";
import RightArrowButton from "../DatePicker/actionComponents/RightArrowButton";
import LeftArrowButton from "../DatePicker/actionComponents/LeftArrowButton";
import { useStyles as datePickerStyles } from "../DatePicker/styles";

import { DATE_FORMATS, Filter, useDateTime } from "../../shared";
import { filterQueryParams, tableLoading } from "../../atoms/atoms";
import { ThemeContext } from "../../context/theme/ThemeContextProvider";
import useInitialLoading from "../../shared/hooks/useInitialLoading";
import RectangleLoader from "../../shared/components/loaders/RectangleLoader";

interface DateRangePickerProps {
  isInPopup?: boolean;
}

const DateRangePicker: React.FC = ({ isInPopup }: DateRangePickerProps) => {
  const { colors } = useContext(ThemeContext);

  const { isInitialLoading } = useInitialLoading();
  const { t } = useTranslation();
  const {
    formattedDate,
    switchedDashToSlashDateFormatWithoutTime,
  } = useDateTime();
  const location = useLocation();

  const filterName = FILTER_NAMES.DateRange;

  const { handleFilterChangeValue } = useFilterDropdown(filterName);
  const {
    handleDeleteFilterValue,
    dateRangeFilterObject,
    filteredValues,
  } = useFilteredValues();

  const isTableLoading = useRecoilValue(tableLoading);

  const [filterParams, setFilterParams] = useRecoilState(filterQueryParams);

  const [value, setValue] = useState<any>();
  const [filterValue, setFilterValue] = useState<Filter[]>([]);
  const [isReadyToCallFilters, setIsReadyToCallFilters] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [startDate, setStartDate] = useState<Moment | null>(null);
  const [endDate, setEndDate] = useState<Moment | null>(null);
  const [open, setOpen] = useState(false);
  const [isDayHovered, setIsDayHovered] = useState(false);
  const [showActionBar, setShowActionBar] = useState<boolean>(true);
  const [currentMonth, setCurrentMonth] = useState(moment()); // Store displayed month
  const [isOnInpuField, setIsOnInputField] = useState(false);

  const pickerRef = useRef<HTMLDivElement | null>(null); // Reference to the date picker

  const { datePickerOverride, filterDropdownCustomWidth } = useStyles(colors);
  const { datePickerSharedStyles } = datePickerStyles();

  const filterObjectFromLocalStorage = pageGlobalStateObjectResult(
    location.pathname,
    filterParams
  );

  // Close popper when clicking outside
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        pickerRef.current &&
        !pickerRef.current.contains(event.target as Node)
      ) {
        setOpen(false);
      }
    }
    //  call handleClickOutside only outside of input field,
    // otherwise toggle open state when clicking on the input field does not work properly
    !isOnInpuField &&
      document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [isOnInpuField]);

  useEffect(() => {
    const resetPlaceholderAndFilterValue = () => {
      setStartDate(null);
      setEndDate(null);
      setFilterValue([]);
    };

    const checkFilterNameAndSetValue = (filterObject: {
      [key: string]: Filter[];
    }) => {
      const filterValue: Filter[] = filterObject?.[filterName];

      if (filterValue !== undefined) {
        setFilterValue(filterValue);
      } else if (!filterValue) {
        resetPlaceholderAndFilterValue(); // reset values to the initial ones if filterValue is empty
      }
    };

    if (filterObjectFromLocalStorage?.dateRange?.length === 0) {
      resetPlaceholderAndFilterValue(); // reset values to the initial ones if no filter value in local storage
    } else {
      checkFilterNameAndSetValue(filterObjectFromLocalStorage);
    }

    // checkFilterNameAndSetValue(filterObjectFromLocalStorage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, filterParams, location.pathname, filterName]);

  useEffect(() => {
    // reset showActionBar to true if prev view was not day and popup was closed
    if (!open) {
      setShowActionBar(true);
    }

    if (filterValue.length > 0 && filteredValues.length > 0) {
      setStartDate(moment(filterValue[0].id));
      setEndDate(moment(filterValue[1].id));
      setValue([new Date(filterValue[0].id), new Date(filterValue[1].id)]);
    }
  }, [filterValue, setValue, filteredValues, open]);

  useEffect(() => {
    let isoStartDate = undefined;
    let isoEndDate = undefined;
    let dateFromTo = undefined;

    if (startDate && endDate) {
      isoStartDate = formattedDate(startDate, DATE_FORMATS.ISO_8601_no_z);
      isoEndDate = formattedDate(endDate, DATE_FORMATS.ISO_8601_no_z, true);

      dateFromTo = [
        { id: isoStartDate, name: FILTER_KEYS.DateRangeFrom },
        {
          id: isoEndDate,
          name: FILTER_KEYS.DateRangeTo,
        },
      ];
    }

    const hasFilterValue = filterValue.length > 0;

    // call handleFilterChangeValue method to store filter values into local storage and update filterParams
    !hasFilterValue &&
      dateFromTo &&
      isReadyToCallFilters &&
      handleFilterChangeValue({
        isTableLoading,
        filterObjectFromLocalStorage,
        newValue: dateFromTo as Filter[],
        filterName,
        setFilterParams,
        setValue: setFilterValue,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, startDate, endDate, isReadyToCallFilters]);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);

    // reset current month due to selected start date to remove visual bug of date range backgound color
    startDate ? setCurrentMonth(startDate) : setCurrentMonth(moment());

    setOpen(!open);
  };

  const handleDateChange = (date: Moment) => {
    setIsDayHovered(true);

    const resetDates = () => {
      setStartDate(date);
      setEndDate(null);
      // Reset current month due to selected start date to remove visual bug of date range background color
      setCurrentMonth(date);
    };

    const setEndDateAndClearFilter = () => {
      setFilterValue([]);
      setEndDate(date);
    };

    const shouldReset =
      !startDate ||
      endDate ||
      !(date.isAfter(startDate) || date.isSame(startDate, "day"));

    if (shouldReset) {
      resetDates();
      setIsReadyToCallFilters(false);
      return;
    }

    setEndDateAndClearFilter();
    setIsReadyToCallFilters(false);
  };

  const handleMonthChange = (newMonth: Moment) => {
    setCurrentMonth(newMonth);
  };

  const handleYearChange = () => {
    setStartDate(null);
    setEndDate(null);
  };

  const hasDateRangeValues = startDate && endDate;

  const formattedRange = hasDateRangeValues
    ? `${startDate.format(
        switchedDashToSlashDateFormatWithoutTime
      )} - ${endDate.format(switchedDashToSlashDateFormatWithoutTime)}`
    : t("Filter##Select Date Range");

  const handleClean = (event?: React.MouseEvent) => {
    if (hasDateRangeValues) {
      event?.preventDefault();

      const hasDateFilteredValues = filteredValues.some(
        (item) => item.id === FILTER_KEYS.Date
      );

      hasDateFilteredValues && handleDeleteFilterValue(dateRangeFilterObject);

      setStartDate(null);
      setEndDate(null);
    }
  };

  const handleApply = (event: React.MouseEvent) => {
    if (hasDateRangeValues) {
      event.preventDefault();
      setIsReadyToCallFilters(true);
    }
  };

  const userLocale = navigator.language || "en-US";
  // Get current year
  const currentYear = moment().year();

  // Calculate min and max dates (2 years back and current year)
  const minDate = moment().subtract(24, "months");
  const maxDate = moment()
    .year(currentYear)
    .endOf("year");

  return (
    <LocalizationProvider
      dateAdapter={AdapterMoment}
      adapterLocale={userLocale}
    >
      {isInitialLoading && !isInPopup ? (
        <RectangleLoader
          customStyle={filterDropdownCustomWidth}
          height={36}
          testId={`${filterName}-filter-loader`}
        />
      ) : (
        <Box>
          <InputField
            id={"date-range-input-field"}
            size="small"
            data-testid="dateRange-input-field"
            fullWidth
            placeholder={formattedRange}
            onClick={handleClick}
            onMouseEnter={() => setIsOnInputField(true)}
            onMouseLeave={() => setIsOnInputField(false)}
            iconRight={
              <InsertInvitationIcon sx={{ color: colors.textPlaceholder }} />
            }
            disabled={isTableLoading}
          />

          <Popper
            open={open}
            anchorEl={anchorEl}
            placement="bottom-start"
            sx={{ zIndex: 10000 }}
          >
            {/* bgcolor does not accept colors from the context values - it accepts only a string */}
            <Box ref={pickerRef} bgcolor="white" borderRadius={2} boxShadow={3}>
              <DateCalendar
                value={startDate}
                disableFuture
                readOnly={isTableLoading}
                onChange={handleDateChange}
                onMonthChange={handleMonthChange}
                onYearChange={handleYearChange}
                views={["year", "day"]}
                minDate={minDate}
                maxDate={maxDate}
                slots={{
                  day: (props: any) =>
                    CustomPickersDayBox({
                      props: props,
                      isDayHovered: isDayHovered,
                      setIsDayHovered: setIsDayHovered,
                      hasDateRangeValues: Boolean(hasDateRangeValues),
                    }),
                  switchViewIcon: SwitchViewIcon,
                  previousIconButton: LeftArrowButton,
                  nextIconButton: RightArrowButton,
                }}
                slotProps={
                  {
                    day: (ownerState: any) => {
                      const date = ownerState.day;
                      const isSameMonth = date.month() === currentMonth.month();

                      return {
                        isInRange:
                          startDate &&
                          endDate &&
                          ownerState.day.isBetween(
                            startDate,
                            endDate,
                            "day",
                            "[]"
                          ),
                        isStart: startDate?.isSame(ownerState.day, "day"),
                        isEnd: endDate?.isSame(ownerState.day, "day"),
                        isRowStart: date.weekday() === 0,
                        isRowEnd: date.weekday() === 6,
                        issamemonth: `${isSameMonth}`,
                      };
                    },
                  } as any
                }
                // Hides action bar when not in day view
                onViewChange={(view: any) => {
                  if (view === "day") {
                    setShowActionBar(true);
                  } else {
                    setShowActionBar(false);
                  }
                }}
                sx={[
                  datePickerOverride as CSSObject,
                  datePickerSharedStyles as CSSObject,
                ]}
              />
              {showActionBar && (
                <ActionButtons
                  onClearButtonclick={handleClean}
                  onApplyButtonclick={handleApply}
                  hasValues={Boolean(hasDateRangeValues)}
                  isLoading={isTableLoading}
                />
              )}
            </Box>
          </Popper>
        </Box>
      )}
    </LocalizationProvider>
  );
};

export default DateRangePicker;
