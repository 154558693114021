import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import useDownloadStatusHistoryFunctions from "./useDownloadStatusHistoryFunctions/useDownloadStatusHistoryFunctions";

import { ApiResources } from "../../../../../../../api/resources";
import useAxiosPrivate from "../../../../../../../api/hooks/useAxiosPrivate";
import useResponse from "../../../../../../../shared/hooks/useResponse";
import { ROUTES, useDateTime } from "../../../../../../../shared";
import { removeSlashFromPath } from "../../../../../../../shared/functions/functions";

const useDownloadStatusHistory = () => {
  const { getData } = useAxiosPrivate();
  const { handleCommonError } = useResponse();
  const { t } = useTranslation();
  const { formattedDate } = useDateTime();

  const {
    mapDataAndChangeDateToLocalTime,
    createHistoryObjectArray,
  } = useDownloadStatusHistoryFunctions({ formattedDate });

  const [downloadStatusHistory, setDownloadStatusHistory] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getDownloadStatusHistory = useCallback(
    async (VehicleId: string, shouldHaveLoader?: boolean) => {
      try {
        shouldHaveLoader && setIsLoading(true);
        const response = await getData(
          `${removeSlashFromPath(ROUTES.Vehicles)}/${VehicleId}/${
            ApiResources.DownloadsHistory
          }`
        );

        const downloadHistoryData = createHistoryObjectArray(
          mapDataAndChangeDateToLocalTime(response.data)
        );

        response.data && setDownloadStatusHistory(downloadHistoryData);
      } catch (error) {
        handleCommonError(error, t);
      } finally {
        shouldHaveLoader && setIsLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getData, setDownloadStatusHistory, handleCommonError]
  );

  return {
    getDownloadStatusHistory,
    downloadStatusHistory,
    isLoading,
  };
};

export default useDownloadStatusHistory;
