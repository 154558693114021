/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Fragment, useEffect, useState } from "react";
import useResizeObserver from "use-resize-observer";

import {
  DownloadHistoryDataDto,
  DownloadLiveStatusDataType,
  DownloadStatusesTypes,
} from "../types";
import { useStyles } from "../styles";
import DownloadHistory from "../DownloadHistory/DownloadHistory";

import useDownloadStatus from "../../../hooks/useDownloadStatus";
import useDownloadStatusFunctions from "../../../hooks/useDownloadStatusFunctions";

import { useMediaQueries } from "../../../../../../../shared";

interface DownloadMonitoringDialogContentProps {
  liveStatusData: DownloadLiveStatusDataType;
  downloadHistoryData?: DownloadHistoryDataDto[];
  isHistoryLoading?: boolean;
}

const DownloadMonitoringDialogContent = ({
  liveStatusData,
  downloadHistoryData,
  isHistoryLoading,
}: DownloadMonitoringDialogContentProps) => {
  const { fromMd } = useMediaQueries();
  const { downloadLiveStatuses, isLoading } = useDownloadStatus();

  const {
    successStep,
    currentStep,
    notCompletedStep,
    renderStepsLoader,
    renderErrorBanner,
  } = useDownloadStatusFunctions({
    downloadLiveStatuses,
  });

  const { ref, height = 1 } = useResizeObserver();

  const [currentValueIndex, setCurrentValueIndex] = useState<number | null>(
    null
  );
  const [statusContentHeight, setStatusContentHeight] = useState<number>(0);

  const { downloadStatContentContainer } = useStyles({ fromMd });

  const showErrorBanner =
    liveStatusData?.Status === DownloadStatusesTypes.InsufficientCredits ||
    liveStatusData?.Status === DownloadStatusesTypes.VehicleDeactivated ||
    liveStatusData?.Status === DownloadStatusesTypes.NoSchedulesApplied;

  const MAX_CONTENT_HEIGHT = 366;
  const CONTENT_PADDING_TOP_AND_BOTTOM = 32;
  const MAX_CONTENT_HEIGHT_WITHOUT_PADDINGS =
    MAX_CONTENT_HEIGHT - CONTENT_PADDING_TOP_AND_BOTTOM;

  useEffect(() => {
    setStatusContentHeight(height);
  }, [height, setStatusContentHeight]);

  useEffect(() => {
    downloadLiveStatuses &&
      setCurrentValueIndex(
        downloadLiveStatuses?.stages.findIndex(
          (stage: any) => stage === liveStatusData?.Stage
        )
      );
  }, [liveStatusData, downloadLiveStatuses, downloadHistoryData, fromMd]);

  const showCurrentStageInAList = () =>
    downloadLiveStatuses &&
    liveStatusData &&
    downloadLiveStatuses.stages.map((stage: any, index: number) => {
      const isCurrentStage = index === currentValueIndex;
      const passedSuccessStage = currentValueIndex && index < currentValueIndex;

      return (
        <Fragment key={stage}>
          {showErrorBanner
            ? notCompletedStep(index, stage)
            : currentStep(
                isCurrentStage,
                index,
                stage,
                liveStatusData.Finished,
                liveStatusData
              ) ||
              successStep(Boolean(passedSuccessStage), index, stage) ||
              notCompletedStep(index, stage)}
        </Fragment>
      );
    });

  return (
    <>
      <div
        data-testid="download-status-dialog-content"
        css={css({
          height: fromMd ? `${MAX_CONTENT_HEIGHT_WITHOUT_PADDINGS}px` : "100%",
        })}
        ref={ref}
      >
        {!liveStatusData || isLoading ? (
          renderStepsLoader(undefined, "download_status_steps_loader")
        ) : (
          <>
            {showErrorBanner && renderErrorBanner(liveStatusData)}
            <div
              css={css(downloadStatContentContainer)}
              data-testid="stage-steppers"
            >
              {showCurrentStageInAList()}
            </div>
          </>
        )}

        {/* div with padding to make some space at the bottom when content overflows Y */}
        <div css={css({ paddingBottom: "16px" })}>
          <DownloadHistory
            downloadHistoryData={downloadHistoryData}
            downloadLiveStatuses={downloadLiveStatuses}
            isHistoryLoading={isHistoryLoading || !liveStatusData || isLoading}
            statusContentHeight={statusContentHeight}
            showErrorBanner={showErrorBanner}
          />
        </div>
      </div>
    </>
  );
};

export default DownloadMonitoringDialogContent;
