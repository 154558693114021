/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Table as MuiTable } from "@mui/material";
import { useContext } from "react";
import { noop } from "lodash";
import { useRecoilValue } from "recoil";
import { useLocation } from "react-router-dom";

import TableHead from "./components/TableHead";
import DesktopTableHeadActive from "./components/DesktopTableHeadActive";
import TableBody from "../TableBody/TableBody";
import { generateDesktopTableHeight } from "../../functions";
import useFilteredValues from "../../../Filters/hooks/useFilteredValues";
import { ThemeContext } from "../../../../context/theme/ThemeContextProvider";
import {
  generateDesktopTableStyle,
  ROUTES,
  useMediaQueries,
  useScroll,
} from "../../../../shared";
import {
  filtersHeight,
  mainBannerHeightState,
  selectedTableRows,
  tableLoading,
} from "../../../../atoms/atoms";
import useGeneralHeights from "../../../../shared/hooks/useGeneralHeights";

interface IDesktopDetailsProps {
  onScroll?: boolean;
  hasTabFilters?: boolean;
  hasDetails: boolean;
  hasSearch?: boolean;
}

const DesktopTable = ({
  onScroll = true,
  hasTabFilters,
  hasDetails,
  hasSearch = true,
}: IDesktopDetailsProps) => {
  const { colors } = useContext(ThemeContext);
  const { toMd, toLg } = useMediaQueries();
  const { scrollHorizontal, handleScrollHorizontal } = useScroll();
  const location = useLocation();
  const { filteredValues } = useFilteredValues();

  const isLoading = useRecoilValue(tableLoading);
  const selectedRows = useRecoilValue(selectedTableRows);
  const filtersHeightValue = useRecoilValue(filtersHeight);

  const mainBannerHeight = useRecoilValue(mainBannerHeightState);

  const {
    PAGE_HEADER_HEIGHT,
    NAVBAR_HEIGHT,
    TAB_FILTERS_HEIGHT,
    TABLE_TOOLBAR_HEIGHT,
    DESKTOP_TABLE_FOOTER_HEIGHT,
  } = useGeneralHeights(mainBannerHeight);

  const companiesPage = location.pathname === ROUTES.Companies;
  const hasFilteredValues = filteredValues.length > 0;
  const desktopTableHeight = generateDesktopTableHeight({
    hasTabFilters,
    toMd,
    toLg,
    hasSearch,
    hasFilteredValues,
    companiesPage,
    filtersHeightValue,
    PAGE_HEADER_HEIGHT,
    NAVBAR_HEIGHT,
    TAB_FILTERS_HEIGHT,
    TABLE_TOOLBAR_HEIGHT,
    DESKTOP_TABLE_FOOTER_HEIGHT,
  });

  return (
    <div
      data-testid="desktop-table"
      onScroll={onScroll ? handleScrollHorizontal : noop}
      css={css({
        overflowY: "auto",
        overflowX: companiesPage ? "hidden" : "auto",
        height: desktopTableHeight,
        scrollbarWidth: "thin",
        backgroundColor: companiesPage ? `${colors.gray50}` : "unset",
      })}
    >
      {selectedRows?.length > 0 && <DesktopTableHeadActive />}
      <MuiTable
        stickyHeader
        css={css(
          generateDesktopTableStyle(colors, companiesPage, isLoading) as any
        )}
      >
        <TableHead
          scrollHorizontal={scrollHorizontal}
          companiesPage={companiesPage}
        />
        <TableBody
          scrollHorizontal={scrollHorizontal}
          hasDetails={hasDetails}
        />
      </MuiTable>
    </div>
  );
};

export default DesktopTable;
