/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { SetStateAction, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { InputAdornment } from "@mui/material";
import { useRecoilValue } from "recoil";
import { v4 as uuidv4 } from "uuid";

import DownloadDataTypesCheckboxes from "./DownloadDataTypesCheckboxes";
import TimeInputLabel from "./TimeInputLabel/TimeInputLabel";

import {
  ActivityDates,
  REPEAT_NAME_VALUES,
  SCHEDULE_PAYLOAD_NAMES,
  ScheduleDataDto,
  ScheduleRepeatPeriod,
  TimeValue,
} from "../types";
import { useStyles } from "../styles";
import { useScheduleDateTimeValues } from "../hooks/useScheduleDateTimeValues";
import {
  disableField,
  disableFieldsForChildren,
  toggleSwitchTitle,
  toggleSwitchTooltip,
} from "../functions";

import InputField from "../../InputField/InputField";
import QueryDropdown from "../../QueryDropdown/QueryDropdown";
import Autocomplete from "../../Autocomplete/Autocomplete";
import CustomDatePicker from "../../DatePicker/CustomDatePicker/CustomDatePicker";
import BannerMessage, {
  BANNER_MESSAGE_STATUSES,
} from "../../BannerMessage/BannerMessage";

import {
  CombinedDataTypes,
  DATE_FORMATS,
  DriverDataTypes,
  ID_TYPE,
  INVALID_DATE,
  inputFieldMarginBottom,
  useDateTime,
} from "../../../shared";
import { ApiResources } from "../../../api/resources";
import { ThemeContext } from "../../../context/theme/ThemeContextProvider";
import { mainBannerHeightState } from "../../../atoms/atoms";
import useGeneralHeights from "../../../shared/hooks/useGeneralHeights";
import { useReleaseFeatureToggle } from "../../../shared/featureToggles/hooks";
import { FEATURE_NAMES } from "../../../shared/featureToggles/types";
import { UserDto } from "../../../Pages/Auth/types";
import ToggleSwitchPaperBox from "../../ToggleSwitchPaperBox/ToggleSwitchPaperBox";

interface ScheduleFormContentProps {
  inputError: any;
  inputValues: any;
  repeatValue: ScheduleRepeatPeriod | null;
  selectedDataTypes: CombinedDataTypes[];
  activityDates: ActivityDates;
  startDateTime: string | null;
  scheduleData?: ScheduleDataDto | null;
  isGlobalSchedule?: boolean;
  handleFormChange: (
    key: SCHEDULE_PAYLOAD_NAMES,
    value: string | ScheduleRepeatPeriod | CombinedDataTypes | null | boolean
  ) => void;
  handleTimeChange: (value: string) => void;
  validateScheduleName: (e: { target: { value: string } }) => void;
  validateStartDate: (
    error: string | null | SetStateAction<string | null>
  ) => void;
  isLoading: boolean;
  user: UserDto | null;
  createSchedule?: boolean;
  hadleToggleGlobalLinkSwitch?: () => void;
  isInheritanceEnabled?: boolean;
  isAttachedToRoot?: boolean;
}

const ScheduleFormContent = ({
  inputError,
  inputValues,
  repeatValue,
  selectedDataTypes,
  activityDates,
  scheduleData,
  startDateTime,
  isGlobalSchedule,
  handleFormChange,
  handleTimeChange,
  validateScheduleName,
  validateStartDate,
  isLoading,
  user,
  createSchedule,
  hadleToggleGlobalLinkSwitch,
  isInheritanceEnabled,
  isAttachedToRoot,
}: ScheduleFormContentProps) => {
  const { colors } = useContext(ThemeContext);

  const { isReleaseFeatureEnabledForRole } = useReleaseFeatureToggle();
  const { t } = useTranslation();
  const { formattedDate } = useDateTime();

  const mainBannerHeight = useRecoilValue(mainBannerHeightState);

  const { TIME_INPUT_WIDTH } = useGeneralHeights(mainBannerHeight);

  const { timeValues, getFormattedTimeInput } = useScheduleDateTimeValues();

  const [isDatePickerOpen, setIsDatePickerOpen] = useState<boolean>(false);
  const [isTimeInputOpen, setIsTimeInputOpen] = useState<boolean>(false);
  const [isRepeatInputOpen, setIsRepeatInputOpen] = useState<boolean>(false);
  const [everyNthDay, setEveryNthDay] = useState<number | null>(null);

  const { scheduleActivitiesDates } = useStyles();

  const hasRepeatValue = repeatValue !== null;
  const isCustomRepeatValue = repeatValue?.value === REPEAT_NAME_VALUES.Custom;
  const datePickerStartDateValue =
    createSchedule && scheduleData?.[SCHEDULE_PAYLOAD_NAMES.StartDate];
  const datePickerNextDateValue = scheduleData?.nextDate;

  const allowedToToggleGlobalLinking = isReleaseFeatureEnabledForRole(
    FEATURE_NAMES.AllowedGlobalScheduleLinking,
    user?.role as string
  );

  const isInRootCompany = user?.rootCompanySelected;

  const showWarningBanner =
    isGlobalSchedule &&
    isInheritanceEnabled &&
    allowedToToggleGlobalLinking &&
    isInRootCompany;

  const removeToggleSwitchfromUi =
    !isInRootCompany && !scheduleData?.inheritanceEnabled;

  useEffect(() => {
    // convert to positive number if value is negative (refers to hours value)
    const positiveRepeatValue = Math.abs(+`${repeatValue?.days}`);
    setEveryNthDay(positiveRepeatValue);
  }, [repeatValue]);

  const handleStartDateChange = (value: string | null) => {
    const newDate = value === INVALID_DATE ? null : value;

    handleFormChange(
      SCHEDULE_PAYLOAD_NAMES.StartDate,
      `${newDate} ${startDateTime ?? ""}`
    );
  };

  const timeFilterOptions = (
    options: TimeValue[],
    { inputValue }: { inputValue: string }
  ) => {
    const matchingOptions = options.filter((option) =>
      option.label.toLowerCase().includes(inputValue.toLowerCase())
    );
    const nonMatchingOptions = options.filter(
      (option) => !matchingOptions.includes(option)
    );

    const combinedOptions = [...matchingOptions, ...nonMatchingOptions];

    // Create a new array and sort it
    const sortedOptions = [...combinedOptions].sort((a, b) => {
      const dateA = new Date(a.value);
      const dateB = new Date(b.value);
      return dateA.getTime() - dateB.getTime();
    });

    return sortedOptions;
  };

  const handleTimeFieldBlur = (value: string) => {
    const formattedValue = getFormattedTimeInput(value);

    handleTimeChange(formattedValue ?? "");
  };

  const checkIfEqual = (option: any) => {
    const editedValue = startDateTime
      ? getFormattedTimeInput(startDateTime)
      : startDateTime;
    return option.label === editedValue;
  };

  return (
    <>
      {showWarningBanner && (
        <BannerMessage
          data-testid="toggle-global-schedule-warning--banner"
          status={BANNER_MESSAGE_STATUSES.Warning}
          title={t(`Dialog##toggle global schedule warning banner`)}
          externalCustomStyle={{ margin: "0 0 16px 0" }}
        />
      )}
      {isGlobalSchedule && !removeToggleSwitchfromUi && (
        <ToggleSwitchPaperBox
          title={toggleSwitchTitle(isInRootCompany, t)}
          onToggleSwitch={hadleToggleGlobalLinkSwitch}
          isChecked={isInRootCompany ? isInheritanceEnabled : isAttachedToRoot}
          tooltip={toggleSwitchTooltip(isInRootCompany, t)}
          isDisabled={!allowedToToggleGlobalLinking || isLoading}
          customIconStyle={{ marginRight: "12px" }}
        />
      )}

      <InputField
        testId="schedule-form-name-input-field"
        size="medium"
        fullWidth
        required
        readOnly={isGlobalSchedule || isDatePickerOpen}
        errorText={inputError.name ?? ""}
        onBlur={validateScheduleName}
        onChange={(e) =>
          handleFormChange(SCHEDULE_PAYLOAD_NAMES.Name, e.target.value)
        }
        defaultValue={
          scheduleData ? scheduleData[SCHEDULE_PAYLOAD_NAMES.Name] : undefined
        }
        value={
          isGlobalSchedule && !scheduleData
            ? inputValues[SCHEDULE_PAYLOAD_NAMES.Name]
            : undefined
        }
        labelLeft={t("Dialog##name")}
        placeholder={t("Dialog##enter here")}
        tooltipText={
          isGlobalSchedule ? t("Dialog##field cannot be changed") : undefined
        }
        customStyle={inputFieldMarginBottom}
        disabled={isLoading}
      />

      <div css={css({ display: "flex" })}>
        <QueryDropdown
          data-testid="repeat-period-dropdown"
          resource={ApiResources.Repeat}
          idType={ID_TYPE.Repeat}
          fullWidth
          isRequired
          disableClearable
          size="medium"
          value={repeatValue}
          readOnly={isDatePickerOpen}
          open={isRepeatInputOpen}
          onOpen={() => {
            setIsDatePickerOpen(false);
            !isDatePickerOpen && setIsRepeatInputOpen(true);
          }}
          onClose={() => {
            setIsRepeatInputOpen(false);
          }}
          change={(_, val) =>
            handleFormChange(SCHEDULE_PAYLOAD_NAMES.Repeat, val)
          }
          labelLeft={t("Schedules##repeat")}
          textFieldParams={{ placeholder: t("Dialog##select") }}
          customStyle={{ marginBottom: "16px" }}
          isDisabled={disableField(
            isLoading,
            isInRootCompany,
            isAttachedToRoot
          )}
        />

        {isCustomRepeatValue && (
          <InputField
            key={isAttachedToRoot ? uuidv4() : ""}
            testId="schedule-form-name-custom-repeat-field"
            size="medium"
            onChange={(e) =>
              handleFormChange(
                SCHEDULE_PAYLOAD_NAMES.CustomRepeat,
                e.target.value
              )
            }
            readOnly={isLoading}
            disabled={disableField(
              isLoading,
              isInRootCompany,
              isAttachedToRoot
            )}
            InputProps={{
              inputMode: "numeric",
              endAdornment: inputValues.customRepeat && (
                <InputAdornment
                  position="end"
                  sx={{
                    ml: 1,
                    "& .MuiTypography-root": {
                      color: colors.textPlaceholder,
                      fontWeight: 600,
                      fontSize: "14px",
                      lineHeight: "20px",
                    },
                  }}
                >
                  {t("Schedules##days")}
                </InputAdornment>
              ),
              sx: {
                "& input": { textAlign: "left", pr: "50px" }, // Space before "Days"
              },
            }}
            value={inputValues.customRepeat}
            placeholder={
              inputValues.customRepeat ? "" : t("Schedules##enter days")
            }
            css={css({ width: "108px", margin: "24px 0 0 12px" })}
          />
        )}
      </div>

      {hasRepeatValue && (
        <div
          css={css(scheduleActivitiesDates, inputFieldMarginBottom)}
          data-testid="schedule-date-time-picker"
        >
          <CustomDatePicker
            key={isAttachedToRoot ? uuidv4() : "schedule-date-field"}
            label={
              createSchedule
                ? t("Schedules##start date")
                : t("Schedules##next date")
            }
            disablePast
            open={isDatePickerOpen}
            onClose={() => setIsDatePickerOpen(false)}
            onOpen={() => {
              setIsTimeInputOpen(false);
              !disableFieldsForChildren(isInRootCompany, isAttachedToRoot) &&
                setIsDatePickerOpen(true);
            }}
            initialDate={
              scheduleData
                ? formattedDate(
                    // show start date in create schedule dialog and next date in edit schedule dialog
                    datePickerStartDateValue || datePickerNextDateValue,
                    DATE_FORMATS.DateHoursMinutes
                  )
                : inputValues[SCHEDULE_PAYLOAD_NAMES.StartDate]
            }
            setDate={handleStartDateChange}
            textFieldParams={{
              required: true,
            }}
            externalErrorHandle={validateStartDate}
            customStyle={{ flex: 1 }}
            createSchedule={createSchedule}
            everyNthDay={everyNthDay}
            disabled={disableField(
              isLoading,
              isInRootCompany,
              isAttachedToRoot
            )}
          />

          {/* date time picker */}

          <Autocomplete
            data-testid="start-date-time-input-field"
            labelLeft={<TimeInputLabel t={t} />}
            size="medium"
            isRequired
            hideRequiredStar
            freeSolo
            forcePopupIcon
            disableClearable
            width={TIME_INPUT_WIDTH}
            readOnly={isDatePickerOpen}
            open={isTimeInputOpen}
            disabled={disableField(
              isLoading,
              isInRootCompany,
              isAttachedToRoot
            )}
            onOpen={() => {
              setIsDatePickerOpen(false);
              !isDatePickerOpen && setIsTimeInputOpen(true);
            }}
            onClose={() => {
              setIsTimeInputOpen(false);
            }}
            getOptionLabel={(option) => {
              return option.label;
            }}
            isOptionEqualToValue={checkIfEqual}
            options={timeValues}
            onInputChange={(event: any, newInputValue) => {
              event && handleTimeChange(newInputValue);
            }}
            // save value on enter:
            onChange={(event: any) => {
              event.type === "keydown" &&
                handleTimeFieldBlur(event.target.value);
            }}
            inputValue={startDateTime ?? ""}
            textFieldParams={{
              onBlur: (e: any) =>
                isTimeInputOpen && handleTimeFieldBlur(e.target.value),
              placeholder: DATE_FORMATS.HoursMinutes.toUpperCase(),
            }}
            filterOptions={timeFilterOptions}
            customStyle={inputError.startDate && { marginBottom: "26px" }}
          />
        </div>
      )}

      {!selectedDataTypes.includes(DriverDataTypes.DriverCard) && (
        <DownloadDataTypesCheckboxes
          selectedDataTypes={selectedDataTypes}
          handleChange={handleFormChange}
          startDate={activityDates.startDate}
          repeatValue={repeatValue}
          isLoading={disableField(isLoading, isInRootCompany, isAttachedToRoot)}
        />
      )}
    </>
  );
};

export default ScheduleFormContent;
