/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

import { useStyles } from "../../styles";

import InputField from "../../../InputField/InputField";

import { ThemeContext } from "../../../../context/theme/ThemeContextProvider";
import { DATE_FORMATS } from "../../../../shared";

const TextField = ({ timeZoneExplanatory, ...restProps }: any) => {
  const { colors } = useContext(ThemeContext);

  const { t } = useTranslation();

  const { error, helperText, InputProps, placeholder, value } = restProps;

  const { timeZoneExplanatorySpan } = useStyles();

  const isTimeZoneExplanatory =
    timeZoneExplanatory &&
    value.length !== 0 &&
    value !== placeholder &&
    !error;

  const isValueEqualToDateFormat = Object.values(DATE_FORMATS).includes(value);

  return (
    <div
      css={css({
        position: "relative",
        // remove text highlight on click:
        "& ::selection": {
          background: "transparent",
        },
      })}
    >
      <InputField
        data-testid="custom-date-picker-input-field"
        {...restProps}
        fullWidth
        size="medium"
        errorText={helperText}
        readOnly={InputProps.readOnly}
        placeholder={t("Schedules##select date")}
        // if value is a date format, show placeholder
        value={isValueEqualToDateFormat ? t("Schedules##select date") : value}
        customInputStyle={{
          color: isValueEqualToDateFormat ? colors.textPlaceholder : undefined,
        }}
        inputStyleExceptions={{ background: colors.white }}
      />

      {isTimeZoneExplanatory && (
        <span
          css={css([
            timeZoneExplanatorySpan,
            {
              color: colors.textPlaceholder,
            },
          ])}
        >
          {timeZoneExplanatory}
        </span>
      )}
    </div>
  );
};

export default TextField;
