/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useLocation } from "react-router-dom";

import { useFilters } from "../../hooks";
import FilterButton from "../../components/FilterButton";
import FilterMenu from "../../components/FilterMenu";
import { useStyles } from "../../styles";

import DateRangePicker from "../../../DateRangePicker/DateRangePicker";

import { checkIfFilesPage, useMediaQueries } from "../../../../shared";

const FilesFilters = () => {
  const location = useLocation();
  const { toLg } = useMediaQueries();

  const {
    anchorEl,
    handleOpenMenu,
    handleCloseMenu,
    isPartOfFilterMenu,
  } = useFilters();

  const { filtersMainBlock, filtersListBlock } = useStyles();

  const currentLocation = location.pathname;

  return (
    <div css={css(filtersMainBlock)} data-testid="files-filters">
      {checkIfFilesPage(currentLocation) && (
        <>
          {!isPartOfFilterMenu() && <DateRangePicker />}

          {toLg && (
            <>
              <FilterButton
                onOpen={handleOpenMenu}
                hasLoadingCustomStyle
                hasMoreFilters
              />
              <FilterMenu
                isOpen={Boolean(anchorEl)}
                onClose={handleCloseMenu}
                anchorEl={anchorEl}
                hasMoreFilters
              >
                <div css={css(filtersListBlock)}>
                  {isPartOfFilterMenu() && <DateRangePicker />}
                </div>
              </FilterMenu>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default FilesFilters;
