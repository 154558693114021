import { CSSObject, Paper, Typography } from "@mui/material";
import { Dispatch, SetStateAction, useContext, useState } from "react";

import ImageDropzoneContent from "./ImageDropzoneContent";

import { selectableImageType } from "../..";
import { useStyles } from "../../styles";
import SelectImageDialog from "../../Dialogs/SelectImageDialog/SelectImageDialog";

import { ThemeContext } from "../../../../../../context/theme/ThemeContextProvider";
import { inputFieldMarginBottom } from "../../../../../../shared";
import RectangleLoader from "../../../../../../shared/components/loaders/RectangleLoader";

interface ImageDropzoneProps {
  label: string;
  setSelectedImage: Dispatch<SetStateAction<selectableImageType | null>>;
  selectedImage: selectableImageType | null;
  setIsInteracted?: Dispatch<SetStateAction<boolean>>;
  isLoading?: boolean;
  disabled?: boolean;
}

const ImageDropzone = ({
  label,
  setSelectedImage,
  selectedImage,
  setIsInteracted,
  isLoading,
  disabled,
}: ImageDropzoneProps) => {
  const { colors } = useContext(ThemeContext);

  const [isSelectImageDialogOpen, setIsSelectImageDialogOpen] = useState<
    boolean
  >(false);

  const { imageDropzone } = useStyles({ colors });

  const handleSelectImageButtonClick = () => {
    setIsSelectImageDialogOpen(true);
  };

  const handleCloseSelectImageDialog = () => {
    setIsSelectImageDialogOpen(false);
  };

  return (
    <>
      {isLoading ? (
        <RectangleLoader
          width={"100%"}
          height={"92px"}
          customStyle={inputFieldMarginBottom}
          testId="image-dropzone-loader"
        />
      ) : (
        <>
          <Typography variant="body2" sx={{ marginBottom: "4px" }}>
            {label}
          </Typography>
          <Paper
            data-testid="image-dropzone"
            elevation={0}
            variant="outlined"
            sx={[imageDropzone, inputFieldMarginBottom] as CSSObject[]}
          >
            <ImageDropzoneContent
              onButtonClick={handleSelectImageButtonClick}
              selectedImage={selectedImage}
              disabled={disabled}
            />
          </Paper>

          <SelectImageDialog
            isDialogOpen={isSelectImageDialogOpen}
            onDialogClose={handleCloseSelectImageDialog}
            colors={colors}
            setSelectedImage={setSelectedImage}
            selectedImage={selectedImage}
            setIsInteracted={setIsInteracted}
          />
        </>
      )}
    </>
  );
};

export default ImageDropzone;
