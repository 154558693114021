/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useCallback, useContext, useEffect } from "react";
import useResizeObserver from "use-resize-observer";
import { useRecoilState } from "recoil";
import { Typography } from "@mui/material";
import moment from "moment";

import { ThemeContext } from "../../context/theme/ThemeContextProvider";
import { mainBannerHeightState, mainBannerState } from "../../atoms/atoms";

const MainInfoBanner = () => {
  const { colors } = useContext(ThemeContext);
  const { ref, height = 1 } = useResizeObserver();

  const [, setMainBannerHeight] = useRecoilState(mainBannerHeightState);
  const [isMainBannerVisible, setIsMainBannerVisible] = useRecoilState(
    mainBannerState
  );

  const padding = 8;
  const paddinTopAndBottom = padding * 2;

  const endDate = "2025-04-12 00:00";

  const isFutureTime = useCallback((dateTimeStr: string): boolean => {
    const givenTime = moment(dateTimeStr, "YYYY-MM-DD HH:mm");
    return moment().isBefore(givenTime);
  }, []);

  useEffect(() => {
    if (isFutureTime(endDate)) {
      setIsMainBannerVisible(true);
      setMainBannerHeight(height + paddinTopAndBottom);
    } else {
      setIsMainBannerVisible(false);
      setMainBannerHeight(0);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [height, setMainBannerHeight]);

  return (
    <>
      {isMainBannerVisible ? (
        <div
          ref={ref}
          css={css({
            backgroundColor: colors.blue200,
            padding: `${padding}px 24px`,
            textAlign: "center",
          })}
        >
          <Typography
            variant="body2"
            sx={{ maxWidth: "970px", textWrap: "wrap", margin: "0 auto" }}
          >
            Important Notice: BETA access ends on April 11, 2025. The product
            will be unavailable between <b>April 12–28</b> as we prepare for the
            official <b>public release</b> on <b>April 29, 2025</b>. Thank you
            for your patience!
          </Typography>
        </div>
      ) : null}
    </>
  );
};

export default MainInfoBanner;
