/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useContext } from "react";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import CollectionsIcon from "@mui/icons-material/Collections";

import { selectableImageType } from "../..";
import { useStyles } from "../../styles";

import WarningIcon from "../../../../../../assets/customIcons/status/WarningIcon";
import { ThemeContext } from "../../../../../../context/theme/ThemeContextProvider";
import Button from "../../../../../../components/Button/Button";

interface IImageDropzoneContentProps {
  onButtonClick: () => void;
  selectedImage: selectableImageType | null;
  disabled?: boolean;
}

const ImageDropzoneContent = ({
  onButtonClick,
  selectedImage,
  disabled,
}: IImageDropzoneContentProps) => {
  const { colors } = useContext(ThemeContext);

  const { t } = useTranslation();

  const { noImageWarning } = useStyles({ colors });

  return (
    <>
      <div css={css([noImageWarning])}>
        {!selectedImage && <WarningIcon data-testid="alert-warning-icon" />}

        {selectedImage && (
          <CollectionsIcon
            data-testid="image-collections-icon"
            sx={{ color: colors.textPlaceholder }}
          />
        )}
        <Typography variant="body2">
          {selectedImage?.imageName || t("Dropzone##no image")}
        </Typography>
      </div>

      <Button
        size="small"
        variant="textOnly"
        color="primarySubtle"
        onClick={onButtonClick}
        disabled={disabled}
      >
        {t("Button##select image")}
      </Button>
    </>
  );
};

export default ImageDropzoneContent;
