/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useTranslation } from "react-i18next";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";

import Tooltip from "../../Tooltip/Tooltip";
import Button from "../../Button/Button";

const LeftArrowButton = ({ ...props }) => {
  const { t } = useTranslation();

  return (
    <Tooltip
      placement="top"
      size="medium"
      title={!props.disabled ? t("Button##previous month") : ""}
      disableTouchListener
      sx={{ zIndex: 10000 }}
    >
      <span>
        <Button
          {...props}
          color="secondary"
          size="tiny"
          variant="iconOnly"
          icon={<ChevronLeftRoundedIcon />}
          css={css({
            marginRight: "-20px",
          })}
        />
      </span>
    </Tooltip>
  );
};

export default LeftArrowButton;
