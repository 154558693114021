/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Typography } from "@mui/material";

import {
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useRecoilValue } from "recoil";
import { useLocation } from "react-router-dom";

import { useStyles } from "../styles";

import { useStyles as useReusableDetailsStyles } from "../../../../../styles";
import useDetailsFunctions from "../../../../../hooks/useDetailsFunctions/useDetailsFunctions";

import { SCHEDULES_NAMES } from "../../../../../../Schedules/types";
import Separator from "../../../../../../Separator/Separator";
import GlobalScheduleIcon from "../../../../../../Schedules/components/GlobalScheduleIcon/GlobalScheduleIcon";
import { specificGlobalSchedule } from "../../../../../../Schedules/functions";
import SharedStatusBadge from "../../../../../../SharedStatusBadge/SharedStatusBadge";
import { LongTextTooltip } from "../../../../../../Tooltip/LongTextTooltip";

import {
  tableDetailsLoading,
  tableLoading,
} from "../../../../../../../atoms/atoms";
import { ThemeContext } from "../../../../../../../context/theme/ThemeContextProvider";
import {
  COMPANY_CARD_STATUS,
  MenuAnchorEl,
  useMediaQueries,
} from "../../../../../../../shared";
import MoreActionsButton from "../../../../../../../shared/components/buttons/MoreActionsButton";

interface SingleDetailsAcordionItemprops {
  title: string;
  totalCount: number;
  index: number;
  collapsable?: boolean;
  children: ReactNode;
  titleInlineActions?: ReactNode;
  anchorEl?: MenuAnchorEl;
  menuWidth?: string;
  companyCardStatus?: COMPANY_CARD_STATUS;
  setIsLoading?: Dispatch<SetStateAction<boolean>>;
  setIsActionsOpen?: Dispatch<SetStateAction<MenuAnchorEl>>;
  expandFirstItem?: boolean;
  showFirstTopSeparator?: boolean;
  fromDialog?: boolean;
  addMaxHeight?: boolean;
  showCollapseTooltips?: boolean;
}

const SingleDetailsAcordionItem = ({
  title,
  totalCount,
  index,
  collapsable,
  children,
  titleInlineActions,
  anchorEl,
  menuWidth,
  companyCardStatus,
  setIsLoading,
  setIsActionsOpen,
  expandFirstItem = true,
  showFirstTopSeparator = true,
  fromDialog,
  addMaxHeight = true,
  showCollapseTooltips = true,
}: SingleDetailsAcordionItemprops) => {
  const { colors } = useContext(ThemeContext);
  const { renderExpandIcon } = useDetailsFunctions();
  const { collapseArrow } = useReusableDetailsStyles();
  const location = useLocation();
  const { toSm, toMd, toLg } = useMediaQueries();

  const isDetailsLoading = useRecoilValue(tableDetailsLoading);
  const isTableLoading = useRecoilValue(tableLoading);

  const isFirstItemAllowedToCollapse = expandFirstItem && index === 0;

  const [isOpen, setIsOpen] = useState<boolean>(isFirstItemAllowedToCollapse);
  const [isTitleOverfloing, setIsTitleOverflowing] = useState<boolean>(false);

  const titleRef = useRef<HTMLDivElement>(null);
  const titleContainerRef = useRef<HTMLDivElement>(null);

  const {
    accordionItemTitleArea,
    collapsableItemContentDiv,
    accordionItemTitleContainer,
    notCollapsableItemContentDiv,
    accordionItemIconMargin,
  } = useStyles({
    isOpen,
    fromDialog,
    addMaxHeight,
  });

  const displayLoader = isDetailsLoading || isTableLoading;
  const collapsableCardContent = isOpen ? children : <></>;

  const titleWidthToSm = toSm && 200;
  const titleWidthToMd = toMd && 400;
  const titleWidthToLg = toLg && 200;

  const titleMaxWidth =
    titleWidthToSm || titleWidthToMd || titleWidthToLg || 300;

  useEffect(() => {
    titleRef.current &&
      setIsTitleOverflowing(
        titleRef.current.scrollWidth > titleRef.current?.clientWidth
      );

    setIsLoading && setIsLoading(displayLoader);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    index,
    setIsLoading,
    displayLoader,
    titleRef.current?.scrollWidth,
    titleRef.current?.clientWidth,
    titleContainerRef.current?.clientWidth,
  ]);

  const handleToggleCollapse = () => {
    setIsOpen(!isOpen);
  };

  const handleOpenActionsMenu = (e: {
    currentTarget: SetStateAction<MenuAnchorEl>;
  }) => {
    setIsActionsOpen && setIsActionsOpen(e.currentTarget);
  };

  const handleCloseActionsMenu = () => {
    setIsActionsOpen && setIsActionsOpen(null);
  };

  const titleStyleOverrides = {
    maxWidth: `${titleMaxWidth}px`,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  };

  return (
    <div data-testid="single-details-accordion-item">
      {index < totalCount && showFirstTopSeparator && (
        <Separator color={colors.gray200} />
      )}

      <div css={css(accordionItemTitleContainer)} ref={titleContainerRef}>
        {/* title */}
        <div
          css={css([
            accordionItemTitleArea,
            companyCardStatus && accordionItemIconMargin,
          ])}
        >
          {title === SCHEDULES_NAMES.GlobalSchedule && (
            <GlobalScheduleIcon
              specificGlobalSchedule={specificGlobalSchedule(location.pathname)}
              colors={colors}
            />
          )}

          {companyCardStatus && (
            <SharedStatusBadge status={companyCardStatus} bubbleOnly />
          )}

          <LongTextTooltip
            title={title}
            open={isTitleOverfloing}
            customStyles={{
              "& .MuiTooltip-tooltip": { wordBreak: "break-all" },
            }}
          >
            <Typography
              variant="body2Bold"
              ref={titleRef}
              sx={titleStyleOverrides}
            >
              {title}
            </Typography>
          </LongTextTooltip>
        </div>

        {/* actions menu */}
        <div css={css(accordionItemTitleArea)}>
          {titleInlineActions && (
            <MoreActionsButton
              onMenuOpen={handleOpenActionsMenu}
              onCloseMenu={handleCloseActionsMenu}
              anchorEl={anchorEl || null}
              menuWidth={menuWidth}
            >
              {titleInlineActions}
            </MoreActionsButton>
          )}

          {/* arrow icon */}
          <div css={css(collapseArrow)}>
            {collapsable &&
              renderExpandIcon({
                handleToggleCollapse,
                isOpen,
                customStyle: {},
                hasTooltip: showCollapseTooltips,
              })}
          </div>
        </div>
      </div>

      {/* Card content */}
      {collapsable && (
        <div css={css(collapsableItemContentDiv)}>{collapsableCardContent}</div>
      )}

      {!collapsable && (
        <div css={css(notCollapsableItemContentDiv)}>{children}</div>
      )}
    </div>
  );
};

export default SingleDetailsAcordionItem;
