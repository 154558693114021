import { PickersDay } from "@mui/x-date-pickers";
import { styled } from "@mui/material/styles";

import { CUSTOM_PICKERS_DAY_PROPS } from "../types";

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) =>
    prop !== CUSTOM_PICKERS_DAY_PROPS.IsInRange &&
    prop !== CUSTOM_PICKERS_DAY_PROPS.IsStart &&
    prop !== CUSTOM_PICKERS_DAY_PROPS.IsEnd &&
    prop !== CUSTOM_PICKERS_DAY_PROPS.IsRowStart &&
    prop !== CUSTOM_PICKERS_DAY_PROPS.IsRowEnd,
})<{
  isInRange?: boolean;
  isStart?: boolean;
  isEnd?: boolean;
  isRowStart?: boolean;
  isRowEnd?: boolean;
}>(({ isInRange, isStart, isEnd, isRowEnd, isRowStart }) => {
  const sharedStyles = (borderRadius: string | number) => {
    return {
      backgroundColor: "#003FB4",
      color: "#fff !important",
      border: "none !important",
      borderRadius: borderRadius,
      ":hover": {
        backgroundColor: "#001A77 !important",
        transition: "backgroundColor 0.3s",
      },
    };
  };

  const isLeftRounded = isStart || (isRowStart && (isStart || isInRange));
  const isRightRounded = isEnd || (isRowEnd && (isEnd || isInRange));
  const isAllRounded =
    (isStart && isRowEnd && isInRange) ||
    (isEnd && isRowStart) ||
    (isStart && isEnd);

  return {
    ...(isInRange && sharedStyles(0)),
    ...(isLeftRounded && sharedStyles("50% 0 0 50%")), // left rounded
    ...(isRightRounded && sharedStyles("0 50% 50% 0")), // right rounded
    ...(isAllRounded && sharedStyles("50% 50% 50% 50%")), // all rounded
  };
});

export default CustomPickersDay;
