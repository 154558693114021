/** @jsxImportSource @emotion/react */
import { css, CSSObject } from "@emotion/react";
import { useContext } from "react";
import { EmotionJSX } from "@emotion/react/types/jsx-namespace";
import { Typography } from "@mui/material";

import { useStyles } from "../styles";
import { CircleStepperStatusTypes } from "../types";
import {
  DownloadLiveStatusDataType,
  DownloadStagesTypes,
} from "../../DetailsSection/components/DetailsContent/components/DownloadMonitoringDialog/types";

import { ThemeContext } from "../../../context/theme/ThemeContextProvider";
import { useMediaQueries } from "../../../shared";
import { replaceCamelCaseStringToSeparateWords } from "../../../shared/functions/functions";
import useLoadingDots from "../../../shared/hooks/useLoadingDots";

interface CircleStepperFunctionsProps {
  showLeftHorizontalLine?: boolean;
  status: CircleStepperStatusTypes;
  showRightHorizontalLine?: boolean;
  lastElement?: boolean;
  stepTitle?: string;
}

const useCircleStepperFunctions = ({
  showLeftHorizontalLine,
  status,
  showRightHorizontalLine,
  lastElement,
  stepTitle,
}: CircleStepperFunctionsProps) => {
  const { colors } = useContext(ThemeContext);
  const { fromMd } = useMediaQueries();
  const dots = useLoadingDots();

  const {
    pulsatingCircle,
    pulsatingDot,
    stagesFromMdContainer,
    horizontalStickStyle,
    verticalStickStyle,
    stagesToMdContainer,
    stageIconContainerToMd,
    downloadStepTextToMd,
    downloadStatusText,
    loadingDots,
  } = useStyles({
    colors,
    showLeftHorizontalLine,
    fromMd,
  });

  const generateRightHorizontalLineColor = (
    status: CircleStepperStatusTypes
  ) => {
    switch (status) {
      case CircleStepperStatusTypes.success:
        return colors.blue700;
      case CircleStepperStatusTypes.current:
        return colors.textDarkDisabled;
      case CircleStepperStatusTypes.notCompleted:
        return colors.textDarkDisabled;

      default:
        return colors.blue700;
    }
  };

  const generateLeftHorizontalLineColor = () =>
    status === CircleStepperStatusTypes.notCompleted
      ? colors.textDarkDisabled
      : colors.blue700;

  const generatetextColor = (status: CircleStepperStatusTypes) =>
    status === CircleStepperStatusTypes.notCompleted
      ? colors.textDarkDisabled
      : colors.textDark;

  const generateCurrentStepCircle = () => (
    <div className="pulsating-circle" css={css(pulsatingCircle)}>
      <div className="pulsating-dot" css={css(pulsatingDot)}></div>
    </div>
  );

  const generateStatusText = (liveStatusData: DownloadLiveStatusDataType) => (
    <Typography
      variant="body4regular"
      sx={{ color: colors.textPlaceholder, position: "relative" }}
    >
      {replaceCamelCaseStringToSeparateWords(liveStatusData.Status)}
      {!liveStatusData.Finished && <span css={css(loadingDots)}>{dots}</span>}
    </Typography>
  );

  const generateViewFromMd = (
    generateCircleStepperIcon: (
      status: CircleStepperStatusTypes
    ) => EmotionJSX.Element | null,
    liveStatusData?: DownloadLiveStatusDataType
  ) => (
    <>
      <div css={css(stagesFromMdContainer)}>
        {showLeftHorizontalLine && (
          <div
            css={css([
              {
                backgroundColor: generateLeftHorizontalLineColor(),
              },
              horizontalStickStyle,
            ])}
          />
        )}
        {generateCircleStepperIcon(status)}
        {showRightHorizontalLine && (
          <div
            css={css([
              {
                backgroundColor: lastElement
                  ? "transparent"
                  : generateRightHorizontalLineColor(status),
              },
              horizontalStickStyle,
            ])}
          />
        )}
      </div>
      <Typography
        variant="body2"
        color={generatetextColor(status)}
        sx={{ marginTop: "16px" }}
      >
        {stepTitle}
      </Typography>

      <div css={downloadStatusText}>
        {liveStatusData && generateStatusText(liveStatusData)}
      </div>
    </>
  );

  const generateViewToMd = (
    generateCircleStepperIcon: (
      status: CircleStepperStatusTypes
    ) => EmotionJSX.Element | null,
    liveStatusData?: DownloadLiveStatusDataType
  ) => {
    const firstStep =
      stepTitle ===
      replaceCamelCaseStringToSeparateWords(
        DownloadStagesTypes.DownloadTrigger
      );

    return (
      <div css={css(stagesToMdContainer)}>
        <div css={css(stageIconContainerToMd)}>
          {/* top vertical line */}
          {showLeftHorizontalLine && (
            <div
              css={css([
                {
                  backgroundColor: generateLeftHorizontalLineColor(),
                },
                verticalStickStyle,
              ])}
            />
          )}
          {generateCircleStepperIcon(status)}

          {/* bottom vertical line */}
          {showRightHorizontalLine && (
            <div
              css={css([
                {
                  backgroundColor: lastElement
                    ? "transparent"
                    : generateRightHorizontalLineColor(status),
                },
                verticalStickStyle,
              ])}
            />
          )}
        </div>

        {/* Status text under the stage */}
        <div
          css={css({
            marginTop: liveStatusData?.Status && firstStep ? "-8px" : undefined,
          })}
        >
          <span>
            <Typography
              variant="body2"
              color={generatetextColor(status)}
              sx={downloadStepTextToMd as CSSObject}
            >
              {stepTitle}
            </Typography>
            <div css={css({ marginLeft: "16px" })}>
              {liveStatusData && generateStatusText(liveStatusData)}
            </div>
          </span>
        </div>
      </div>
    );
  };

  return { generateViewFromMd, generateViewToMd, generateCurrentStepCircle };
};

export default useCircleStepperFunctions;
