import { ThemeColors } from "../../shared";

export const pickersTextStyles = {
  fontWeight: "600",
  lineHeight: "20px",
  letterSpacing: "0.15px",
};

const renderDayBorderRadius = (props: any) => {
  if ((props?.isStart && props?.isRowEnd) || (props?.isStart && props?.isEnd)) {
    return "50px"; // Fully rounded (start is at row end)
  }

  if (props?.isEnd && props?.isRowStart) {
    return "50px"; // Fully rounded (end is at row start)
  }

  if (props?.isStart || props?.isRowStart) {
    return "50px 0 0 50px"; // Left rounded
  }

  if (props?.isEnd || props?.isRowEnd) {
    return "0 50px 50px 0"; // Right rounded
  }

  return "0"; // Default (no rounding)
};

export const useStyles = (
  colors?: ThemeColors,
  textStyles: any = pickersTextStyles,
  props?: any,
  isDayHovered?: boolean
) => {
  return {
    dateRangeActionButtons: {
      display: "flex",
      justifyContent: "space-between",
      padding: 2,
      position: "absolute",
      bottom: 0,
      right: 0,
      gap: "16px",
    },
    datePickerOverride: {
      "& .MuiDateCalendar-root": {
        width: "324px",
        height: "324px",
      },

      "& .MuiPickersCalendarHeader-label": {
        fontWeight: 600,
      },

      "& .MuiDayCalendar-weekDayLabel": {
        margin: "0 0 4px 0",
      },

      "& .MuiDayCalendar-weekContainer": {
        margin: "4px 0",
      },

      "& .MuiPickersDay-root": {
        color: colors?.textDark,
        fontSize: "16px",
        fontWeight: 600,
        width: "32px",
        height: "32px",
        margin: "0 6px",
        ...textStyles,

        "&.Mui-selected": {
          background: colors?.blue700,
          color: colors?.white,
          fontWeight: "600",
        },

        "&.Mui-selected:focus": {
          background: colors?.blue700,
          color: colors?.white,
        },

        "&.Mui-disabled:not(.Mui-selected)": {
          color: colors?.textDarkDisabled,
          textDecoration: "line-through",
        },
      },

      "& .MuiPickersDay-root:focus": {
        background: colors?.blue700,
        color: colors?.white,
      },

      "& .MuiPickersDay-root:not(.Mui-selected)": {
        borderColor: colors?.gray300,
      },
    },
    customPickersDayBox: {
      position: "relative",
      width: "44px", // Ensure width includes margins
      height: "32px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      background: props?.isInRange ? "#003FB4" : "transparent",
      // borderRadius: "50px",
      borderRadius: renderDayBorderRadius(props),
      ":hover": {
        backgroundColor:
          isDayHovered && props?.isInRange ? "#001A77 !important" : "undefined",
        transition: "backgroundColor 0.3s",
      },
    },
    filterDropdownCustomWidth: {
      width: "246px",

      "@media(max-width: 839px)": {
        width: "unset",
        flex: "1",
      },
    },
  };
};
