import { useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import TroubleshootOutlinedIcon from "@mui/icons-material/TroubleshootOutlined";

import { useDialog } from "../../../../PageHeader/actions/hooks";

import { VehicleDataDto } from "../../../../../Pages/Vehicles/interfaces";
import {
  RenderConditionOptions,
  VEHICLES_ACTIONS,
} from "../../../../../shared";

const useVehicleInlineActions = () => {
  const { isDialogOpen, openDialog, closeDialog } = useDialog();

  const [rowData, setRowData] = useState<VehicleDataDto | null>(null);
  const [isEditOpen, setIsEditOpen] = useState<boolean>(false);

  const openEditDialog = (data: any) => {
    setRowData(data);
    setIsEditOpen(true);
  };

  const closeEditDialog = () => {
    setRowData(null);
    setIsEditOpen(false);
  };

  const handleEditVehicleClick = (data: VehicleDataDto) => {
    openEditDialog(data);
  };

  const handleDownloadStatusClick = (data: any) => {
    setRowData(data);
    openDialog(VEHICLES_ACTIONS.DownloadMonitoring);
  };

  const handleCloseDownloadStatusDialog = () => {
    closeDialog(VEHICLES_ACTIONS.DownloadMonitoring);
    setRowData(null);
  };

  const actionTitleArray = [
    VEHICLES_ACTIONS.DownloadMonitoring,
    VEHICLES_ACTIONS.EditVehicle,
  ];

  const inlineActions = [
    {
      title:
        actionTitleArray[0].charAt(0).toUpperCase() +
        actionTitleArray[0].slice(1),
      icon: <TroubleshootOutlinedIcon />,
      clickAction: (data: any) => handleDownloadStatusClick(data),
      // if renderConditions property is equal to true
      // then table will return inline action button
      renderConditions: ({ data, user }: RenderConditionOptions) => true,
    },
    {
      title:
        actionTitleArray[1].charAt(0).toUpperCase() +
        actionTitleArray[1].slice(1),
      icon: <EditIcon />,
      clickAction: (data: any) => handleEditVehicleClick(data),
      // if renderConditions property is equal to true
      // then table will return inline action button
      renderConditions: ({ data, user }: RenderConditionOptions) => true,
    },
  ];

  return {
    inlineActions,
    rowData,
    isEditOpen,
    closeEditDialog,
    isDialogOpen,
    handleCloseDownloadStatusDialog,
  };
};

export default useVehicleInlineActions;
