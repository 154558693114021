import { CSSObject } from "@emotion/react";

import { ThemeColors } from "../../../../../../shared";

const historyEmptyStateHeightFromMd = (
  fromMd?: boolean,
  showErrorBanner?: boolean
) => {
  const emtyHistoryWithBannerMessage = showErrorBanner && `calc(204px - 34px)`;
  return fromMd && (emtyHistoryWithBannerMessage || "204px");
};

const dynamicHistoryEmptyStateHeight = (statusContentHeight?: number) =>
  statusContentHeight && `calc(${statusContentHeight}px - 431px - 2rem)`;

interface UseStylesProps {
  fromMd?: boolean;
  colors?: ThemeColors;
  statusContentHeight?: number;
  showErrorBanner?: boolean;
}

export const useStyles = ({
  fromMd,
  colors,
  statusContentHeight,
  showErrorBanner,
}: UseStylesProps) =>
  ({
    downloadStatContentContainer: {
      display: "flex",
      flexDirection: fromMd ? "row" : "column",
      width: "100%",
      justifyContent: "center",
      paddingTop: !fromMd ? "16px" : undefined,
    },
    historyInfoItem: {
      display: "flex",
      justifyContent: "space-between",
      borderLeft: `1px solid ${colors?.gray200}`,
    },
    historyItemIconStyle: {
      width: "16px",
      height: "16px",
      marginRight: "6px",
      marginTop: "8px",
    },
    infoTitleDiv: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      marginBottom: "4px",
    },
    titleWithIconBlock: {
      display: "flex",
      marginLeft: "16px",
      alignItems: "flex-start",
    },
    historyEmptyStatePaper: {
      marginTop: "16px",
      height:
        historyEmptyStateHeightFromMd(fromMd, showErrorBanner) ||
        dynamicHistoryEmptyStateHeight(statusContentHeight) ||
        undefined,
      borderRadius: "8px",
      display: "flex",
      flexDirection: "column",
      flex: "1",
      justifyContent: "center",
      alignItems: "center",
    },
  } as CSSObject);
