/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from "@emotion/react";
import { Fragment, ReactNode } from "react";

import { useStyles } from "./styles";

import Tooltip from "../Tooltip/Tooltip";

import InfoIcon from "../../assets/customIcons/status/InfoIcon";

interface LabelProps {
  titleLeft?: string | ReactNode;
  titleTooltip?: string;
  titleRight?: string | ReactNode;
  isRequired?: boolean;
  css?: SerializedStyles;
  labelCustomStyle?: {};
  hideRequiredStar?: boolean;
}

const Label = ({
  titleLeft,
  titleTooltip,
  titleRight,
  isRequired,
  labelCustomStyle,
  hideRequiredStar,
  ...props
}: LabelProps) => {
  const classes = useStyles();

  const getJustifyPosition = () => {
    if (titleLeft && titleRight) {
      return "space-between";
    }

    return titleLeft ? "flex-start" : "flex-end";
  };

  const justifyContent = getJustifyPosition();

  return (
    <div
      {...props}
      css={css([
        {
          justifyContent,
        },
        classes.label,
      ])}
    >
      {titleLeft && (
        <div css={css([labelCustomStyle, classes.titleLeft])}>
          <span>{titleLeft}</span>

          {isRequired && !hideRequiredStar && <Fragment>&#42;</Fragment>}

          {titleTooltip && (
            <Tooltip title={titleTooltip} placement="top" size="medium">
              <span css={css(classes.titleTooltip)}>
                <InfoIcon />
              </span>
            </Tooltip>
          )}
        </div>
      )}

      {titleRight && <div css={css(labelCustomStyle)}>{titleRight}</div>}
    </div>
  );
};

export default Label;
