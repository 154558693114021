/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useContext, useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";

import ToggleDrawerButton from "./components/ToggleDrawerButton/ToggleDrawerButton";
import Navigation from "../Navigation/Navigation";
import { useStyles } from "../styles";
import {
  desktopDrawerState,
  mainBannerHeightState,
} from "../../../atoms/atoms";
import { LOCAL_STORAGE_KEYS, useMediaQueries } from "../../../shared";
import { setItemToLocalStorage } from "../../../shared/functions/functions";
import { ThemeContext } from "../../../context/theme/ThemeContextProvider";
import useGeneralHeights from "../../../shared/hooks/useGeneralHeights";

const DesktopDrawer = () => {
  const { colors } = useContext(ThemeContext);

  const { to1099 } = useMediaQueries();
  const mainBannerHeight = useRecoilValue(mainBannerHeightState);

  const {
    CLOSE_DESKTOP_DRAWER_WIDTH_WITHOUT_PADDING,
    OPEN_DESKTOP_DRAWER_WIDTH_WITHOUT_PADDING,
  } = useGeneralHeights(mainBannerHeight);

  const { desktopdrawerAside, desktopdrawerContent } = useStyles();

  const [isDesktopDrawerOpen, setIsDesktopDrawerOpen] = useRecoilState(
    desktopDrawerState
  );

  useEffect(() => {
    to1099 && handleCloseDrawer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [to1099]);

  const handleOpenDrawer = () => {
    setIsDesktopDrawerOpen(true);
    setItemToLocalStorage(LOCAL_STORAGE_KEYS.DesktopDrawer, true.toString());
  };

  const handleCloseDrawer = () => {
    setIsDesktopDrawerOpen(false);
    setItemToLocalStorage(LOCAL_STORAGE_KEYS.DesktopDrawer, false.toString());
  };

  return (
    <aside
      id="desktopDrawer"
      data-testid="DesktopDrawer"
      css={css([
        {
          background: colors.gray50,
          borderRight: `1px solid ${colors.gray200}`,
          width: isDesktopDrawerOpen
            ? OPEN_DESKTOP_DRAWER_WIDTH_WITHOUT_PADDING
            : CLOSE_DESKTOP_DRAWER_WIDTH_WITHOUT_PADDING,
        },
        desktopdrawerAside,
      ])}
    >
      <div css={css(desktopdrawerContent)}>
        <Navigation />
        {!to1099 && (
          <ToggleDrawerButton
            isdrawerOpen={isDesktopDrawerOpen}
            onOpendrawer={handleOpenDrawer}
            onDrawerClose={handleCloseDrawer}
          />
        )}
      </div>
    </aside>
  );
};

export default DesktopDrawer;
