import { SetStateAction, useEffect, useLayoutEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useScheduleDateTimeValues } from "./useScheduleDateTimeValues";
import { useScheduleResourceFunctions } from "./useScheduleResourceFunctions";
import useScheduleRepeatValues from "./useScheduleRepeatValues/useScheduleRepeatValues";

import {
  ActivityDates,
  InputValuesDto,
  REPEAT_NAME_VALUES,
  SCHEDULE_PAYLOAD_NAMES,
  ScheduleDataDto,
  SchedulePayloadDto,
  ScheduleRepeatPeriod,
} from "../types";

import { TABLE_NAMES, VehicleDataTypes, useDateTime } from "../../../shared";
import { useAuth } from "../../../context/Auth/AuthProvider";

interface UseScheduleFormInputsProps {
  resourceName: TABLE_NAMES;
  scheduleData?: ScheduleDataDto | null;
}

export const useScheduleFormInputs = (
  parameters: UseScheduleFormInputsProps
) => {
  const { user } = useAuth();
  const { t } = useTranslation();

  const { convertDateToUTC } = useDateTime();

  const { getConvertedTimeValue, getDateValue } = useScheduleDateTimeValues();

  const { resourceName, scheduleData } = parameters;

  const {
    initialSchedulePayload,
    activityDatesInitialState,
    updateActivityDates,
  } = useScheduleResourceFunctions(resourceName);

  const {
    checkIfRepeatValueIsValid,
    validateCustomRepeatInput,
  } = useScheduleRepeatValues();

  const [schedulePayload, setSchedulePayload] = useState<SchedulePayloadDto>(
    initialSchedulePayload
  );

  const [inputValues, setInputValues] = useState<InputValuesDto>({
    name: "",
    startDate: "",
    customRepeat: "",
  });

  const [repeatPeriod, setRepeatPeriod] = useState<ScheduleRepeatPeriod | null>(
    null
  );

  const [inputError, setInputError] = useState({
    name: "",
    startDate: "",
  });

  const [activityDates, setActivityDates] = useState<ActivityDates>(
    activityDatesInitialState
  );

  const [hasUserInteracted, setHasUserInteracted] = useState<boolean>(false);

  const fullDate = getDateValue(inputValues, scheduleData) ?? "";

  const [datePart, timePart] = fullDate.split(" ");

  const [startDateTime, setStartDateTime] = useState<string | null>(timePart);
  const [isInheritanceEnabled, setIsInheritanceEnabled] = useState<boolean>(
    scheduleData?.inheritanceEnabled || false
  );
  const [isAttachedToRoot, setIsAttachedToRoot] = useState<boolean>(
    scheduleData?.attachedToRoot || false
  );

  const handleTimeChange = (value: string) => {
    getConvertedTimeValue(value, datePart, setStartDateTime, handleFormChange);
  };

  const { fileTypes, name, repeat, startDate } = schedulePayload;

  // set global schedule inheritance to the payload
  useEffect(() => {
    setSchedulePayload({
      ...schedulePayload,
      inheritanceEnabled: isInheritanceEnabled,
      attachedToRoot: isAttachedToRoot,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInheritanceEnabled, isAttachedToRoot, scheduleData, name]);

  useLayoutEffect(() => {
    updateActivityDates(
      schedulePayload,
      repeatPeriod,
      inputError,
      setSchedulePayload,
      setActivityDates
    );

    setStartDateTime(timePart);

    if (repeatPeriod?.days && repeat === REPEAT_NAME_VALUES.Custom) {
      setInputValues({ ...inputValues, customRepeat: repeatPeriod?.days });
    }

    // eslint-disable-next-line
  }, [repeatPeriod, startDate, fileTypes]);

  const SCHEDULE_NAME_INPUT_LIMIT = 20;

  const hadleToggleGlobalLinkSwitch = () => {
    // If user is in root company, then inheritanceEnabled value is important,
    // since this is an indicator to attach all children's global schedule to root company.
    // If user is not in root company, then isAttachedToRoot value is important,
    // because this is an indicator to detach child's global schedule from root company.
    user?.rootCompanySelected
      ? setIsInheritanceEnabled(!isInheritanceEnabled)
      : setIsAttachedToRoot(!isAttachedToRoot);
    setHasUserInteracted(true);
  };

  const validateScheduleName = (e: { target: { value: string } }) => {
    const inputValue = e.target.value;

    const nameInputError =
      inputValue.length > SCHEDULE_NAME_INPUT_LIMIT
        ? t(`Schedules##name not valid`, {
            maxNumber: SCHEDULE_NAME_INPUT_LIMIT,
          })
        : "";

    if (inputValue.length === 0) {
      return setInputError({
        ...inputError,
        name: t("Dialog##name should not be empty"),
      });
    }

    setInputError({
      ...inputError,
      name: nameInputError,
    });
  };

  const validateStartDate = (
    error: string | null | SetStateAction<string | null>
  ) => {
    const errorMessage = error ? String(error) : "";

    setInputError({
      ...inputError,
      startDate: errorMessage,
    });
  };

  const handleNameInput = (value: string, global: boolean | undefined) => {
    setInputValues({
      ...inputValues,
      name: value,
    });

    setSchedulePayload({
      ...schedulePayload,
      name: value,
      global: Boolean(global),
    });

    setInputError({
      ...inputError,
      name: "",
    });
  };

  const handleStartDateInput = (dateInput: string) => {
    const clearedDateInput = dateInput.includes("null");

    setInputValues({
      ...inputValues,
      startDate: dateInput,
    });

    const convertedDate = clearedDateInput ? "" : convertDateToUTC(dateInput);

    setSchedulePayload({
      ...schedulePayload,
      startDate: convertedDate,
    });
  };

  const handleRepeatDropdown = (option: ScheduleRepeatPeriod | null) => {
    setRepeatPeriod(option);

    setSchedulePayload({
      ...schedulePayload,
      repeat: option?.value ?? null,
      customRepeat: 0,
    });

    setInputValues({
      ...inputValues,
      customRepeat: "",
    });
  };

  const handleCustomRepeat = (value: string) => {
    const inputValue = value.replace(/\D/g, ""); // Allow only numbers
    if (inputValue.length < 4) {
      const validatedNumber = validateCustomRepeatInput(inputValue);

      setInputValues({
        ...inputValues,
        customRepeat: validatedNumber,
      });

      setSchedulePayload({
        ...schedulePayload,
        customRepeat: validatedNumber as number,
      });

      setRepeatPeriod({
        days: validatedNumber as number,
        id: "Custom_schedule_period",
        name: REPEAT_NAME_VALUES.Custom,
        value: REPEAT_NAME_VALUES.Custom,
      });
    }
  };

  const handleDataTypes = (value: VehicleDataTypes) => {
    const isAlreadySelected = fileTypes.includes(value)
      ? fileTypes.filter((val) => val !== value)
      : [...fileTypes, value];

    setSchedulePayload({
      ...schedulePayload,
      fileTypes: isAlreadySelected,
    });
  };

  const resetValueStates = () => {
    setInputError({
      name: "",
      startDate: "",
    });

    setRepeatPeriod(null);

    setActivityDates(activityDatesInitialState);

    setInputValues({
      name: "",
      startDate: "",
      customRepeat: "",
    });

    setStartDateTime(null);

    setSchedulePayload(initialSchedulePayload);

    setHasUserInteracted(false);
  };

  const handleFormChange = (
    key: SCHEDULE_PAYLOAD_NAMES,
    value: string | ScheduleRepeatPeriod | VehicleDataTypes | null | boolean,
    global?: boolean
  ) => {
    setHasUserInteracted(true);

    switch (key) {
      case SCHEDULE_PAYLOAD_NAMES.Name:
        handleNameInput(value as string, global);
        break;
      case SCHEDULE_PAYLOAD_NAMES.StartDate:
        handleStartDateInput(value as string);
        break;
      case SCHEDULE_PAYLOAD_NAMES.Repeat:
        handleRepeatDropdown(value as ScheduleRepeatPeriod | null);
        break;

      case SCHEDULE_PAYLOAD_NAMES.CustomRepeat:
        handleCustomRepeat(value as string);
        break;

      case SCHEDULE_PAYLOAD_NAMES.FileTypes:
        handleDataTypes(value as VehicleDataTypes);
        break;

      default:
        return;
    }
  };

  const readyToSubmit =
    !inputError.name &&
    !inputError.startDate &&
    name &&
    startDate &&
    checkIfRepeatValueIsValid(repeatPeriod?.days) &&
    startDateTime &&
    hasUserInteracted;

  return {
    schedulePayload,
    repeatPeriod,
    activityDates,
    inputError,
    inputValues,
    startDateTime,
    readyToSubmit,
    hasUserInteracted,
    handleFormChange,
    handleTimeChange,
    resetValueStates,
    setRepeatPeriod,
    setSchedulePayload,
    validateScheduleName,
    validateStartDate,
    hadleToggleGlobalLinkSwitch,
    isInheritanceEnabled,
    isAttachedToRoot,
  };
};
