import { CSSObject } from "@emotion/react";

import { ThemeColors } from "../../shared";

export const textStyles = {
  fontWeight: "600",
  lineHeight: "20px",
  letterSpacing: "0.15px",
};

export const useStyles = (
  colors?: ThemeColors,
  textStyles?: any,
  toMd?: boolean,
  customDatePicker?: boolean
) => {
  const isNotCustomDatePickerOrToMd = toMd || !customDatePicker;

  return {
    datePickerOverride: {
      "& .MuiDateCalendar-root": {
        width: isNotCustomDatePickerOrToMd ? "324px" : "516px",
        height: isNotCustomDatePickerOrToMd ? "324px" : "348px",
        position: customDatePicker ? "relative" : undefined,
      },

      "& .MuiDayCalendar-weekDayLabel": {
        margin: isNotCustomDatePickerOrToMd ? "0 0 4px 0" : "0 14px",
      },

      "& .MuiDayCalendar-weekContainer": {
        margin: isNotCustomDatePickerOrToMd ? "4px 0" : "6px 0",
      },

      "& .MuiPickersDay-root": {
        color: colors?.textDark,
        fontSize: "16px",
        textAlign: customDatePicker ? "center" : undefined,
        width: "32px",
        height: "32px",
        margin: isNotCustomDatePickerOrToMd ? "0 6px" : "0px 20px",
        ...textStyles,

        "&.Mui-selected": {
          background: colors?.blue700,
          color: colors?.white,
          fontWeight: "600",
        },

        "&.Mui-selected:focus": {
          background: colors?.blue700,
        },

        "&.Mui-disabled:not(.Mui-selected)": {
          color: colors?.textDarkDisabled,
          textDecoration: "line-through",
        },
      },

      "& .MuiPickersDay-root:focus": {
        background: colors?.white,
      },

      "& .MuiPickersDay-root:not(.Mui-selected)": {
        borderColor: colors?.gray300,
      },
    },

    // remove code duplication in sonarqube:
    datePickerSharedStyles: {
      "& .MuiPickersCalendarHeader-root": {
        margin: "16px 0",
        padding: "0 24px",
        minHeight: "unset",
      },

      "& .MuiPickersCalendarHeader-label": {
        color: colors?.textDark,
        marginRight: "4px",
        textTransform: " capitalize",
        ...textStyles,
      },

      // Fixes library bug when changing month
      "& .MuiPickersCalendarHeader-label:nth-of-type(2)": {
        position: "absolute",
        top: "0",
        left: "0",
      },

      "& .MuiPickersCalendarHeader-switchViewButton": {
        padding: "4px",
        svg: {
          fontSize: "16px",
          color: colors?.textDark,
        },
      },

      "& .MuiDayCalendar-header": {
        marginBottom: "4px",
      },

      "& .MuiPickersYear-yearButton, .MuiPickersMonth-monthButton": {
        color: colors?.gray700,
        height: "32px",
        margin: "4px 0",
        width: "calc(100% - 8px)",
        ...textStyles,

        "&.Mui-selected": {
          color: colors?.white,
          background: colors?.blue700,
        },
        "&.Mui-selected:focus": {
          background: colors?.blue700,
        },
      },

      "& .MuiYearCalendar-root, .MuiMonthCalendar-root": {
        width: "unset",
        marginTop: "-4px",
        padding: "0 8px 12px 8px",
      },

      "& .MuiYearCalendar-root": {
        maxHeight: "252px",
      },

      "& .MuiDayCalendar-weekDayLabel": {
        color: colors?.gray700,
        fontSize: "16px",
        lineHeight: "20px",
        width: "44px",
        height: "unset",
      },
    },

    customDatePickerActionButtons: {
      display: "flex",
      justifyContent: "space-between",
      padding: 2,
      position: "absolute",
      bottom: 0,
      right: 0,
      gap: "16px",
    },
    timeZoneExplanatorySpan: {
      position: "absolute",
      top: "0",
      left: "90px",
      display: "flex",
      alignItems: "center",
      fontSize: "14px",
      fontWeight: "600",
      lineHeight: "20px",
      letterSpacing: "0.1px",
      padding: "12px 0",
    },
  } as CSSObject;
};
