import { CSSObject } from "@emotion/react";
import { ThemeColors } from "../../../../../../shared";

const collapsableCardMaxHeight = (isOpen: boolean) => (isOpen ? "500px" : 0);
const collapsableCardOpacity = (isOpen: boolean) => (isOpen ? 1 : 0);
const transitionTiming = "0.2s";

interface UseStyleProps {
  colors?: ThemeColors;
  isOpen?: boolean;
  fromDialog?: boolean;
  addMaxHeight?: boolean;
}

export const useStyles = ({ colors, isOpen, fromDialog, addMaxHeight }: UseStyleProps) =>
  ({
    acordionDetailsCardTitleContainer: {
      margin: "16px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    acordionDetailsCardTitle: {
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
      alignItems: "center",
    },
    accordionItemTitleContainer: {
      margin: "16px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    collapsableItemContentDiv: {
      // aparently maxHeight, if it is in the div, which also has maxHeigth, will have scroll thumb flickering effect
      maxHeight: !fromDialog && addMaxHeight
        ? collapsableCardMaxHeight(isOpen || false)
        : undefined,
      opacity: collapsableCardOpacity(isOpen || false),
      transition: `all ease-in-out ${transitionTiming}`,
      margin: "0 16px",
      overflow: "auto",
    },
    notCollapsableItemContentDiv: {
      maxHeight: "500px",
      opacity: 1,
      margin: "0 16px",
    },
    accordionItemTitleArea: {
      display: "flex",
      alignItems: "center",
    },
    accordionItemIconMargin: {
      "div:first-of-type": {
        marginRight: "8px",
      },
    },
    titleInlineIconDiv: {
      marginLeft: "4px",
    },
  } as CSSObject);
