import { useTranslation } from "react-i18next";
import { Dispatch, SetStateAction } from "react";

import { ImportFileErrorMessagetype } from "./ImportResourceDialog";

import InputField from "../../../../../InputField/InputField";
import Dropzone from "../../../../../Dropzone/Dropzone";
import SampleTemplate from "../../../../../Dropzone/SampleTemplate/SampleTemplate";
import { FILE_NAME } from "../../../../../Dialog/types/enums";
import BannerMessage, {
  BANNER_MESSAGE_STATUSES,
} from "../../../../../BannerMessage/BannerMessage";

import { useAuth } from "../../../../../../context/Auth/AuthProvider";
import { INPUT_LENGTH, inputFieldMarginBottom } from "../../../../../../shared";

interface ImportResourceDialogContentProps {
  fileName: FILE_NAME;
  tooltipText: string;
  errorBannerMessage: ImportFileErrorMessagetype | null;
  setErrorbannerMessage: Dispatch<
    SetStateAction<ImportFileErrorMessagetype | null>
  >;
  disabledDropzone: boolean;
  showMoreText?: boolean;
  setShowMoreText?: Dispatch<SetStateAction<boolean>>;
  expandableBanner?: boolean;
}

const ImportResourceDialogContent = ({
  fileName,
  tooltipText,
  errorBannerMessage,
  setErrorbannerMessage,
  disabledDropzone,
  showMoreText,
  setShowMoreText,
  expandableBanner = false,
}: ImportResourceDialogContentProps) => {
  const { t } = useTranslation();
  const { user } = useAuth();

  return (
    <>
      <InputField
        id={user?.selectedCompany.name}
        size="medium"
        data-testid={`import-resource-company-name`}
        fullWidth
        readOnly
        labelLeft={t("Dialog##company")}
        placeholder={user?.selectedCompany.name}
        customStyle={inputFieldMarginBottom}
      />
      {errorBannerMessage && (
        <BannerMessage
          title={errorBannerMessage?.title}
          description={errorBannerMessage?.description}
          collapsedDescription={errorBannerMessage?.collapsedDescription}
          status={BANNER_MESSAGE_STATUSES.Critical}
          externalCustomStyle={{ marginBottom: "16px" }}
          expandable={expandableBanner}
          showMoreText={showMoreText}
          setShowMoreText={setShowMoreText}
        />
      )}
      <Dropzone
        data-testid="import-resource-dropzone"
        supportedFormats={[".csv"]}
        maxFileNameLength={INPUT_LENGTH.Name}
        countCsv
        tooltipText={tooltipText}
        setErrorbannerMessage={setErrorbannerMessage}
        isDisabled={disabledDropzone}
      />
      <SampleTemplate fileName={fileName} />
    </>
  );
};

export default ImportResourceDialogContent;
