/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Typography } from "@mui/material";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

import { useStyles } from "../styles";
import { DownloadHistoryObjectType, DownloadStatusesTypes } from "../types";

import { useMediaQueries } from "../../../../../../../shared";
import { replaceCamelCaseStringToRegularSentence } from "../../../../../../../shared/functions/functions";
import { ThemeContext } from "../../../../../../../context/theme/ThemeContextProvider";
import CircleStepperErrorIcon from "../../../../../../../assets/customIcons/steppers/CircleStepperErrorIcon";
import CircleStepperSuccessIcon from "../../../../../../../assets/customIcons/steppers/CircleStepperSuccessIcon";

interface DownloadHistorySingleItemProps {
  history: DownloadHistoryObjectType;
}

const DownloadHistorySingleItem = ({
  history,
}: DownloadHistorySingleItemProps) => {
  const { colors } = useContext(ThemeContext);

  const { fromMd } = useMediaQueries();
  const { t } = useTranslation();

  const {
    historyInfoItem,
    historyItemIconStyle,
    infoTitleDiv,
    titleWithIconBlock,
  } = useStyles({
    fromMd,
    colors,
  });

  const scheduleInfo = history.vehicleSchedule || history.driverSchedule;
  const driverData = history.driver;

  // Date Fromat is handled in useDownloadHistory while creating history data Object
  // and there is no need to format it again here. Only need to remove date part
  const timeWithoutDate = history.date.slice(11);

  const downloadSuccessfullText = t("Download history##download successful");
  const infoTitleText =
    history.details || replaceCamelCaseStringToRegularSentence(history.status);

  const generateDownloadSuccessfulText = () => {
    const uploadingVehicleFiles =
      history.status === DownloadStatusesTypes.UploadingVehicleFiles;

    const uploadingDriverFiles =
      history.status === DownloadStatusesTypes.UploadingDriverFiles;

    const stageComplete =
      history.status === DownloadStatusesTypes.StageComplete;

    if (!history.failedStep) {
      return (
        (uploadingVehicleFiles || uploadingDriverFiles || stageComplete) &&
        downloadSuccessfullText
      );
    }
    return "";
  };

  const generateHistoryItemIcon = () =>
    history.failedStep === true ? (
      <CircleStepperErrorIcon css={css(historyItemIconStyle)} />
    ) : (
      <CircleStepperSuccessIcon css={css(historyItemIconStyle)} />
    );

  const generateHistorySubtitle = (prop: string, value: string) => (
    <div css={css(infoTitleDiv)}>
      <Typography variant="body3semiBold" sx={{ color: colors.textSecondary }}>
        {prop}: <span css={css({ color: colors.textDark })}>{value}</span>
      </Typography>
    </div>
  );

  return (
    <div css={css(historyInfoItem)} data-testid="history-info-item">
      <div css={css(titleWithIconBlock)}>
        {generateHistoryItemIcon()}

        <div css={css(infoTitleDiv)}>
          <Typography variant="body3semiBold" sx={{ marginTop: "8px" }}>
            {generateDownloadSuccessfulText() || infoTitleText}
          </Typography>

          {scheduleInfo &&
            generateHistorySubtitle(
              t("Download history##schedule"),
              scheduleInfo
            )}

          {driverData &&
            generateHistorySubtitle(
              t("Download history##driver data"),
              driverData
            )}
        </div>
      </div>
      <Typography
        variant="body3semiBold"
        sx={{ color: colors.textPlaceholder }}
      >
        {timeWithoutDate}
      </Typography>
    </div>
  );
};

export default DownloadHistorySingleItem;
