import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useRecoilValue } from "recoil";
import { AxiosError } from "axios";

import EditUserDialogContent from "./EditUserDialogContent";
import Dialog from "../../../../../Dialog/Dialog";
import {
  GeneralDialogProps,
  useMediaQueries,
  USERS_ACTIONS,
} from "../../../../../../shared";
import DialogActions from "../../../../../../shared/components/buttons/DialogActions";
import { capitalizeStringOnlyFirstLetter } from "../../../../../../shared/functions/functions";
import { searchQueryParams } from "../../../../../../atoms/atoms";
import useAxiosPrivate from "../../../../../../api/hooks/useAxiosPrivate";
import {
  AlertMessageResources,
  ApiResources,
} from "../../../../../../api/resources";
import useResponse from "../../../../../../shared/hooks/useResponse";
import { ALERT_STATUS } from "../../../../../../context/alert/types";
import useRefreshTableData from "../../../../../../shared/hooks/useRefreshTableData";

interface IEditUserDialogProps {
  rowData: any;
}

const EditUserDialog = ({
  isOpen,
  onClose,
  rowData,
}: IEditUserDialogProps & GeneralDialogProps) => {
  const { t } = useTranslation();
  const { toMd } = useMediaQueries();
  const { putData } = useAxiosPrivate();
  const { handleResponse } = useResponse();
  const { refreshTableData } = useRefreshTableData();

  const searchParams = useRecoilValue(searchQueryParams);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isReady, setIsReady] = useState<boolean>(false);
  const [payload, setPayload] = useState<any>(null);

  const valueInSearchField = searchParams.usersPage || null;
  const resourceString = AlertMessageResources.User;

  const currentRole = {
    id: capitalizeStringOnlyFirstLetter(rowData.role),
    name: capitalizeStringOnlyFirstLetter(rowData.role),
  };

  const handleCloseEditUserDialog = () => {
    onClose(USERS_ACTIONS.EditUser);
  };

  const resetAllValues = () => {
    setIsReady(false);
    setPayload(null);
  };

  const handleSubmit = async () => {
    if (isReady) {
      try {
        setIsLoading(true);
        await putData(ApiResources.Users, payload);

        handleResponse(
          ALERT_STATUS.Success,
          t(`Alert##successfully edited`, { resourceString }),
          t("Alert##edit user description")
        );
      } catch (error) {
        const err = error as AxiosError<any>;
        if (err) {
          handleResponse(
            ALERT_STATUS.Critical,
            err.response?.data.message || err.message
          );
        }
      } finally {
        setIsLoading(false);
      }
      refreshTableData(undefined, valueInSearchField || undefined);
      handleCloseEditUserDialog();
    }
  };

  return (
    <Dialog
      data-testid={`edit-user-dialog`}
      title={t("Dialog##edit user")}
      extendToMaxWidth={toMd}
      isTitleSeparator
      isActionsSeparator
      open={isOpen}
      close={handleCloseEditUserDialog}
      submit={handleSubmit}
      TransitionProps={{
        onExited: () => resetAllValues(),
      }}
      actions={
        <DialogActions
          onClose={handleCloseEditUserDialog}
          isLoading={isLoading}
          isreadyToConfirm={isReady}
          confirmationButtonText={t("Button##save")}
          confirmationButtonTestId="save-edit-user-button"
          cancelButtonText={t("Button##close")}
          cancelButtonTestId="close-edit-user-button"
        />
      }
    >
      <EditUserDialogContent
        rowData={rowData}
        initialRole={currentRole}
        setPayload={setPayload}
        setIsReady={setIsReady}
        isLoading={isLoading}
      />
    </Dialog>
  );
};

export default EditUserDialog;
