// Extracted Helper Functions Outside the Hook
const getToolbarHeightBase = (
  toMd: boolean,
  hasTableView?: boolean,
  hasTabFilters?: boolean
) => {
  if (hasTableView) return "104px";
  if (hasTabFilters) return getMobileToolbarHeight(toMd);
  return "60px";
};

const getTableToolbarHeight = (
  toMd: boolean,
  hasSearch: boolean,
  hasTableView?: boolean,
  filtersHeightValue = 0,
  hasTabFilters?: boolean
) => {
  if (!hasSearch) return "42px";

  const heightInMd = getToolbarHeightBase(toMd, hasTableView, hasTabFilters);
  const heightWithSearch = toMd ? heightInMd : "68px";

  return `calc(${heightWithSearch} + ${filtersHeightValue}px)`;
};

const getTableToolbarPaddings = (toMd: boolean, hasTableView?: boolean) => {
  const paddingBottom = hasTableView ? "0px" : "8px";
  return toMd ? `16px 24px ${paddingBottom} 24px` : "16px 24px";
};

const getDesktopTableFooterHeight = (toLg: boolean) =>
  toLg ? "104px" : "52px";

const getMobileToolbarHeight = (toMd: boolean) => (toMd ? "112px" : "120px");

const useGeneralHeights = (mainBannerHeight: number) => {
  const XSM_PAGE_HEADER_HEIGHT = "100px";
  const PAGE_HEADER_HEIGHT = "68px";
  const NAVBAR_HEIGHT =
    mainBannerHeight > 0 ? `calc(${mainBannerHeight}px + 56px)` : "56px";
  const OPEN_DESKTOP_DRAWER_WIDTH_WITHOUT_PADDING = "204px";
  const CLOSE_DESKTOP_DRAWER_WIDTH_WITHOUT_PADDING = "36px";
  const OPEN_DESKTOP_DRAWER_WIDTH_WITH_PADDING = "236px";
  const CLOSE_DESKTOP_DRAWER_WIDTH_WITH_PADDING = "68px";
  const FOOTER_HEIGHT_TO_LG = "104px";
  const DESKTOP_TABLE_HEIGHT = `calc(100vh - ${PAGE_HEADER_HEIGHT} - ${NAVBAR_HEIGHT})`;
  const MOBILE_TABLE_HEIGHT = `calc(100vh - ${XSM_PAGE_HEADER_HEIGHT} - ${NAVBAR_HEIGHT})`;
  const MOBILE_TABLE_HEIGHT_WITHOUT_FOOTER = `calc(100vh - ${XSM_PAGE_HEADER_HEIGHT} - ${NAVBAR_HEIGHT} + ${FOOTER_HEIGHT_TO_LG})`;
  const COMPANIES_SX_MOBILE_TABLE_HEIGHT = `calc(100vh - ${XSM_PAGE_HEADER_HEIGHT} - ${NAVBAR_HEIGHT} + ${FOOTER_HEIGHT_TO_LG} - 20px)`;
  const TAB_FILTERS_HEIGHT = "52px";
  const INITIAL_DETAILS_TITLE_AREA_HEIGHT_WITH_SHOW_MORE = 101;
  const DETAILS_TITLE_AREA_FIXED_HEIGHT = 103;
  const EMPTY_TABLE_HEADER_HEIGHT = "42px";
  const TIME_INPUT_WIDTH = "108px";
  const TOOLBAR_WITH_TABLE_VIEW = "104px";
  const DEFAULT_TOOLBAR_HEIGHT = 60;
  const MOBILE_TOOLBAR_HEIGHT = 68;
  const dashboardContainerBottomMargin = "12px";
  const DashboardContainerHeight = `calc(100vh - ${PAGE_HEADER_HEIGHT} - ${NAVBAR_HEIGHT} - ${dashboardContainerBottomMargin})`;

  return {
    TABLE_TOOLBAR_HEIGHT: getTableToolbarHeight,
    TABLE_TOOLBAR_PADDINGS: getTableToolbarPaddings,
    DESKTOP_TABLE_FOOTER_HEIGHT: getDesktopTableFooterHeight,
    MOBILE_TABLE_TOOLBAR_AND_SEARCH_HEIGHT: getMobileToolbarHeight,
    XSM_PAGE_HEADER_HEIGHT,
    PAGE_HEADER_HEIGHT,
    NAVBAR_HEIGHT,
    OPEN_DESKTOP_DRAWER_WIDTH_WITHOUT_PADDING,
    CLOSE_DESKTOP_DRAWER_WIDTH_WITHOUT_PADDING,
    OPEN_DESKTOP_DRAWER_WIDTH_WITH_PADDING,
    CLOSE_DESKTOP_DRAWER_WIDTH_WITH_PADDING,
    FOOTER_HEIGHT_TO_LG,
    DESKTOP_TABLE_HEIGHT,
    MOBILE_TABLE_HEIGHT,
    MOBILE_TABLE_HEIGHT_WITHOUT_FOOTER,
    COMPANIES_SX_MOBILE_TABLE_HEIGHT,
    TAB_FILTERS_HEIGHT,
    INITIAL_DETAILS_TITLE_AREA_HEIGHT_WITH_SHOW_MORE,
    DETAILS_TITLE_AREA_FIXED_HEIGHT,
    EMPTY_TABLE_HEADER_HEIGHT,
    TIME_INPUT_WIDTH,
    TOOLBAR_WITH_TABLE_VIEW,
    DEFAULT_TOOLBAR_HEIGHT,
    MOBILE_TOOLBAR_HEIGHT,
    dashboardContainerBottomMargin,
    DashboardContainerHeight,
  };
};

export default useGeneralHeights;
