/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext, Fragment } from "react";
import { useTranslation } from "react-i18next";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import { Typography } from "@mui/material";

import { useStyles } from "./styles";
import { UploadedFileSingleProps } from "../types";

import Tooltip from "../../Tooltip/Tooltip";
import Button from "../../Button/Button";
import Separator from "../../Separator/Separator";
import Tag from "../../Tag/Tag";
import { TAG_COLORS, TAG_SIZES } from "../../Tag/enums";
import DropzoneContext from "../../../context/dropzone/dropzoneContext";
import FileIcon from "../../../assets/customIcons/FileIcon";
import { useFormattedNumber } from "../../../shared";
import { ThemeContext } from "../../../context/theme/ThemeContextProvider";

const UploadedFileSingle = ({
  countCsv,
  setErrorbannerMessage,
  isDisabled
}: UploadedFileSingleProps) => {
  const { colors } = useContext(ThemeContext);
  const { file, lineCount, setFile } = useContext(DropzoneContext);

  const { t } = useTranslation();
  const { renderFormattedNumber } = useFormattedNumber();

  const { mainDivCSS, upperRowCSS, fileNameAreaCSS, fileIconCSS } = useStyles(
    colors
  );

  const handleDeleteFileButtonClick = () => {
    setFile(null);
    setErrorbannerMessage(null);
  };

  return (
    <div css={css(mainDivCSS)}>
      <div css={css(upperRowCSS)}>
        <div css={css(fileNameAreaCSS)}>
          <FileIcon css={css(fileIconCSS)} />
          <Typography
            variant="body2"
            sx={{
              color: colors?.gray700,
            }}
          >
            {file?.name}
          </Typography>
        </div>

        <Tooltip
          placement="top"
          title={t("Button##delete")}
          disableTouchListener
        >
          <Button
            data-testid="delete-file-button"
            color="primarySubtle"
            size="small"
            variant="iconOnly"
            icon={<DeleteOutlineRoundedIcon />}
            disabled={isDisabled}
            onClick={handleDeleteFileButtonClick}
            css={css({
              alignSelf: "flex-start",
            })}
          />
        </Tooltip>
      </div>

      {countCsv && (
        <Fragment>
          <Separator
            css={css({
              margin: "16px 0",
            })}
          />

          <div
            css={css({
              display: "flex",
              alignItems: "Center",
            })}
          >
            <Tag
              data-testid="line-count-tag"
              size={TAG_SIZES.Tiny}
              color={TAG_COLORS.White}
              title={renderFormattedNumber(lineCount)}
            />

            <Typography
              variant="body2"
              sx={{
                color: colors.gray700,
                marginLeft: "8px",
              }}
            >
              {t("Dropzone##rows detected")}
            </Typography>
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default UploadedFileSingle;
