import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";
import moment from "moment";

import { FILTER_KEYS, FILTER_NAMES, FilterParamsType } from "../types";

import {
  getPageGlobalStateObjectValue,
  pageGlobalStateObjectResult,
} from "../../Table/functions";

import { currPage, filterQueryParams } from "../../../atoms/atoms";
import { Filter, useDateTime } from "../../../shared";

const useFilteredValues = () => {
  const location = useLocation();
  const { switchedDashToSlashDateFormatWithoutTime } = useDateTime();

  const [filterParams, setFilterParams] = useRecoilState(filterQueryParams);
  const [, setPageToShow] = useRecoilState(currPage);

  const [filteredValues, setFilteredValues] = useState<Filter[]>([]);

  // for filter identification object
  const dateRangeFilterObject = {
    id: FILTER_KEYS.Date,
    name: FILTER_KEYS.Date,
  };

  const formatDateString = (date: string) =>
    moment(date).format(switchedDashToSlashDateFormatWithoutTime);

  const processFilterValues = (
    filterValues: Filter[],
    filteredValues: Filter[]
  ) => {
    let hasDateRange = false;
    let hasBothDateRangeValues = false;

    filterValues.forEach((v: Filter) => {
      hasDateRange =
        v.name === FILTER_KEYS.DateRangeFrom ||
        v.name === FILTER_KEYS.DateRangeTo;
      hasBothDateRangeValues =
        v.name !== FILTER_KEYS.DateRangeFrom &&
        v.name !== FILTER_KEYS.DateRangeTo;

      if (hasBothDateRangeValues) {
        filteredValues.push(v);
      }
    });

    return { hasDateRange, filteredValues };
  };

  const addDateRangeFilter = (val: any, filteredValues: Filter[]) => {
    const dateRangeDisplayValue = {
      id: FILTER_KEYS.Date,
      name: `${formatDateString(val[0].id)} - ${formatDateString(val[1].id)}`,
    };
    filteredValues.push(dateRangeDisplayValue);
  };

  const getFilteredValues = (filterObjectFromLocalStorage: any) => {
    const filteredValues: Filter[] = [];
    if (filterObjectFromLocalStorage) {
      for (const val of Object.values(filterObjectFromLocalStorage)) {
        const filterValues: Filter[] = val as Filter[];

        const {
          hasDateRange,
          filteredValues: updatedFilteredValues,
        } = processFilterValues(filterValues, filteredValues);

        if (hasDateRange) {
          addDateRangeFilter(val, updatedFilteredValues);
        }
      }
    }
    return filteredValues;
  };

  useEffect(() => {
    const filterObjectFromLocalStorage = pageGlobalStateObjectResult(
      location.pathname,
      filterParams
    );
    setFilteredValues(getFilteredValues(filterObjectFromLocalStorage));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterParams, location.pathname]);

  const updateCurrentFilters = (
    key: string,
    filteredValuesWithoutCurrentItem: Filter[],
    currentFilters: {
      [key: string]: any;
    }
  ) => ({
    ...currentFilters,
    [key]: filteredValuesWithoutCurrentItem,
  });

  const mapThroughFiltersAndRemoveValue = (
    currentFilters: { [key: string]: any },
    filterItem: Filter
  ) => {
    const dateRangeItem = filterItem?.id === FILTER_KEYS.Date; // check if date range filter is clicked

    let allFilters: { [key: string]: any } = currentFilters;

    for (const [key, val] of Object.entries(currentFilters)) {
      let values: any = val;

      const dateRangeValues = values.filter(
        (v: Filter) =>
          v.name === FILTER_KEYS.DateRangeFrom ||
          v.name === FILTER_KEYS.DateRangeTo
      );

      // if date range exists in the filtered values, remove all the date range values on date value click
      if (
        currentFilters.hasOwnProperty(FILTER_NAMES.DateRange) &&
        dateRangeItem &&
        dateRangeValues.length > 0
      ) {
        const emptyDateRangeArray: Filter[] = []; // remove all the date range values at once

        allFilters = updateCurrentFilters(
          key,
          emptyDateRangeArray,
          currentFilters
        );

        //  else - remove only the clicked filter value
      } else if (values.includes(filterItem)) {
        const filteredValuesWithoutCurrentItem = values.filter(
          (val: Filter) => val !== filterItem
        );

        allFilters = updateCurrentFilters(
          key,
          filteredValuesWithoutCurrentItem,
          currentFilters
        );
      }
    }
    // the line below actually removes filter item from local storage
    setFilterParams((prev: FilterParamsType) =>
      getPageGlobalStateObjectValue(location.pathname, prev, allFilters)
    );
  };

  const resetCurrentPage = () =>
    setPageToShow((prev: any) =>
      getPageGlobalStateObjectValue(location.pathname, prev, "1")
    );

  const handleDeleteFilterValue = (filterItem: Filter) => {
    const currentFilters = pageGlobalStateObjectResult(
      location.pathname,
      filterParams
    );

    mapThroughFiltersAndRemoveValue(currentFilters, filterItem);

    resetCurrentPage();
  };

  return {
    filteredValues,
    mapThroughFiltersAndRemoveValue,
    handleDeleteFilterValue,
    resetCurrentPage,
    dateRangeFilterObject,
  };
};

export default useFilteredValues;
