import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import { useAuth } from "../../../../../../context/Auth/AuthProvider";
import { inputFieldMarginBottom } from "../../../../../../shared";
import InputField from "../../../../../InputField/InputField";

interface IAddDriverDialogContentProps {
  onCardHolderNameChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  validateCardHolderName: (event: any) => void;
  cardHolderNameErrorText: string | null;
  validateIdInput: (e: any) => void;
  idInputError: string | null;
  onIdValueChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onDescriptionChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  validateDescription: (event: any) => void;
  descriptionErrorText: string | null;
  isLoading: boolean;
  cardHolderName?: string | null;
}

const AddDriverDialogContent = ({
  onCardHolderNameChange,
  validateCardHolderName,
  cardHolderNameErrorText,
  validateIdInput,
  idInputError,
  onIdValueChange,
  onDescriptionChange,
  validateDescription,
  descriptionErrorText,
  isLoading,
  cardHolderName,
}: IAddDriverDialogContentProps) => {
  const { user } = useAuth();
  const { t } = useTranslation();

  return (
    <>
      <InputField
        id={uuidv4()}
        size="medium"
        data-testid="add-driver-company-name"
        fullWidth
        readOnly
        labelLeft={t("Dialog##company")}
        placeholder={user?.selectedCompany.name}
        customStyle={inputFieldMarginBottom}
      />
      <InputField
        id={uuidv4()}
        testId="card-holder-name-input-field"
        size="medium"
        fullWidth
        required
        value={cardHolderName}
        errorText={cardHolderNameErrorText}
        onBlur={validateCardHolderName}
        onChange={onCardHolderNameChange}
        labelLeft={t("Dialog##card holder name")}
        placeholder={t("Dialog##enter here")}
        customStyle={inputFieldMarginBottom}
        disabled={isLoading}
      />
      <InputField
        id={uuidv4()}
        data-testid="driver-id-input-field"
        size="medium"
        fullWidth
        required
        errorText={idInputError}
        onBlur={validateIdInput}
        onChange={onIdValueChange}
        labelLeft={t("Dialog##driver id")}
        placeholder={t("Dialog##driver id placeholder")}
        customStyle={inputFieldMarginBottom}
        disabled={isLoading}
      />
      <InputField
        id={uuidv4()}
        data-testid="driver-description-input-field"
        size="medium"
        fullWidth
        errorText={descriptionErrorText}
        onBlur={validateDescription}
        onChange={onDescriptionChange}
        labelLeft={t("Dialog##description")}
        placeholder={t("Dialog##enter here")}
        customStyle={inputFieldMarginBottom}
        disabled={isLoading}
      />
    </>
  );
};

export default AddDriverDialogContent;
