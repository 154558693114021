import { CSSObject } from "@emotion/react";
import { detailsAndDrawerLayoutState } from "../types";

const generateBreakOfDesktopScreen = (
  layoutState: detailsAndDrawerLayoutState
) => {
  switch (layoutState) {
    case detailsAndDrawerLayoutState.BothOpen:
      return {
        maxWidth: "200px",
        "@media (min-width: 1440px)": {
          maxWidth: "600px",
        },
        "@media (min-width: 1199px)": {
          maxWidth: "300px",
        },
      };
    case detailsAndDrawerLayoutState.DetailsOnly:
      return {
        maxWidth: "150px",
        "@media (min-width: 1440px)": {
          maxWidth: "700px",
        },
        "@media (min-width: 1199px)": {
          maxWidth: "400px",
        },
      };
    case detailsAndDrawerLayoutState.DrawerOnly:
      return {
        maxWidth: "400px",
        "@media (min-width: 1440px)": {
          maxWidth: "800px",
        },
        "@media (min-width: 1199px)": {
          maxWidth: "500px",
        },
      };
    case detailsAndDrawerLayoutState.BothClosed:
      return {
        maxWidth: "400px",
        "@media (min-width: 1440px)": {
          maxWidth: "100%",
        },
        "@media (min-width: 1199px)": {
          maxWidth: "700px",
        },
      };

    default:
      return {
        maxWidth: "200px",
      };
  }
};

export const useStyles = (
  layoutState?: detailsAndDrawerLayoutState,
  toMd?: boolean
) =>
  ({
    tableToolbar: {
      display: "flex",
      alignItems: "center",
    },
    tableViewGridCSS: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",

      "@media(max-width: 839px)": {
        justifyContent: "flex-start",
        alignItems: "flex-start",
      },

      "@media(max-width: 599px)": {
        flexDirection: "column",
        alignItems: "flex-start",
      },
    },
    tableFooter: {
      display: "flex",
      alignItems: "center",
      position: "absolute",
      bottom: "0",
      width: "100%",
    },
    tableCheckboxCell: {
      position: "sticky",
      zIndex: 3,
      left: "0",
    },
    tableCheckboxDiv: {
      height: "24px",
    },
    desktopTableHeadActive: {
      position: "sticky",
      top: "0",
      left: "0",
      width: "100%",
      boxSizing: "border-box",
      marginTop: "-42px",
      zIndex: 5,
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
      rowGap: "8px",
      borderRadius: "unset",
      padding: "4px 24px 4px 16px",
    },
    mobileTableHead: {
      position: "sticky",
      top: "0",
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
      rowGap: "8px",
      zIndex: 10,
      padding: "4px 24px",
    },
    mobileTableItemBox: {
      transition: "opacity 300ms ease-out",

      "& > div + div": {
        marginTop: "8px",
      },
    },
    activeMobileTableItemCheckbox: {
      padding: "16px 8px 16px 16px",
    },
    mobileTableItem: {
      flex: "1",
      overflow: "hidden",

      "& > div": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        height: "56px",
        boxSizing: "border-box",
        padding: "0 24px",
        "&:last-of-type": {
          borderBottom: "none",
        },
      },
    },
    mobileTableColumn: {
      "& > div": {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
    },
    mobileTableColumnValue: {
      fontSize: "14px",
      fontWeight: 600,
      lineHeight: "20px",
      letterSpacing: "0.1px",
    },
    emptyTable: {
      top: "0",
      bottom: "0",
      left: "0",
      right: "0",
      overflow: "unset",
    },
    emptyTableInnerDiv: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      flex: "1",
      padding: "32px 24px",
      overflowY: "auto",

      "& > div:nth-of-type(1)": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: "16px",
      },
    },
    emptyTableTitle: {
      marginBottom: "8px",
    },
    emptyTableDescription: {
      textAlign: "center",
      marginBottom: "12px",
      fontWeight: 400,
      whiteSpace: "pre-line",
    },
    tableCircularProgressDiv: {
      display: "flex",
      justifyContent: "center",
      position: "absolute",
      top: "150px",
      width: "100%",
      zIndex: 11,
    },
    noResultsIcon: {
      fontSize: "120px",
    },
    tableInlineActionsBlock: {
      display: "flex",
      justifyContent: "end",

      "& button + button": {
        marginLeft: "12px",
      },
      "& button:first-of-type": {
        marginLeft: "18px",
      },
      "& button:last-of-type": {
        marginRight: "18px",
      },
    },
    firstTableInlineAction: {
      position: "absolute",
      top: "0",
      bottom: "0",
      left: "0",
      width: "1px",
    },
    mobilTableInlineActionsBlock: {
      flexDirection: "column",
      justifyContent: "center",
    },
    companyTitleDivInDesktopCell:
      layoutState && generateBreakOfDesktopScreen(layoutState),
    driverLastActivityDiv: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
    },
    driverLastActivityIconDiv: {
      position: "relative",
      display: "flex",
      alignItems: "center",
    },
  } as CSSObject);
