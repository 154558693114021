/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { DownloadHistoryDataDto, DownloadHistoryObjectType } from "../types";
import DownloadHistorySingleItem from "../DownloadHistorySingleItem/DownloadHistorySingleItem";

import SingleDetailsAcordionItem from "../../AccordionDetailsCard/components/SingleDetailsAcordionItem";

interface DownloadHistorySingleDayProps {
  historyObject: DownloadHistoryDataDto;
  itemsCount: number;
  index: number;
}

const DownloadHistorySingleDay = ({
  historyObject,
  itemsCount,
  index,
}: DownloadHistorySingleDayProps) => {
  const showSingleHistoryItem = (historyArray: DownloadHistoryObjectType[]) =>
    historyArray?.map((history: DownloadHistoryObjectType) => (
      <DownloadHistorySingleItem key={history.date} history={history} />
    ));

  // Date Fromat is handled in useDownloadHistory while creating history data Object
  // and there is no need to format it again here. Only need to remove time part
  const dateWithoutTime = historyObject.date.slice(0, 10);

  return (
    <SingleDetailsAcordionItem
      key={historyObject.date}
      totalCount={itemsCount}
      title={dateWithoutTime}
      index={index}
      collapsable
      expandFirstItem
      showFirstTopSeparator={index !== 0}
      addMaxHeight={false}
    >
      {showSingleHistoryItem(historyObject.history)}
      {/* empty space at the bottom of the accordion item */}
      <div css={css({ height: "16px" })}></div>
    </SingleDetailsAcordionItem>
  );
};

export default DownloadHistorySingleDay;
