export enum DownloadStatusesTypes {
  VehicleDeactivated = "VehicleDeactivated",
  WaitingForDeviceConnection = "WaitingForDeviceConnection",
  CheckingCredits = "CheckingCredits",
  InsufficientCredits = "InsufficientCredits",
  CreditReserved = "CreditReserved",
  ReceivingStatusState = "ReceivingStatusState",
  ReceivingDriverInfo = "ReceivingDriverInfo",
  ReceivingVehicleSchedules = "ReceivingVehicleSchedules",
  ReceivingDriversSchedules = "ReceivingDriversSchedules",
  CompanyCardInUse = "CompanyCardInUse",
  Authenticating = "Authenticating",
  ReceivingDownloadList = "ReceivingDownloadList",
  DownloadingVehicleData = "DownloadingVehicleData",
  DownloadingDriverData = "DownloadingDriverData",
  TerminatingConnectionToDevice = "TerminatingConnectionToDevice",
  UploadingVehicleFiles = "UploadingVehicleFiles",
  UploadingDriverFiles = "UploadingDriverFiles",
  UploadingVehicleFilesFailed = "UploadingVehicleFilesFailed",
  UploadingDriverFilesFailed = "UploadingDriverFilesFailed",
  StageComplete = "StageComplete",
  ProcessTerminated = "ProcessTerminated",
  NoSchedulesApplied = "NoSchedulesApplied",
}

export enum DownloadStagesTypes {
  DownloadTrigger = "DownloadTrigger",
  Initialization = "Initialization",
  Authentication = "Authentication",
  Downloading = "Downloading",
}
