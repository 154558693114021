import { TFunction } from "i18next";

import { ROUTES, TABLE_NAMES } from "../../shared";

export const specificGlobalSchedule = (pathname: string) => {
  switch (pathname) {
    case ROUTES.Vehicles:
      return TABLE_NAMES.Vehicles;

    case ROUTES.Drivers:
      return TABLE_NAMES.Drivers;

    default:
      return null;
  }
};

export const toggleSwitchTitle = (
  isInRootCompany: boolean | undefined,
  t: TFunction<"translation", undefined>
) =>
  isInRootCompany
    ? t("Dialog##toggle global schedule linking")
    : t("Dialog##toggle children global schedule linking");

export const toggleSwitchTooltip = (
  isInRootCompany: boolean | undefined,
  t: TFunction<"translation", undefined>
) =>
  isInRootCompany
    ? t("Dialog##toggle global schedule linking tooltip")
    : t("Dialog##toggle children global schedule linking tooltip");

export const disableFieldsForChildren = (
  isInRootCompany: boolean | undefined,
  isAttachedToRoot: boolean | undefined
) => !isInRootCompany && isAttachedToRoot;

export const disableField = (
  isLoading: boolean,
  isInRootCompany: boolean | undefined,
  isAttachedToRoot: boolean | undefined
) => isLoading || disableFieldsForChildren(isInRootCompany, isAttachedToRoot);
