import { USER_ROLES_RAW_DATA } from "./../../roles/types/enums";

//Roles featured defined purely in FE
export const rolesFeatures = {
  showToggle2FA: [
    USER_ROLES_RAW_DATA.Administrator,
    USER_ROLES_RAW_DATA.User,
    USER_ROLES_RAW_DATA.TeltonikaSupport,
  ],
  showTutorials: [
    USER_ROLES_RAW_DATA.TeltonikaSupport,
    USER_ROLES_RAW_DATA.Administrator,
  ],
  showCompanySelector: [
    USER_ROLES_RAW_DATA.TeltonikaSupport,
    USER_ROLES_RAW_DATA.Administrator,
  ],
  showFindTool: [USER_ROLES_RAW_DATA.TeltonikaSupport],
  allowedToCreateNewClientCompany: [USER_ROLES_RAW_DATA.TeltonikaSupport],
  allowedToChangeRootCompany: [USER_ROLES_RAW_DATA.TeltonikaSupport],
  allowedToInviteAllRoles: [USER_ROLES_RAW_DATA.TeltonikaSupport],
  allowedGlobalScheduleLinking: [USER_ROLES_RAW_DATA.Administrator],
  ccaFileDownload: [
    USER_ROLES_RAW_DATA.TeltonikaSupport,
    USER_ROLES_RAW_DATA.Administrator,
  ],
};

