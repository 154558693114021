import { COLORS } from "../../shared";

export const inputPropsStyleOverrides = {
  color: COLORS.TextDark,
  fontSize: "14px",
  fontWeight: "600",
  lineHeight: "20px",
  letterSpacing: "0.1px",
  borderRadius: "6px",

  "& input": {
    transitionDelay: "9999s",
    transitionProperty: "background-color",
  },

  "&:hover": {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: COLORS.Gray400,
    },

    "&.Mui-error .MuiOutlinedInput-notchedOutline": {
      borderColor: COLORS.Red800,
    },

    "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
      borderColor: COLORS.Gray300,
    },
  },

  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: COLORS.Gray300,
  },

  "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
    borderColor: COLORS.Gray300,
  },
};

export const textFieldStyleOverrides = {
  "& .MuiFormHelperText-root": {
    margin: "6px 0 0",
    display: "inline-flex",
    alignItems: "center",
    color: COLORS.Gray700,
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: "600",
    letterSpacing: "0.1px",

    "&.Mui-error": {
      color: COLORS.Gray700,
    },
  },
};
