import { Box } from "@mui/system";
import { CSSObject } from "@emotion/react";
import { useTranslation } from "react-i18next";

import { useStyles } from "../../styles";
import { CUSTOM_DATE_PICKER_ACTION_BUTTON } from "../../types";

import Button from "../../../Button/Button";
import { ButtonColors } from "../../../Button/types";

interface DateActionButtonsProps {
  onClearButtonclick: () => void;
  value: moment.Moment | null;
  onApplyButtonclick: () => void;
  hasUserInteraction: boolean;
  createSchedule?: boolean;
}

const DateActionButtons = ({
  onClearButtonclick,
  onApplyButtonclick,
  value,
  hasUserInteraction,
  createSchedule,
}: DateActionButtonsProps) => {
  const { t } = useTranslation();

  const { customDatePickerActionButtons } = useStyles();

  const actionButtons = [
    {
      text: createSchedule ? t("Button##clear") : t("Button##reset"),
      color: "secondary",
      onAction: onClearButtonclick,
    },
    {
      text: t("Button##apply"),
      color: "primary",
      onAction: onApplyButtonclick,
    },
  ];

  return (
    <Box sx={customDatePickerActionButtons as CSSObject}>
      {actionButtons.map((button: CUSTOM_DATE_PICKER_ACTION_BUTTON) => (
        <Button
          data-testid={`${button.text}-button`}
          key={button.text}
          variant="textOnly"
          color={button.color as ButtonColors}
          size="small"
          onClick={button.onAction}
          disabled={!value || !hasUserInteraction}
        >
          {button.text}
        </Button>
      ))}
    </Box>
  );
};

export default DateActionButtons;
