/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useCallback, useEffect, useState } from "react";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import { useRecoilState, useRecoilValue } from "recoil";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useStyles } from "./styles";
import { MIN_CHAR_LENGTH_IN_SEARCH } from "./constants";
import InputField from "../InputField/InputField";
import Tooltip from "../Tooltip/Tooltip";
import { ClearInputIcon } from "../InputField/components/ClearInputIcon";

import {
  currentTableNameDueToRouteName,
  getPageGlobalStateObjectValue,
  pageGlobalStateObjectResult,
} from "../Table/functions";
import {
  currPage,
  findToolSearch,
  noResultClear,
  refreshTableState,
  searchQueryParams,
  tableDetailsResource,
  tableName,
  takeMeThereState,
} from "../../atoms/atoms";
import RectangleLoader from "../../shared/components/loaders/RectangleLoader";
import {
  filterDropdownCustomWidth,
  getSearchQueryParamsFromLocalStorage,
  INPUT_LENGTH,
  ROUTES,
  useDebounce,
} from "../../shared";
import useRefreshTableData from "../../shared/hooks/useRefreshTableData";
import { SearchQueryParamsStateType } from "../../atoms/types";
import useInitialLoading from "../../shared/hooks/useInitialLoading";
import { removeSlashFromPath } from "../../shared/functions/functions";

const Search = () => {
  const { refreshTableData } = useRefreshTableData();
  const location = useLocation();
  const { t } = useTranslation();
  const { isInitialLoading } = useInitialLoading();

  const currentTableName = useRecoilValue(tableName);
  const isTakeMeThere = useRecoilValue(takeMeThereState);

  const [pageToShow, setPageToShow] = useRecoilState(currPage);
  const [searchParams, setSearchParams] = useRecoilState(searchQueryParams);
  const [, setIsRefreshTable] = useRecoilState(refreshTableState);
  const [noResultClearCall, setNoResultClearCall] = useRecoilState(
    noResultClear
  );
  const [isFindToolSearch, setIsFindToolSearch] = useRecoilState(
    findToolSearch
  );
  const resource = useRecoilValue(tableDetailsResource);

  const [isAllowedToCallApi, setIsAllowedToCallApi] = useState<boolean>(false);

  const searchQuery =
    getSearchQueryParamsFromLocalStorage(location.pathname)?.length >=
    MIN_CHAR_LENGTH_IN_SEARCH
      ? getSearchQueryParamsFromLocalStorage(location.pathname)
      : null;

  const [value, setValue] = useState<string>(searchQuery || "");

  const debouncedValue = useDebounce(value, 500);
  const { inputField, searchInputSpan } = useStyles();

  const filesPage =
    location.pathname === ROUTES.VehicleFiles ||
    location.pathname === ROUTES.DriverFiles;

  const searchByValue = filesPage
    ? removeSlashFromPath(location.pathname)
    : currentTableName;

  // check if page has changed
  const hasPageChanged =
    (resource && resource !== location.pathname) ||
    currentTableNameDueToRouteName(currentTableName, location) !==
      location.pathname.slice(1);

  const queryNoMoreExist =
    getSearchQueryParamsFromLocalStorage(location.pathname) === null &&
    value.length >= 3;

  // the followinfg useEffect is used to display the search value when using "find tool" feature through the navbar,
  // and user's current location does not require to route to different page.
  useEffect(() => {
    if (isFindToolSearch && searchQuery) {
      setValue(searchQuery);
      setIsFindToolSearch(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery, isFindToolSearch]);

  const runSearch = useCallback(() => {
    if (
      !isInitialLoading &&
      pageGlobalStateObjectResult(location.pathname, pageToShow)
    ) {
      setPageToShow((prev: any) =>
        getPageGlobalStateObjectValue(location.pathname, prev, "1")
      );

      const refreshPageData = async () => {
        setIsRefreshTable(true); // prevents from calling annecessary getTableData() from useTableData.tsx
        await refreshTableData(false, debouncedValue)?.then(() => {
          setIsRefreshTable(false);
        });
      };

      // Do not call refreshTableData() when 'reset view' button is clicked in "NoResultsFound" component
      if (!noResultClearCall && !isFindToolSearch && !isTakeMeThere) {
        refreshPageData();
      }

      // reset noResultClearCall to false so that refreshTableData() would be called when needed
      setNoResultClearCall(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    debouncedValue,
    isInitialLoading,
    location,
    pageToShow,
    refreshTableData,
    setPageToShow,
    noResultClearCall,
  ]);

  useEffect(() => {
    // Clear search value on reset view button click from NoResultsFound component
    if (queryNoMoreExist && noResultClearCall) {
      setValue("");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery, searchParams, noResultClearCall]);

  useEffect(() => {
    const isLenghtValid = debouncedValue && validateValueLength(debouncedValue);
    if (isLenghtValid) {
      setIsAllowedToCallApi(true);
    }

    if (isLenghtValid || isAllowedToCallApi) {
      if (!Boolean(hasPageChanged)) {
        runSearch();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  const validateValueLength = (value: string) => {
    return (
      value.length >= MIN_CHAR_LENGTH_IN_SEARCH &&
      value.length < INPUT_LENGTH.MaxValueInSearchField
    );
  };

  const changeValue = (e: { target: { value: string } }) => {
    const newValue = e.target.value.trimStart();
    const isValueLengthValid = validateValueLength(newValue);
    const isNotTooLong = newValue.length <= INPUT_LENGTH.MaxValueInSearchField;

    if (isValueLengthValid || e.target.value.length === 0) {
      setIsAllowedToCallApi(true);
    }
    if (e.target.value.length > 0 && e.target.value.length < 2) {
      setIsAllowedToCallApi(false);
    }
    setSearchParams((prev: SearchQueryParamsStateType) =>
      getPageGlobalStateObjectValue(location.pathname, prev, e.target.value)
    );

    isNotTooLong && setValue(newValue);
  };

  const clearValue = async () => {
    setValue("");
    setIsRefreshTable(true);
    setSearchParams((prev: SearchQueryParamsStateType) =>
      getPageGlobalStateObjectValue(location.pathname, prev, null)
    );
    setIsAllowedToCallApi(false);
    await refreshTableData(false);
    setIsRefreshTable(false);
  };

  return (
    <>
      {isInitialLoading && (
        <RectangleLoader
          customStyle={filterDropdownCustomWidth}
          height={36}
          testId="search-loader"
        />
      )}
      {!isInitialLoading && (
        <Tooltip
          title={t(`Search##search in ${searchByValue}`)}
          size="medium"
          placement="right"
          disableInteractive
        >
          <span css={css(searchInputSpan)}>
            <InputField
              fullWidth
              size="small"
              value={value}
              onChange={changeValue}
              iconLeft={<SearchRoundedIcon />}
              iconRight={
                <ClearInputIcon value={value} clearValue={clearValue} />
              }
              placeholder={t("Search##search")}
              css={css(inputField)}
            />
          </span>
        </Tooltip>
      )}
    </>
  );
};

export default Search;
