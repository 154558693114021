import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";

import { EditDriverPayloadType } from "../../../../types";
import InputField from "../../../../../InputField/InputField";
import useDriversFormInputs from "../../../../../../shared/hooks/useDriversFormInputs";
import { useAuth } from "../../../../../../context/Auth/AuthProvider";
import { DriverDataDto } from "../../../../../../Pages/Drivers/interfaces";
import { inputFieldMarginBottom } from "../../../../../../shared";
import {
  cardHolderNameIsValid,
  descriptionIsValid,
  isDriverPayloadReadyToConfirm,
} from "../../../../../../shared/functions/functions";
import useDriverIdInputValidation from "../../../../../../shared/hooks/useDriverIdInputValidation";

interface IEditDriverDialogContentProps {
  rowData: DriverDataDto;
  setPayload: Dispatch<SetStateAction<EditDriverPayloadType>>;
  setIsReadyToConfirm: Dispatch<SetStateAction<boolean | "" | null>>;
  isLoading: boolean;
}

const EditDriverDialogContent = ({
  rowData,
  setPayload,
  setIsReadyToConfirm,
  isLoading,
}: IEditDriverDialogContentProps) => {
  const { user } = useAuth();
  const { t } = useTranslation();

  const [cardHolderName, setCardHolderName] = useState<string>(
    rowData.cardName
  );
  const [cardHolderInputError, setCardHolderInputError] = useState<
    string | null
  >(null);
  const [driverId, setDriverId] = useState<string | null>(rowData.cardNumber);
  const [descriptionValue, setDescriptionValue] = useState<string | null>(
    rowData.comment
  );
  const [descriptionInputError, setDescriptionInputError] = useState<
    string | null
  >(null);

  const {
    inputIsValid,
    inputError,
    clearInputError,
    validateInput,
  } = useDriverIdInputValidation(driverId);

  const {
    validateCardHolderNameLength,
    validateDescriptionLength,
    handleCardHolderNameChange,
    handleChangeDriverIdValue,
    handleChangeDescription,
  } = useDriversFormInputs({
    setCardHolderInputError,
    setDescriptionInputError,
    setCardHolderName,
    setDriverId,
    validateInput,
    clearInputError,
    setDescriptionValue,
  });

  const isCardHolderNameValid = cardHolderNameIsValid(cardHolderName);
  const isDescriptionValid = descriptionIsValid(descriptionValue);

  const isPayloadReadyToConfirm = isDriverPayloadReadyToConfirm(
    cardHolderName,
    isCardHolderNameValid,
    driverId,
    inputIsValid,
    isDescriptionValid
  );

  useEffect(() => {
    if (rowData && rowData.id) {
      setPayload({
        id: rowData.id,
        cardNumber: driverId,
        cardName: cardHolderName,
        comment: descriptionValue,
      });

      setIsReadyToConfirm(isPayloadReadyToConfirm);
    }
  }, [
    cardHolderName,
    isCardHolderNameValid,
    isDescriptionValid,
    descriptionValue,
    driverId,
    isPayloadReadyToConfirm,
    rowData,
    setIsReadyToConfirm,
    setPayload,
  ]);

  return (
    <>
      <InputField
        id={uuidv4()}
        size="medium"
        data-testid="edit-driver-company-name"
        fullWidth
        readOnly
        labelLeft={t("Dialog##company")}
        placeholder={user?.selectedCompany.name}
        customStyle={inputFieldMarginBottom}
      />
      <InputField
        id={uuidv4()}
        testId="card-holder-name-input-field"
        size="medium"
        fullWidth
        required
        value={cardHolderName}
        errorText={isCardHolderNameValid ? "" : cardHolderInputError}
        onBlur={validateCardHolderNameLength}
        onChange={handleCardHolderNameChange}
        labelLeft={t("Dialog##card holder name")}
        placeholder={t("Dialog##enter here")}
        customStyle={inputFieldMarginBottom}
        disabled={isLoading}
      />
      <InputField
        id={uuidv4()}
        data-testid="driver-id-input-field"
        size="medium"
        fullWidth
        required
        value={driverId}
        errorText={inputError}
        onBlur={validateInput}
        onChange={handleChangeDriverIdValue}
        labelLeft={t("Dialog##driver id")}
        placeholder={t("Dialog##driver id placeholder")}
        customStyle={inputFieldMarginBottom}
        disabled={isLoading}
      />
      <InputField
        id={uuidv4()}
        data-testid="driver-description-input-field"
        size="medium"
        fullWidth
        value={descriptionValue}
        errorText={isDescriptionValid ? "" : descriptionInputError}
        onBlur={validateDescriptionLength}
        onChange={handleChangeDescription}
        labelLeft={t("Dialog##description")}
        placeholder={t("Dialog##enter here")}
        customStyle={inputFieldMarginBottom}
        disabled={isLoading}
      />
    </>
  );
};

export default EditDriverDialogContent;
