/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext } from "react";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import { Moment } from "moment-timezone";

import {
  REPEAT_TYPE_VALUES,
  SCHEDULE_PAYLOAD_NAMES,
  ScheduleRepeatPeriod,
} from "../types";
import { useStyles } from "../styles";

import Checkbox from "../../CheckBox/Checkbox";
import Tooltip from "../../Tooltip/Tooltip";

import { CombinedDataTypes, VehicleDataTypes } from "../../../shared";
import { ThemeContext } from "../../../context/theme/ThemeContextProvider";
import InfoIcon from "../../../assets/customIcons/status/InfoIcon";

interface DownloadDataTypesCheckboxesProps {
  selectedDataTypes: CombinedDataTypes[];
  handleChange: (name: SCHEDULE_PAYLOAD_NAMES, value: VehicleDataTypes) => void;
  startDate?: string | Moment;
  repeatValue?: ScheduleRepeatPeriod | null;
  isLoading?: boolean;
}

const DownloadDataTypesCheckboxes = ({
  selectedDataTypes,
  handleChange,
  startDate,
  repeatValue,
  isLoading
}: DownloadDataTypesCheckboxesProps) => {
  const { colors } = useContext(ThemeContext);

  const { t } = useTranslation();

  const { downloadDataTypesInfo, downloadDataTypesTitleIconCSS } = useStyles();

  const getSubtitleText = (value: any) => {
    const repeatDays = Number(repeatValue?.days);

    const getRepeatType = () => {
      switch (repeatDays) {
        case -1:
          return REPEAT_TYPE_VALUES.Hourly;
        case 1:
          return REPEAT_TYPE_VALUES.Single;
        default:
          return REPEAT_TYPE_VALUES.Multiple;
      }
    };

    return value === VehicleDataTypes.Activities &&
      Boolean(startDate) &&
      !isNaN(repeatDays) &&
      Boolean(repeatDays)
      ? t(
          `Schedules##vehicle schedules##download data activities tooltip ${getRepeatType()}`,
          { repeatDays: repeatValue?.days }
        )
      : undefined;
  };

  return (
    <div data-testid="download-data-types-checkboxes">
      <div css={css(downloadDataTypesTitleIconCSS)}>
        <Typography variant="body2" sx={{ color: colors.textSecondary }}>
          {t("Dialog##download data types")}
        </Typography>

        <Tooltip
          title={t(`Schedules##download activities##tooltip text`)}
          size="medium"
          placement="top"
          hasArrow
        >
          <span>
            <InfoIcon
              data-testid="schedules-info-icon"
              css={css(downloadDataTypesInfo)}
            />
          </span>
        </Tooltip>
      </div>
      {Object.values(VehicleDataTypes).map((value) => (
        <div key={`schedule_${value}`}>
          <Checkbox
            id={`schedule_${value}`}
            checked={selectedDataTypes.includes(value)}
            disabled={value === VehicleDataTypes.Overview || isLoading}
            onChange={() =>
              handleChange(SCHEDULE_PAYLOAD_NAMES.FileTypes, value)
            }
            label={t(`Schedules##file types##${value}`)}
            info={getSubtitleText(value)}
            // Add margin bottom to the last checkbox so that it does not jump when changing date in date picker
            customStyle={{
              marginBottom:
                value === VehicleDataTypes.Activities && !getSubtitleText(value)
                  ? "12px"
                  : "0",
            }}
          />
        </div>
      ))}
    </div>
  );
};

export default DownloadDataTypesCheckboxes;
