/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useTranslation } from "react-i18next";

import DetailsCard from "../DetailsCard";
import DetailsCardItemText from "../DetailsCardItemText";
import DetailsCardItemTitle from "../DetailsCardItemTitle";

import { useStyles } from "../../../../styles";

import SharedStatusBadge from "../../../../../SharedStatusBadge/SharedStatusBadge";

import {
  ACTIVITY_STATUS,
  IGeneralDetailsProps,
} from "../../../../../../shared";

const VehicleGeneralDetails = ({ detailsData }: IGeneralDetailsProps) => {
  const { t } = useTranslation();

  const { paperCardItem, paperCardItemFixedHeight } = useStyles();

  return (
    <DetailsCard cardTitle={t("Details##general")} detailsData={detailsData}>
      <div css={css(paperCardItem)}>
        <DetailsCardItemTitle title={t("Details##company")} />
        <DetailsCardItemText
          textToRender={detailsData?.company.name as string}
        />
      </div>

      <div css={css([paperCardItem, paperCardItemFixedHeight])}>
        <DetailsCardItemTitle title={t("Details##imei")} />
        <DetailsCardItemText
          textToRender={detailsData?.imei as string}
          hasCopyButton
          copiedValue={detailsData?.imei as string}
        />
      </div>

      <div css={css([paperCardItem, paperCardItemFixedHeight])}>
        <DetailsCardItemTitle title={t("Details##device status")} />
        <SharedStatusBadge status={detailsData?.status as ACTIVITY_STATUS} />
      </div>
    </DetailsCard>
  );
};

export default VehicleGeneralDetails;
