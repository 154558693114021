/** @jsxImportSource @emotion/react */
import { css, CSSObject } from "@emotion/react";
import { useContext } from "react";

import { useStyles } from "./styles";
import useCircleStepperFunctions from "./hooks/useCircleStepperFunctions";
import { CircleStepperStatusTypes } from "./types";

import {
  DownloadLiveStatusDataType,
  DownloadStatusesTypes,
} from "../DetailsSection/components/DetailsContent/components/DownloadMonitoringDialog/types";

import { ThemeContext } from "../../context/theme/ThemeContextProvider";
import CircleStepperSuccessIcon from "../../assets/customIcons/steppers/CircleStepperSuccessIcon";
import CircleStepperCurrentIcon from "../../assets/customIcons/steppers/CircleStepperCurrentIcon";
import { useMediaQueries } from "../../shared";
import CircleStepperErrorIcon from "../../assets/customIcons/steppers/CircleStepperErrorIcon";

interface CircleStepperProps {
  status: CircleStepperStatusTypes;
  liveStatusData?: DownloadLiveStatusDataType;
  showRightHorizontalLine?: boolean;
  stepTitle?: string;
  showLeftHorizontalLine?: boolean;
  lastElement?: boolean;
  pulseEffect?: boolean;
}

const CircleStepper = ({
  showRightHorizontalLine = true,
  liveStatusData,
  stepTitle,
  showLeftHorizontalLine,
  status,
  lastElement,
  pulseEffect,
}: CircleStepperProps) => {
  const { colors } = useContext(ThemeContext);
  const { fromMd } = useMediaQueries();

  const {
    generateViewFromMd,
    generateViewToMd,
    generateCurrentStepCircle,
  } = useCircleStepperFunctions({
    showLeftHorizontalLine,
    status,
    showRightHorizontalLine,
    lastElement,
    stepTitle,
  });

  const { circleStepIconStyle, circleStepperContainer } = useStyles({
    colors,
    showLeftHorizontalLine,
    fromMd,
  });

  const isTerminated =
    liveStatusData?.Status === DownloadStatusesTypes.ProcessTerminated;

  const errorStep = isTerminated && (
    <CircleStepperErrorIcon
      fillColour={colors.red700}
      sx={circleStepIconStyle as CSSObject}
    />
  );

  const generateCurrentStep = () => {
    // pulseEffect implies that stage is not finished
    if (pulseEffect) {
      return generateCurrentStepCircle();
    }

    return (
      errorStep || (
        <CircleStepperSuccessIcon
          fillColour={colors.blue700}
          sx={circleStepIconStyle as CSSObject}
        />
      )
    );
  };

  const generateCircleStepperIcon = (status: CircleStepperStatusTypes) => {
    switch (status) {
      case CircleStepperStatusTypes.success:
        return (
          <CircleStepperSuccessIcon
            fillColour={colors.blue700}
            sx={circleStepIconStyle as CSSObject}
          />
        );
      case CircleStepperStatusTypes.current:
        return generateCurrentStep();
      case CircleStepperStatusTypes.notCompleted:
        return (
          <CircleStepperCurrentIcon
            strokeColor={colors.textDarkDisabled}
            sx={circleStepIconStyle as CSSObject}
          />
        );

      default:
        return null;
    }
  };

  return (
    <div css={css(circleStepperContainer)}>
      {fromMd
        ? generateViewFromMd(generateCircleStepperIcon, liveStatusData)
        : generateViewToMd(generateCircleStepperIcon, liveStatusData)}
    </div>
  );
};

export default CircleStepper;
