import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";

import { NewsDto, NewsPayloadDto, selectableImageType } from "../..";
import { imageList } from "../../config";
import ImageDropzone from "../../components/ImageDropzone/ImageDropzone";

import InputField from "../../../../../../components/InputField/InputField";
import useNewsFormInputs from "../../../../../../shared/hooks/useNewsFormInputs";
import { inputFieldMarginBottom } from "../../../../../../shared";
import RectangleLoader from "../../../../../../shared/components/loaders/RectangleLoader";
import RichTextField from "../../../../../../components/RichTextField/RichTextField";

interface EditNewsDialogContentProps {
  newsData: NewsDto | null;
  setIsPayloadReady: Dispatch<SetStateAction<boolean>>;
  setPayload: Dispatch<SetStateAction<NewsPayloadDto>>;
  isLoading: boolean;
  isSubmitLoading: boolean;
}

const EditNewsDialogContent = ({
  newsData,
  setIsPayloadReady,
  setPayload,
  isLoading,
  isSubmitLoading,
}: EditNewsDialogContentProps) => {
  const { t } = useTranslation();

  const filteredSelectedImageFromTheList: selectableImageType = imageList.filter(
    (image: selectableImageType) => image.imageName === newsData?.imageName
  )[0];

  const [
    selectedImage,
    setSelectedImage,
  ] = useState<selectableImageType | null>(filteredSelectedImageFromTheList);
  const [isInteracted, setIsInteracted] = useState<boolean>(false);

  const {
    titleInputError,
    validateTitleLength,
    handleChangeTitleValue,
    title,
    setTitle,
    validateRichTextLength,
    isRichTextValid,
    handleChangeRichTextValue,
    richTextInputError,
    richTextValue,
    setRichTextValue,
    isRichTextFocused,
    handleFocus,
  } = useNewsFormInputs({
    setSelectedImage,
    titleValue: newsData?.title || "",
    descriptionValue: newsData?.content || "",
    setIsInteracted,
  });

  const isPayloadReadyToConfirm: boolean =
    isInteracted &&
    Boolean(title) &&
    Boolean(!titleInputError) &&
    Boolean(selectedImage) &&
    Boolean(richTextValue) &&
    isRichTextValid &&
    !richTextInputError;

  useEffect(() => {
    setTitle(newsData?.title || "");
    setRichTextValue(newsData?.content || "");
    newsData && setSelectedImage(filteredSelectedImageFromTheList);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setSelectedImage, newsData, setTitle, setRichTextValue]);

  useEffect(() => {
    newsData &&
      setPayload({
        id: newsData.id,
        title: title,
        content: richTextValue,
        imageName: selectedImage?.imageName as string,
      });
    setIsPayloadReady(isPayloadReadyToConfirm);
  }, [
    title,
    richTextValue,
    titleInputError,
    selectedImage,
    setIsPayloadReady,
    setPayload,
    newsData,
    isPayloadReadyToConfirm,
  ]);

  return (
    <>
      <ImageDropzone
        setSelectedImage={setSelectedImage}
        selectedImage={selectedImage}
        setIsInteracted={setIsInteracted}
        isLoading={isLoading}
        label={t("Dropzone##select image")}
        disabled={isSubmitLoading}
      />
      {isLoading ? (
        <>
          <RectangleLoader
            width={"100%"}
            height={"68px"}
            customStyle={inputFieldMarginBottom}
            testId="edit-news-title-input-field-loader"
          />
          <RectangleLoader
            width={"100%"}
            height={"172px"}
            customStyle={inputFieldMarginBottom}
            testId="edit-news-description-input-field-loader"
          />
        </>
      ) : (
        <>
          <InputField
            id={uuidv4()}
            testId="edit-news-title-input-field"
            size="medium"
            fullWidth
            required
            errorText={titleInputError}
            onBlur={validateTitleLength}
            onChange={handleChangeTitleValue}
            labelLeft={t("Dialog##title")}
            placeholder={t("Dialog##enter here")}
            customStyle={inputFieldMarginBottom}
            value={title}
            disabled={isSubmitLoading}
          />
          <RichTextField
            testId="edit-news-description-input-field"
            label={`${t("Dialog##description required")}*`}
            isRichTextFocused={isRichTextFocused}
            richTextInputError={richTextInputError}
            richTextValue={richTextValue}
            handleChangeRichTextValue={handleChangeRichTextValue}
            validateRichTextLength={validateRichTextLength}
            handleFocus={handleFocus}
            isLoading={isSubmitLoading}
            edit
          />
        </>
      )}
    </>
  );
};

export default EditNewsDialogContent;
