import { AxiosResponse } from "axios";
import { useLocation } from "react-router";
import { useRecoilState, useRecoilValue } from "recoil";

import useResponse from "./useResponse";
import { ROUTES, TableRowData } from "..";
import useAxiosPrivate from "../../api/hooks/useAxiosPrivate";
import {
  currPage,
  filterQueryParams,
  noSearchResults,
  tableData,
  tableDetails,
  tableLoading,
  tablePageData,
  tableRowsPerPage,
  tableSortedColumn,
} from "../../atoms/atoms";
import { ALERT_STATUS } from "../../context/alert/types";
import { MIN_CHAR_LENGTH_IN_SEARCH } from "../../components/Search/constants";
import {
  getPageGlobalStateObjectValue,
  pageGlobalStateObjectResult,
} from "../../components/Table/functions";
import { TableDetailsDataStateType } from "../../atoms/types";
import { mapUserTableRowData } from "../../Pages/Users/Users";
import { mapVehicleFilesTableRowData } from "../../Pages/Files/VehicleFiles/VehicleFiles";
import useTableDataFunctions from "../../components/Table/hooks/useTableDataFunctions";
import { ApiResources } from "../../api/resources";
import { mapCompaniesTableRowData } from "../../Pages/Companies/functions";
import { mapUsersInvitationsTableRowData } from "../../Pages/Users/UsersInvitations/UsersInvitations";
import { mapVehiclesTableRowData } from "../../Pages/Vehicles/Vehicles";
import { mapDriversTableRowData } from "../../Pages/Drivers/Drivers";
import { mapDriversFilesTableRowData } from "../../Pages/Files/DriversFiles/DriversFiles";

const useRefreshTableData = () => {
  const { getData } = useAxiosPrivate();
  const location = useLocation();
  const {
    routeWithoutSearch,
    setPageDataState,
    generateRefreshTableDataQueryParams,
  } = useTableDataFunctions();
  const { handleResponse } = useResponse();

  const pageToShow = useRecoilValue(currPage);
  const rowsPerPage = useRecoilValue(tableRowsPerPage);
  const filterParams = useRecoilValue(filterQueryParams);

  const sortedColumn = useRecoilValue(tableSortedColumn);

  const [, setTableData] = useRecoilState<TableRowData[]>(tableData);
  const [, setPageData] = useRecoilState(tablePageData);
  const [, setTableDetails] = useRecoilState(tableDetails);
  const [, setNoResults] = useRecoilState(noSearchResults);
  const [isLoading, setIsLoading] = useRecoilState(tableLoading);

  const companiesRoute = location.pathname === ROUTES.Companies;

  const checkResults = (
    searchParams: string | undefined,
    response: AxiosResponse<any, any>
  ) => {
    const noSearchResultsFound =
      searchParams && response?.data.items.length === 0;

    const noFiltersResultFound =
      pageGlobalStateObjectResult(location.pathname, filterParams) &&
      response?.data.items.length === 0;

    setNoResults(noSearchResultsFound || noFiltersResultFound);
  };

  const rowsCount = pageGlobalStateObjectResult(location.pathname, rowsPerPage);

  const setTableDataState = (pathname: string, data: any) => {
    switch (pathname) {
      case ROUTES.Users:
        return setTableData(mapUserTableRowData(data));
      case ROUTES.UsersInvitations:
        return setTableData(mapUsersInvitationsTableRowData(data) as any);
      case ROUTES.VehicleFiles:
        return setTableData(mapVehicleFilesTableRowData(data));
      case ROUTES.DriverFiles:
        return setTableData(mapDriversFilesTableRowData(data));
      case ROUTES.Companies:
        return setTableData(mapCompaniesTableRowData(data) as any);
      case ROUTES.Vehicles:
        return setTableData(mapVehiclesTableRowData(data));
      case ROUTES.Drivers:
        return setTableData(mapDriversTableRowData(data));

      default:
        return setTableData(data);
    }
  };

  const refreshTableData = async (
    setNewDetails?: any,
    searchParams?: string,
    fromChangeSelectedCompany?: boolean
  ) => {
    const apiQueryParams = generateRefreshTableDataQueryParams({
      location,
      pageToShow,
      rowsCount,
      searchParams,
      MIN_CHAR_LENGTH_IN_SEARCH,
      filterParams,
      sortedColumn,
      fromChangeSelectedCompany,
    });

    if (
      searchParams &&
      pageGlobalStateObjectResult(location.pathname, pageToShow) !== "1"
    ) {
      return;
    } else {
      !isLoading && setIsLoading(true);
      try {
        const response = await getData(
          routeWithoutSearch(companiesRoute, location) || apiQueryParams
        );

        setPageDataState(
          response,
          companiesRoute,
          setPageData,
          mapCompaniesTableRowData
        );

        // make a copy of companies page repsonse object to avoid error: `cannot add property 'X', object is not extensible`
        const companiesPageApiResponse = companiesRoute && {
          ...response?.data,
        };

        let itemsFromResponse =
          companiesPageApiResponse || response?.data.items;

        setTableDataState(location.pathname, itemsFromResponse); // affects rows checkbox state

        setNewDetails &&
          setTableDetails((prev: TableDetailsDataStateType | null) =>
            getPageGlobalStateObjectValue(
              location.pathname,
              prev,
              response.data.items[0]
            )
          );

        checkResults(searchParams, response);
      } catch (error) {
        const err = error as any;
        if (err) {
          const responseMessage = err.response?.data.message;
          const responseEntriesMessage =
            err.response?.data !== "" &&
            err.response?.data?.entries[0]?.message;
          const errorMessage =
            responseEntriesMessage || responseMessage || err.message;
          handleResponse(ALERT_STATUS.Critical, errorMessage);
        }
      } finally {
        setIsLoading(false);
      }
    }
  };

  const refreshCompaniesHierarchy = async () => {
    setIsLoading(true);
    await getData(ApiResources.Companies)
      .then((response: any) => {
        setPageData(response?.data);
        setTableData(response?.data.childCompanies);
      })
      .catch((error) => {
        handleResponse(ALERT_STATUS.Critical, error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return { refreshTableData, refreshCompaniesHierarchy };
};

export default useRefreshTableData;
