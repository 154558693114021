import { PickersDay, PickersDayProps } from "@mui/x-date-pickers";
import { Moment } from "moment-timezone";
import moment from "moment";

import { CUSTOM_DAY_START_OF_PROPERTY_NAMES } from "../../../Schedules/types";

import { ThemeColors } from "../../../../shared";

interface CustomDayProps extends PickersDayProps<Moment> {
  nthDay: number | null | undefined;
  value: any; // otherwise typescript complains about the value prop
  colors: ThemeColors;
  createSchedule?: boolean;
}

const CustomDay = ({
  value,
  nthDay,
  colors,
  createSchedule,
  ...props
}: CustomDayProps & PickersDayProps<any>) => {
  const { day, ...other } = props;

  const startDate = moment(value).startOf("day");
  const today = moment().startOf("day"); // Get today's date

  const isHighlighted =
    startDate !== null &&
    nthDay !== null &&
    nthDay !== undefined &&
    day.isAfter(today, CUSTOM_DAY_START_OF_PROPERTY_NAMES.Day) && // Exclude today and past days
    day.isAfter(startDate, CUSTOM_DAY_START_OF_PROPERTY_NAMES.Day) && // Excludes days before start date
    day.diff(startDate, CUSTOM_DAY_START_OF_PROPERTY_NAMES.Days) % nthDay === 0;

  return (
    <PickersDay
      {...other}
      day={day}
      sx={{
        border: isHighlighted
          ? `2px solid ${colors.blue700} !important` // Apply blue border when highlighted
          : "none",
        borderRadius: "50%", // Make it circular
      }}
    />
  );
};

export default CustomDay;
