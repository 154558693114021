/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import {
  Dispatch,
  Fragment,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

import InputField from "../../../../../../../../InputField/InputField";

import {
  BUTTON_TYPE,
  PASSWORD_TYPE,
  PasswordInputsType,
  TooltipPswProps,
} from "../../../../../../../../../Pages/Auth/types";
import usePasswordValidation from "../../../../../../../../../Pages/Auth/hooks/usePasswordValidation";
import { passwordLengthIsValid } from "../../../../../../../../../shared/functions/functions";
import PasswordValidation from "../../../../../../../../../Pages/Auth/components/PasswordValidation/PasswordValidation";
import { useStyles } from "../../../../../../../../../Pages/Auth/styles";

interface IChangePasswordDialogContentProps {
  inputs: PasswordInputsType[];
  button: BUTTON_TYPE;
  setReady: Dispatch<SetStateAction<boolean>>;
  setPayload: Dispatch<SetStateAction<{}>>;
  resetValues: boolean;
  isLoading: boolean;
}

const ChangePasswordDialogContent = ({
  inputs,
  button,
  setReady,
  setPayload,
  resetValues,
  isLoading
}: IChangePasswordDialogContentProps) => {
  const { validatePasswordLength } = usePasswordValidation();
  const { t } = useTranslation();

  const [passwordError, setPasswordError] = useState<string>("");
  const [password, setPassword] = useState({
    current: "",
    repeat: "",
    newPsw: "",
  });

  const { blockWithBottomSpacing } = useStyles();

  const { current, repeat, newPsw } = password;

  useEffect(() => {
    resetValues &&
      setPassword({
        current: "",
        repeat: "",
        newPsw: "",
      });
  }, [resetValues]);

  useEffect(() => {
    setPayload({
      currentPassword: password.current,
      newPassword: password.newPsw,
      newPasswordConfirm: password.repeat,
    });
  }, [password, setPayload]);

  const toggleDisable = useCallback(
    (value: boolean) => {
      setReady(value);
    },
    [setReady]
  );

  const handleChange = useCallback(
    (e: { target: { name: string; value: string } }) => {
      const inputName = e.target.name;
      const inputValue = e.target.value;

      setPassword({
        ...password,
        [inputName]: inputValue,
      });
    },
    [password]
  );

  const generateNewPasswordError = (inputName: PASSWORD_TYPE) => {
    if (inputName === PASSWORD_TYPE.NewPsw) {
      return passwordLengthIsValid(newPsw) ? "" : passwordError;
    }
    return null;
  };

  const renderInputs = () =>
    inputs.map(
      (input: {
        TooltipPsw: TooltipPswProps;
        name: PASSWORD_TYPE;
        title: string;
      }) => {
        const { name, title } = input;

        const { PasswordVisibility, showPassword } = input.TooltipPsw;

        if (resetValues) {
          showPassword.current = false;
          showPassword.newPsw = false;
          showPassword.repeat = false;
        }

        return (
          <Fragment key={name}>
            <InputField
              testId={name}
              fullWidth
              size="medium"
              type={
                showPassword[name as keyof typeof showPassword]
                  ? "text"
                  : "password"
              }
              labelLeft={title}
              name={name}
              placeholder={t("Auth##enter here")}
              onChange={handleChange}
              disabled={isLoading}
              iconRight={<PasswordVisibility />}
              errorText={generateNewPasswordError(name)}
              onBlur={(event) =>
                validatePasswordLength(event, setPasswordError)
              }
              css={css(blockWithBottomSpacing)}
            />
          </Fragment>
        );
      }
    );

  return (
    <>
      {renderInputs()}

      <div>
        {/* validation rules: */}
        <PasswordValidation
          currentPsw={current}
          newPsw={newPsw}
          repeatPsw={repeat}
          onRulesMet={toggleDisable}
          name={button}
        />
      </div>
    </>
  );
};

export default ChangePasswordDialogContent;
