export enum TRANSLATABLE_FILTER_VALUES {
  Online = "Online",
  Offline = "Offline",
  Inactive = "Inactive",
  Disabled = "Disabled",

  BreakOrRest = "BreakOrRest",
  Available = "Available",
  Work = "Work",
  Drive = "Drive",
  Unknown4 = "Unknown4",
  Unknown5 = "Unknown5",
  NotAvailable = "NotAvailable",
}

export enum FILTER_KEYS {
  Date = "date",
  DateRangeFrom = "from",
  DateRangeTo = "to",
  DriverIds = "driverIds",
  ScheduleIds = "ScheduleIds",
  NoSchedulesAssigned = "NoSchedulesAssigned",
  VehicleIds = "vehicleIds",
}

export enum FILTER_NAMES {
  DownloadsAttempts = "downloadsattempts",
  DriverName = "driverName",
  LastDownloadAttempt = "lastDownloadAttempt",
  Schedules = "schedules",
  Statuses = "statuses",
  VehicleNumber = "vehicleNumber",
  DateRangeFrom = "dateRangeFrom",
  DateRangeTo = "dateRangeTo",
  DateRange = "dateRange",
}
