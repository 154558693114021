import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { FILES_PAGE_TABS, VEHICLES_FILES_TABLE_COLUMNS } from "../config";
import PageWrapperNoDetails from "../../wrappers/PageWrapperNoDetails";
import LayoutWrapper from "../../../components/Layout/LayoutWrapper";
import VehicleFilesHeaderActions from "../../../components/PageHeader/actions/pageHeaderActions/VehicleFilesHeaderActions/VehicleFilesHeaderActions";
import TabFilters from "../../../components/TabFilters/TabFilters";
import useVehicleFilesInlineActions from "../../../components/Table/actions/inlineActions/hooks/useVehicleFilesInlineActions";
import useTableData from "../../../components/Table/hooks/useTableData";
import Table from "../../../components/Table/Table";
import { TABLE_NAMES } from "../../../shared";
import { isTableCheckable } from "../../../atoms/atoms";

export const mapVehicleFilesTableRowData = (data: any) =>
  data.map((dataItem: any) => {
    return {
      id: dataItem.id,
      fileName: dataItem.fileName,
      fileTypes: dataItem.schedule.fileTypes,
      number: dataItem.vehicle.number,
      activitiesFrom: dataItem.schedule.activitiesFrom,
      activitiesTo: dataItem.schedule.activitiesTo,
      downloadTime: dataItem.downloadTime,
      imei: dataItem.vehicle.imei,
    };
  });

const VehicleFiles = () => {
  useTableData(mapVehicleFilesTableRowData);
  const { inlineActions } = useVehicleFilesInlineActions();

  const [, setIsCheckable] = useRecoilState(isTableCheckable);

  useEffect(() => {
    setIsCheckable(true);
    // eslint-disable-next-line
  }, []);

  return (
    <PageWrapperNoDetails
      currTableName={TABLE_NAMES.Files}
      currTableColumns={VEHICLES_FILES_TABLE_COLUMNS}
      inlineActions={inlineActions}
    >
      <LayoutWrapper showDetailsSection={false}>
        <VehicleFilesHeaderActions />
      </LayoutWrapper>
      <Table
        hasDetails={false}
        // the tabs below slows down typing in search field (lagging occurs)
        // should be reviewed in the future how to fix the issue
        tabFilters={<TabFilters tabs={FILES_PAGE_TABS} />}
        hasTabFilters
      />
    </PageWrapperNoDetails>
  );
};

export default VehicleFiles;
