import { AxiosError } from "axios";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";

import {
  FilterChangePropsType,
  FilterParamsType,
  FILTER_NAMES,
} from "../types";
import { getPageGlobalStateObjectValue } from "../../Table/functions";
import useAxiosPrivate from "../../../api/hooks/useAxiosPrivate";
import { ALERT_STATUS } from "../../../context/alert/types";
import { Filter } from "../../../shared";
import useResponse from "../../../shared/hooks/useResponse";
import { currPage, noResultClear } from "../../../atoms/atoms";

const useFilterDropdown = (filterName?: FILTER_NAMES) => {
  const { getData } = useAxiosPrivate();
  const { handleResponse } = useResponse();
  const location = useLocation();

  const [, setPageToShow] = useRecoilState(currPage);
  const [, setNoResultClearCall] = useRecoilState(noResultClear);

  const [isOpen, setOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [options, setOptions] = useState<Filter[]>([]);

  const resource = `${location.pathname}/Filters?filter=${filterName}`;

  const getFilterOptions = async () => {
    try {
      setLoading(true);

      // reset noResultClear state to false to be able add new filters
      setNoResultClearCall(false);

      await getData(resource).then((response) => {
        setOptions(response.data);
      });
    } catch (error) {
      const err = error as AxiosError<any>;
      if (err) {
        const responseMessage = err.response?.data.message;
        handleResponse(ALERT_STATUS.Critical, responseMessage || err?.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleFilterChangeValue = ({
    isTableLoading,
    filterObjectFromLocalStorage,
    newValue,
    filterName,
    setSelectedItem,
    setFilterParams,
    setValue,
  }: FilterChangePropsType) => {
    if (!isTableLoading) {
      let currentValue = filterObjectFromLocalStorage;

      if (!currentValue) {
        currentValue = {};
      }

      const selectedValue = newValue.filter(
        (x: Filter) => !currentValue[filterName]?.includes(x)
      );

      const unselectedValue = currentValue[filterName]?.filter(
        (x: Filter) => !newValue?.includes(x)
      );

      if (setSelectedItem) {
        selectedValue.length > 0
          ? setSelectedItem(selectedValue[0])
          : setSelectedItem(unselectedValue[0]);
      }

      const mergedFiltersParams = { ...currentValue, [filterName]: newValue };

      setFilterParams((prev: FilterParamsType) =>
        getPageGlobalStateObjectValue(
          location.pathname,
          prev,
          mergedFiltersParams
        )
      );

      setValue(newValue);

      setPageToShow((prev: any) =>
        getPageGlobalStateObjectValue(location.pathname, prev, "1")
      );
    }
  };

  return {
    isOpen,
    isLoading,
    options,
    setOpen,
    getFilterOptions,
    handleFilterChangeValue,
  };
};

export default useFilterDropdown;
