import { DropdownValue } from "../../../components/PageHeader/actions/types/interfaces";
import Button from "../../../components/Button/Button";
import Tooltip from "../../../components/Tooltip/Tooltip";

interface DialogActionsProps {
  onClose: () => void;
  isLoading: boolean;
  isreadyToConfirm: DropdownValue | null | boolean | string | undefined;
  confirmationButtonText?: string;
  cancelButtonText: string;
  cancelButtonTestId?: string;
  confirmationButtonTestId?: string;
  hasTooltip?: boolean;
  tooltipText?: string;
  hideAcceptButton?: boolean;
  hasTutorialHighlight?: boolean;
}

const DialogActions = ({
  onClose,
  isLoading,
  isreadyToConfirm,
  confirmationButtonText,
  confirmationButtonTestId,
  cancelButtonTestId,
  cancelButtonText,
  hasTooltip,
  tooltipText,
  hideAcceptButton,
  hasTutorialHighlight,
}: DialogActionsProps) => {
  const acceptButton = () =>
    !hideAcceptButton ? (
      <Button
        data-testid={confirmationButtonTestId ?? "save-button"}
        id={confirmationButtonTestId}
        fullWidth
        variant="textOnly"
        size="normal"
        color="primary"
        type="submit"
        isLoading={isLoading}
        disabled={!isreadyToConfirm}
        hasTutorialHighlight={hasTutorialHighlight}
      >
        {confirmationButtonText}
      </Button>
    ) : (
      <></>
    );

  return (
    <>
      <Button
        id={cancelButtonTestId}
        data-testid={cancelButtonTestId}
        fullWidth
        variant="textOnly"
        size="normal"
        color="secondary"
        onClick={onClose}
      >
        {cancelButtonText}
      </Button>
      {hasTooltip && !hideAcceptButton ? (
        <Tooltip
          data-testid="accept-button-tooltip"
          title={tooltipText}
          placement="top"
          size="medium"
          hasArrow
        >
          <span>{acceptButton()}</span>
        </Tooltip>
      ) : (
        acceptButton()
      )}
    </>
  );
};

export default DialogActions;
