/** @jsxImportSource @emotion/react */
import { css, CSSObject } from "@emotion/react";

import CustomPickersDay from "./CustomPickersDay";

import { useStyles } from "../styles";

interface CustomPickersDayBoxProps {
  props: any;
  isDayHovered: boolean;
  hasDateRangeValues: boolean;
  setIsDayHovered: React.Dispatch<React.SetStateAction<boolean>>;
}

const CustomPickersDayBox = ({
  props,
  isDayHovered,
  hasDateRangeValues,
  setIsDayHovered,
}: CustomPickersDayBoxProps) => {
  const { customPickersDayBox } = useStyles(
    undefined,
    undefined,
    props,
    isDayHovered
  );

  return (
    <div
      data-testid="custom-pickers-day-box"
      css={css([
        props?.issamemonth !== "true" ? {} : (customPickersDayBox as CSSObject),
      ])}
    >
      <CustomPickersDay
        data-testid="custom-pickers-day"
        {...props}
        onMouseMove={() => {
          hasDateRangeValues && setIsDayHovered(true); // hasDateRangeValues check fixes day flashing bug on hover after changing calendar view
        }}
        onMouseLeave={() => {
          hasDateRangeValues && setIsDayHovered(false); // hasDateRangeValues check fixes day flashing bug on hover after changing calendar view
        }}
      />
    </div>
  );
};

export default CustomPickersDayBox;
