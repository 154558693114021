/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import {
  Dispatch,
  SetStateAction,
  SyntheticEvent,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { v4 as uuidv4 } from "uuid";
import { Tab } from "@mui/material";

import CreateNewHierarchyContent from "./CreateNewHierarchyDialogContent";
import { useNewHierarchyFormInputs } from "./hooks/useNewHierarchyFormInputs";
import { PAYLOAD_ID_KEYS } from "../types";

import Autocomplete from "../../../../../Autocomplete/Autocomplete";
import InputField from "../../../../../InputField/InputField";
import AutocompleteDisbledOptionTooltip from "../../../../../Tooltip/AutocompleteDisbledOptionTooltip";
import SegmentedToggle from "../../../../../SegmentedToggle/SegmentedToggle";
import { SEGMENTED_TOGGLE_TAB_SIZES } from "../../../../../SegmentedToggle/types";

import { tableData } from "../../../../../../atoms/atoms";
import { COMPANY_LEVELS } from "../../../../../../Pages/Companies/types";
import {
  inputFieldMarginBottom,
  INPUT_LENGTH,
  useMediaQueries,
} from "../../../../../../shared";
import useCompaniesFormInputs from "../../../../../../shared/hooks/useCompaniesFormInputs/useCompaniesFormInputs";

import CompaniesIcon from "../../../../../../assets/customIcons/navigation/CompaniesIcon";
import CompanyHierarchyIcon from "../../../../../../assets/customIcons/actions/CompanyHierarchyIcon";

export const indexOfLastCompanyLevel = COMPANY_LEVELS.MaxCompaniesLevels - 1;

interface ICreateCompanyDialogContentProps {
  isAllowedToCreateNewHierarchy: boolean;
  companyTabValue: number;
  setCompanyTabValue: Dispatch<SetStateAction<number>>;
  setPayload: Dispatch<any>;
  setIsReady: Dispatch<SetStateAction<boolean>>;
  resetPayloadValues: () => void;
  isLoading: boolean;
}

const CreateCompanyDialogContent = ({
  isAllowedToCreateNewHierarchy,
  companyTabValue,
  setCompanyTabValue,
  setPayload,
  setIsReady,
  resetPayloadValues,
  isLoading,
}: ICreateCompanyDialogContentProps) => {
  const { t } = useTranslation();
  const { fromSm, fromMd } = useMediaQueries();

  const tableRowData = useRecoilValue(tableData);

  const isOnlyParentCompanyInAList = tableRowData?.length === 1;

  const checkIfOnlyParentCompanyInAList = (
    valueIfTrue: any,
    valueIfFalse: any
  ) => (isOnlyParentCompanyInAList ? valueIfTrue : valueIfFalse);

  const initialLogisticJournalData = {
    lzCode: "",
    lzCompanyName: "",
  };

  const [logisticJournalData, setLogisticJournalData] = useState(
    initialLogisticJournalData
  );

  const { lzCode, lzCompanyName } = logisticJournalData;

  const [parentCompanyId, setParentCompanyId] = useState<string | null>(
    checkIfOnlyParentCompanyInAList(tableRowData[0].id as string, null)
  );
  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [nameInputError, setNameInputError] = useState<string | null>(null);
  const [descriptionInputError, setDescriptionInputError] = useState<
    string | null
  >(null);
  const [autoAddNewDrivers, setAutoAddNewDrivers] = useState<boolean>(true);
  const [
    autoAssignGlobalScheduleToDriver,
    setAutoAssignGlobalScheduleToDriver,
  ] = useState<boolean>(true);
  const [
    autoAssignGlobalScheduleToVehicle,
    setAutoAssignGlobalScheduleToVehicle,
  ] = useState<boolean>(true);

  const {
    validateNameLength,
    handleNameChangeValue,
    validateDescriptionLength,
    handleChangeDescription,
    generateCompanySettings,
    resetAllValues,
  } = useCompaniesFormInputs({
    setNameInputError,
    setName,
    setDescription,
    setDescriptionInputError,
  });

  const {
    verifyButtonLoading,
    logisticJournalError,
    handleVerify,
    handleLzCodeInput,
    getLzCodeFieldIcon,
  } = useNewHierarchyFormInputs(
    logisticJournalData,
    setLogisticJournalData,
    setName
  );

  const hierarchyCreateSelected = companyTabValue === 1;

  const companyName = name?.trim();
  const nameIsValid =
    name &&
    companyName.length <= INPUT_LENGTH.CompanyName &&
    companyName.length >= INPUT_LENGTH.MinCompanyName;

  const descriptionIsValid =
    (description && description.length <= INPUT_LENGTH.Description) ||
    !description;

  const lzCodeOrParentCompanyIdValid = hierarchyCreateSelected
    ? lzCode
    : parentCompanyId;

  const isPayloadReadyToConfirmChild =
    Boolean(lzCodeOrParentCompanyIdValid) &&
    Boolean(name) &&
    Boolean(nameIsValid) &&
    Boolean(descriptionIsValid);

  useEffect(() => {
    const idKey = hierarchyCreateSelected
      ? PAYLOAD_ID_KEYS.LogisticsJournalId
      : PAYLOAD_ID_KEYS.ParentCompanyId;

    setPayload({
      [idKey]: lzCodeOrParentCompanyIdValid,
      name: name.trim(),
      comment: description.trim(),
      automaticDriverRegistrationEnabled: autoAddNewDrivers,
      autoAssignGlobalScheduleToDriver: autoAssignGlobalScheduleToDriver,
      autoAssignGlobalScheduleToVehicle: autoAssignGlobalScheduleToVehicle,
    });

    setIsReady(isPayloadReadyToConfirmChild);
  }, [
    hierarchyCreateSelected,
    isPayloadReadyToConfirmChild,
    parentCompanyId,
    lzCode,
    name,
    description,
    autoAddNewDrivers,
    setIsReady,
    setPayload,
    lzCodeOrParentCompanyIdValid,
    autoAssignGlobalScheduleToDriver,
    autoAssignGlobalScheduleToVehicle,
  ]);

  const handleParentCompanyChange = (event: SyntheticEvent, value: any) => {
    event?.preventDefault();
    setParentCompanyId(value.id);
  };

  const handleToggleAddNewDriver = () => {
    setAutoAddNewDrivers(!autoAddNewDrivers);
  };

  const handleToggleAssignGlobalScheduleToDriver = () => {
    setAutoAssignGlobalScheduleToDriver(!autoAssignGlobalScheduleToDriver);
  };

  const handleToggleAssignGlobalScheduleToVehicle = () => {
    setAutoAssignGlobalScheduleToVehicle(!autoAssignGlobalScheduleToVehicle);
  };

  const getDisabledStatus = () => {
    if (!isAllowedToCreateNewHierarchy || !hierarchyCreateSelected) {
      return false;
    }

    return !lzCompanyName;
  };

  const companySettings = generateCompanySettings({
    handleToggleAddNewDriver,
    addNewDriver: autoAddNewDrivers,
    handleToggleAssignGlobalScheduleToDriver,
    assignGlobalScheduleToDriver: autoAssignGlobalScheduleToDriver,
    handleToggleAssignGlobalScheduleToVehicle,
    assignGlobalScheduleToVehicle: autoAssignGlobalScheduleToVehicle,
    isDisabled: getDisabledStatus(),
    readonly: isLoading,
  });

  const handleTabChange = (_: SyntheticEvent, newValue: number) => {
    setCompanyTabValue(newValue);
    handleFormReset();
  };

  const handleFormReset = () => {
    resetPayloadValues();
    resetAllValues();
    setLogisticJournalData(initialLogisticJournalData);
  };

  return (
    <>
      {isAllowedToCreateNewHierarchy && (
        <SegmentedToggle
          data-testid="new-company-tabs"
          value={companyTabValue}
          size={SEGMENTED_TOGGLE_TAB_SIZES.Small}
          fullWidth={fromSm}
          tabWidth={fromMd ? "277px" : "100%"}
          onChange={handleTabChange}
          css={css(inputFieldMarginBottom)}
        >
          <Tab
            data-testid="child-company-form-tab"
            label={t("Button##child company")}
            icon={<CompaniesIcon />}
            className={!isLoading ? "enabled" : undefined}
            disabled={isLoading}
          />

          <Tab
            data-testid="new-client-form-tab"
            label={t("Button##new client")}
            icon={<CompanyHierarchyIcon />}
            className={!isLoading ? "enabled" : undefined}
            disabled={isLoading}
          />
        </SegmentedToggle>
      )}

      {hierarchyCreateSelected ? (
        <CreateNewHierarchyContent
          verifyButtonLoading={verifyButtonLoading}
          logisticJournalError={logisticJournalError}
          logisticJournalData={logisticJournalData}
          getLzCodeFieldIcon={getLzCodeFieldIcon}
          handleLzCodeInput={handleLzCodeInput}
          handleFormReset={handleFormReset}
          handleVerify={handleVerify}
          isLoading={isLoading}
        />
      ) : (
        <Autocomplete
          data-testid="parent-company-dropdown"
          isRequired
          size="medium"
          labelLeft={t("Dialog##parent company")}
          fullWidth
          disabled={isLoading}
          disableClearable
          // prefill dropdown if only one company is in a list
          value={tableRowData.find((row) => row.id === parentCompanyId) || null}
          // Do not show placeholder if only one company is in a list
          textFieldParams={{
            placeholder: checkIfOnlyParentCompanyInAList(
              undefined,
              t("Dialog##select")
            ),
          }}
          getOptionDisabled={(option) =>
            option.level >= indexOfLastCompanyLevel
          }
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) => option?.id === value?.id}
          options={tableRowData}
          renderOption={(props, option) => {
            return (
              <AutocompleteDisbledOptionTooltip
                key={option.id}
                props={props}
                option={option.name}
                tooltipTitle={t(`Tooltip##parent company`, {
                  companyName: option.name,
                  maxAllowedLevels: COMPANY_LEVELS.MaxCompaniesLevels,
                })}
              />
            );
          }}
          onChange={(_, newInputValue) => {
            handleParentCompanyChange(_, newInputValue);
          }}
          customStyle={inputFieldMarginBottom}
        />
      )}

      <InputField
        id={uuidv4()}
        testId="company-name-input-field"
        size="medium"
        fullWidth
        required
        disabled={getDisabledStatus() || isLoading}
        value={name}
        errorText={nameIsValid ? "" : nameInputError}
        onBlur={validateNameLength}
        onChange={handleNameChangeValue}
        labelLeft={
          hierarchyCreateSelected
            ? t("Dialog##company name in tachoSync")
            : t("Dialog##company name")
        }
        placeholder={
          !getDisabledStatus()
            ? t("Dialog##company name placeholder")
            : undefined
        }
        customStyle={inputFieldMarginBottom}
      />
      <InputField
        id={uuidv4()}
        data-testid="company-description-input-field"
        size="medium"
        fullWidth
        disabled={getDisabledStatus() || isLoading}
        value={description}
        errorText={descriptionIsValid ? "" : descriptionInputError}
        onBlur={validateDescriptionLength}
        onChange={handleChangeDescription}
        labelLeft={t("Dialog##description")}
        placeholder={!getDisabledStatus() ? t("Dialog##enter here") : undefined}
        customStyle={inputFieldMarginBottom}
      />

      {/* Input field for "Send files to email" is going to be added in the future */}

      {companySettings}
    </>
  );
};

export default CreateCompanyDialogContent;
