/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Fragment } from "react";
import { TFunction } from "i18next";

import InfoIconWithTooltip from "../../../IconsWithTooltips/InfoIconWithTooltip/InfoIconWithTooltip";

interface TimeInputLabelProps {
  t: TFunction<"translation", undefined>;
}

const TimeInputLabel = ({ t }: TimeInputLabelProps) => {
  return (
    <div css={css({ display: "flex" })} data-testid="schedule-time-input-label">
      {t("Dialog##time")}
      <Fragment>&#42;</Fragment>
      <InfoIconWithTooltip
        title={t("Dialog##schedule time tooltip description")}
        customIconStyle={{ marginLeft: "4px" }}
      />
    </div>
  );
};

export default TimeInputLabel;
