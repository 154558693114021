import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

interface CircleStepperErrorIconProps extends SvgIconProps {
  fillColour?: string;
  width?: string;
  height?: string;
}

const CircleStepperErrorIcon = ({
  fillColour,
  width,
  height,
  ...props
}: CircleStepperErrorIconProps) => {
  return (
    <SvgIcon
      {...props}
      width={width || "32"}
      height={height || "32"}
      viewBox="0 0 32 32"
      fill="none"
    >
      <g clipPath="url(#clip0_9724_125037)">
        <path
          d="M16.0002 24C16.4536 24 16.8336 23.8467 17.1402 23.54C17.4469 23.2333 17.6002 22.8533 17.6002 22.4C17.6002 21.9467 17.4469 21.5667 17.1402 21.26C16.8336 20.9533 16.4536 20.8 16.0002 20.8C15.5469 20.8 15.1669 20.9533 14.8602 21.26C14.5536 21.5667 14.4002 21.9467 14.4002 22.4C14.4002 22.8533 14.5536 23.2333 14.8602 23.54C15.1669 23.8467 15.5469 24 16.0002 24ZM16.0002 17.6C16.4536 17.6 16.8336 17.4467 17.1402 17.14C17.4469 16.8333 17.6002 16.4533 17.6002 16V9.6C17.6002 9.14667 17.4469 8.76667 17.1402 8.46C16.8336 8.15333 16.4536 8 16.0002 8C15.5469 8 15.1669 8.15333 14.8602 8.46C14.5536 8.76667 14.4002 9.14667 14.4002 9.6V16C14.4002 16.4533 14.5536 16.8333 14.8602 17.14C15.1669 17.4467 15.5469 17.6 16.0002 17.6ZM16.0002 32C13.7869 32 11.7069 31.58 9.76024 30.74C7.81358 29.9 6.12024 28.76 4.68024 27.32C3.24024 25.88 2.10024 24.1867 1.26024 22.24C0.420244 20.2933 0.000244141 18.2133 0.000244141 16C0.000244141 13.7867 0.420244 11.7067 1.26024 9.76C2.10024 7.81333 3.24024 6.12 4.68024 4.68C6.12024 3.24 7.81358 2.1 9.76024 1.26C11.7069 0.42 13.7869 0 16.0002 0C18.2136 0 20.2936 0.42 22.2402 1.26C24.1869 2.1 25.8802 3.24 27.3202 4.68C28.7602 6.12 29.9002 7.81333 30.7402 9.76C31.5802 11.7067 32.0002 13.7867 32.0002 16C32.0002 18.2133 31.5802 20.2933 30.7402 22.24C29.9002 24.1867 28.7602 25.88 27.3202 27.32C25.8802 28.76 24.1869 29.9 22.2402 30.74C20.2936 31.58 18.2136 32 16.0002 32Z"
          fill="#B91C1C"
        />
      </g>
      <defs>
        <clipPath id="clip0_9724_125037">
          <rect
            width="32"
            height="32"
            fill="white"
            transform="translate(0.000244141)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default CircleStepperErrorIcon;
