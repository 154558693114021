import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { SettingsPayloadDto } from "./types";
import TimeSettings from "./TimeSettings/TimeSettings";
import FileFormatSettings from "./FileFormatSettings/FileFormatSettings";
import Dialog from "../../../../../Dialog/Dialog";
import DialogActions from "../../../../../../shared/components/buttons/DialogActions";
import { useAuth } from "../../../../../../context/Auth/AuthProvider";
import useAxiosPrivate from "../../../../../../api/hooks/useAxiosPrivate";
import { ApiResources } from "../../../../../../api/resources";
import useResponse from "../../../../../../shared/hooks/useResponse";
import { ALERT_STATUS } from "../../../../../../context/alert/types";
import useRefreshTableData from "../../../../../../shared/hooks/useRefreshTableData";
import {
  getSearchQueryParamsFromLocalStorage,
  ROUTES,
  useDateTime,
} from "../../../../../../shared";
import useRefreshDashboardData from "../../../../../../shared/hooks/useRefreshDashboardData";

interface GeneralSettingsProps {
  closeDialog: () => void;
  isOpen: boolean;
}

const GeneralSettings = ({ closeDialog, isOpen }: GeneralSettingsProps) => {
  const {
    systemTimeZone,
    userBrowserDateFormat,
    revertToSystemTimeZone,
  } = useDateTime();

  const { t } = useTranslation();
  const { userSettings, getUserSettings, getUserData } = useAuth();
  const { putData } = useAxiosPrivate();
  const { handleCommonError, handleResponse } = useResponse();
  const { refreshTableData } = useRefreshTableData();
  const { refreshDashboardData } = useRefreshDashboardData();
  const location = useLocation();

  const [settingsPayload, setSettingsPayload] = useState<SettingsPayloadDto>(
    userSettings
  );
  const [isAutomaticDateChecked, setIsAutomaticDateChecked] = useState(false);
  const [isGeneralSettingsLoading, setIsGeneralSettingsLoading] = useState(
    true
  );
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isTriggered, setIsTriggered] = useState(false);

  const isDashboardPage = location.pathname === ROUTES.Dashboard;
  const refreshDataDependingOnCurrPage = isDashboardPage
    ? refreshDashboardData
    : refreshTableData;

  useEffect(() => {
    if (isOpen) {
      const fetchUserSettings = async () => {
        setIsGeneralSettingsLoading(true);

        const userSettingsData = await getUserSettings();

        setSettingsPayload(userSettingsData);

        if (userSettingsData) {
          const {
            timeSettings: { dateFormat, timeZone },
          } = userSettingsData;

          const hasDateValues = dateFormat && timeZone;

          setIsAutomaticDateChecked(!hasDateValues);
          setIsGeneralSettingsLoading(false);
        }
      };

      fetchUserSettings();
    }
    // eslint-disable-next-line
  }, [isOpen]);

  useEffect(() => {
    if (isAutomaticDateChecked) {
      setSettingsPayload((prev) => ({
        ...prev,
        timeSettings: { dateFormat: null, timeZone: null },
      }));
    }
  }, [isAutomaticDateChecked]);

  const handleSubmit = async () => {
    try {
      setIsSubmitLoading(true);

      await putData(ApiResources.UsersSettings, settingsPayload);

      await getUserData(!isAutomaticDateChecked);

      if (isAutomaticDateChecked) {
        revertToSystemTimeZone();
      }

      const searchQueryParams = getSearchQueryParamsFromLocalStorage(
        location.pathname
      );
      refreshDataDependingOnCurrPage(undefined, searchQueryParams, false);

      handleResponse(
        ALERT_STATUS.Success,
        t("Alert##general settings update success")
      );

      closeDialog();
    } catch (error) {
      handleCommonError(error, t);
    }
    setIsSubmitLoading(false);
  };

  const isReadyToConfirm = !isGeneralSettingsLoading && isTriggered;

  return (
    <Dialog
      data-testid="general-settings-dialog"
      title={t("Settings##general settings")}
      extendToMaxWidth
      isTitleSeparator
      isActionsSeparator
      submit={handleSubmit}
      actions={
        <DialogActions
          onClose={closeDialog}
          isLoading={isSubmitLoading}
          isreadyToConfirm={isReadyToConfirm}
          confirmationButtonText={t("Button##save")}
          confirmationButtonTestId="general-settings-save-button"
          cancelButtonText={t("Button##close")}
          cancelButtonTestId="general-settings-close-button"
        />
      }
      TransitionProps={{
        onExited: () => {
          setIsTriggered(false);
        },
      }}
      close={closeDialog}
      open={isOpen}
    >
      <FileFormatSettings
        userFileFormat={userSettings?.fileFormat}
        settingsPayload={settingsPayload}
        isSubmitLoading={isSubmitLoading}
        isGeneralSettingsLoading={isGeneralSettingsLoading}
        setSettingsPayload={setSettingsPayload}
        setIsTriggered={setIsTriggered}
      />
      <TimeSettings
        isChecked={isAutomaticDateChecked}
        isGeneralSettingsLoading={isGeneralSettingsLoading}
        isSubmitLoading={isSubmitLoading}
        systemTimeZone={systemTimeZone}
        userBrowserDateFormat={userBrowserDateFormat}
        setSettingsPayload={setSettingsPayload}
        setIsChecked={setIsAutomaticDateChecked}
        setIsTriggered={setIsTriggered}
      />
    </Dialog>
  );
};

export default GeneralSettings;
