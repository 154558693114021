const LinkDetachedIcon = () => {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.307 8.46865L12.6877 6.08794C12.6877 6.08794 13.6305 5.14513 12.2163 3.73092C10.8021 2.31671 9.85923 3.25951 9.85923 3.25951C9.85923 3.25951 8.42136 4.69741 7.47856 5.64022"
          stroke="#111729"
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.7041 10.29L13.5898 10.7614"
          stroke="#111729"
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.29 11.7041L10.7614 13.5897"
          stroke="#111729"
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.24304 5.65695L2.35742 5.18555"
          stroke="#111729"
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.65695 4.24255L5.18555 2.35693"
          stroke="#111729"
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.64071 7.47853L3.26001 9.85921C3.26001 9.85921 2.31719 10.802 3.73141 12.2163C5.14563 13.6305 6.08843 12.6877 6.08843 12.6877C6.08843 12.6877 7.52632 11.2498 8.46912 10.3069"
          stroke="#111729"
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  };
  
  export default LinkDetachedIcon;
  