/** @jsxImportSource @emotion/react */
import { css, CSSObject } from "@emotion/react";

import { SetStateAction, useContext, useEffect, useRef, useState } from "react";
import moment, { Moment } from "moment";
import { useTranslation } from "react-i18next";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

import {
  DesktopDatePicker,
  DesktopDatePickerProps,
} from "@mui/x-date-pickers/DesktopDatePicker";

import { ExpandMoreRounded as SwitchViewIcon } from "@mui/icons-material";

import LeftArrowButton from "./actionComponents/LeftArrowButton";
import RightArrowButton from "./actionComponents/RightArrowButton";
import TextField from "./actionComponents/TextField";
import { DatePickerProps } from "./types";
import { textStyles, useStyles } from "./styles";

import Label from "../Label/Label";

import { DATE_FORMATS, INVALID_DATE, useDateTime } from "../../shared";
import { ThemeContext } from "../../context/theme/ThemeContextProvider";

const DatePicker = ({
  setDate,
  externalErrorHandle,
  label,
  textFieldParams = {},
  customStyle,
  initialDate,
  ...props
}: DatePickerProps & DesktopDatePickerProps<Moment>) => {
  const { colors } = useContext(ThemeContext);

  const { currentDateFormat, convertToCurrentTimezone } = useDateTime();
  const { t } = useTranslation();

  const { datePickerOverride, datePickerSharedStyles } = useStyles(
    colors,
    textStyles
  );

  const initialValue: Moment | null = initialDate
    ? convertToCurrentTimezone(initialDate)
    : null;

  const [value, setValue] = useState<Moment | null>(initialValue || null);
  const [error, setError] = useState<string | null>(null);
  const [innerError, setInnerError] = useState<string | null>(null);

  const isInitialRender = useRef(true);

  useEffect(() => {
    //Prevents handleFormChange from overloading other inputs on load
    if (value !== initialValue) handleValueChange();

    isInitialRender.current = false;
    // eslint-disable-next-line
  }, [value, error, innerError]);

  const handleValueChange = (resetValue?: boolean) => {
    if (value || !resetValue) {
      const isNotValid = !moment(value).isValid() || error;

      const fwDate = isNotValid
        ? INVALID_DATE
        : moment(value).format(DATE_FORMATS.YearMonthDay);

      !isNotValid && setDate(fwDate);
    } else {
      setValue(null);
    }
  };

  const changeErrorValues = (
    error: string | null | SetStateAction<string | null>
  ) => {
    setError(error);
    externalErrorHandle && externalErrorHandle(error);
  };

  const handleChange = (newValue: Moment | null) => {
    const utcDate = moment.utc(newValue);

    innerError && changeErrorValues(innerError);
    setValue(utcDate);
  };

  const handleError = (
    error: string | null | SetStateAction<string | null>
  ) => {
    setInnerError(error);
    if (isInitialRender.current === false) {
      changeErrorValues(error);
    }
  };

  const handleClose = (props: any) => {
    error && handleValueChange(true);
    props.onClose?.();
  };

  const userLocale = navigator.language || "en-US";

  return (
    <div
      css={css({
        ...customStyle,
      })}
    >
      <Label titleLeft={label} isRequired={textFieldParams.required} />

      <LocalizationProvider
        dateAdapter={AdapterMoment}
        adapterLocale={userLocale}
      >
        <DesktopDatePicker
          {...props}
          data-testid="date-picker-input-field"
          open={props.open}
          onOpen={props.onOpen}
          value={value}
          timezone={props.timezone ?? "UTC"}
          format={currentDateFormat}
          views={["year", "month", "day"]}
          onChange={handleChange}
          onClose={() => handleClose(props)}
          onError={handleError}
          slots={{
            switchViewIcon: SwitchViewIcon,
            previousIconButton: LeftArrowButton,
            nextIconButton: RightArrowButton,
            textField: TextField,
          }}
          slotProps={{
            openPickerButton: {
              sx: {
                color: colors.textPlaceholder,
              },
            },
            textField: {
              timeZoneExplanatory: textFieldParams.timeZoneExplanatory,
              helperText: error ? t(`Date picker##${error}`) : error,
              "data-testid": "date-picker-input-field",
              onKeyDown: (event: any) => {
                if (event.key === "Backspace") {
                  handleValueChange(true);
                }
              },
            } as any,
            layout: {
              sx: [
                datePickerOverride as CSSObject,
                datePickerSharedStyles as CSSObject,
              ],
            },
          }}
        />
      </LocalizationProvider>
    </div>
  );
};

export default DatePicker;
