import React from "react";
import { Box, CSSObject } from "@mui/system";
import { useTranslation } from "react-i18next";

import { useStyles } from "../styles";
import { DATE_PICKER_ACTION_BUTTON } from "../types";

import Button from "../../Button/Button";
import { ButtonColors } from "../../Button/types";

interface ActionButtonsProps {
  onClearButtonclick: (event: React.MouseEvent) => void;
  onApplyButtonclick: (event: React.MouseEvent) => void;
  hasValues: boolean;
  isLoading: boolean;
}

const ActionButtons = ({
  onClearButtonclick,
  onApplyButtonclick,
  hasValues,
  isLoading,
}: ActionButtonsProps) => {
  const { t } = useTranslation();

  const { dateRangeActionButtons } = useStyles();

  const actionButtons = [
    {
      text: t("Button##clear"),
      color: "secondary",
      onAction: onClearButtonclick,
    },
    {
      text: t("Button##apply"),
      color: "primary",
      onAction: onApplyButtonclick,
    },
  ];

  return (
    <Box sx={dateRangeActionButtons as CSSObject}>
      {actionButtons.map((button: DATE_PICKER_ACTION_BUTTON) => (
        <Button
          data-testid={`${button.text}-button`}
          key={button.text}
          variant="textOnly"
          color={button.color as ButtonColors}
          size="small"
          onClick={button.onAction}
          disabled={!hasValues || isLoading}
          isLoading={button.text === t("Button##apply") && isLoading}
        >
          {button.text}
        </Button>
      ))}
    </Box>
  );
};

export default ActionButtons;
