/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import { SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";

import { useStyles } from "../../../../../../styles";

import { ClearInputIcon } from "../../../../../../../InputField/components/ClearInputIcon";
import InputField from "../../../../../../../InputField/InputField";

import { tableLoading } from "../../../../../../../../atoms/atoms";

interface ISearchCompanyProps {
  isRootSearch: boolean;
  searchValue: string;
  handleSearchValue: (e: {
    target: {
      value: SetStateAction<string>;
    };
  }) => void;
  clearSearchValue: () => void;
}

const SearchCompany = ({
  isRootSearch,
  searchValue,
  handleSearchValue,
  clearSearchValue,
}: ISearchCompanyProps) => {
  const { t } = useTranslation();

  const isTableLoading = useRecoilValue(tableLoading);

  const { searchCompanyDiv } = useStyles();

  const translationString = isRootSearch ? "child company" : "company name";

  return (
    <div css={css(searchCompanyDiv)}>
      <InputField
        autoFocus
        fullWidth
        size="medium"
        placeholder={t(`Search##search ${translationString}`)}
        iconLeft={<SearchRoundedIcon />}
        value={searchValue}
        onChange={handleSearchValue}
        iconRight={
          <ClearInputIcon value={searchValue} clearValue={clearSearchValue} />
        }
        disabled={isTableLoading}
      />
    </div>
  );
};

export default SearchCompany;
