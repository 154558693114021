import PaperBox from "../PaperBox/PaperBox";
import ToggleItemWithTooltip from "../ToggleItem/ToggleItemWithTooltip";

interface ToggleSwitchPaperBoxProps {
  title: string;
  onToggleSwitch: (() => void) | undefined;
  isChecked: boolean | undefined;
  tooltip?: string;
  isDisabled?: boolean;
  customIconStyle?: {};
}

const ToggleSwitchPaperBox = ({
  title,
  onToggleSwitch,
  isChecked,
  tooltip,
  isDisabled,
  customIconStyle,
}: ToggleSwitchPaperBoxProps) => {
  return (
    <PaperBox
      customStyling={{ padding: "0 16px", margin: "16px 0" }}
      testid="global-schedule-inheritance-switch"
    >
      <ToggleItemWithTooltip
        title={title}
        tooltipText={tooltip}
        onToggle={onToggleSwitch}
        isChecked={isChecked || false}
        isDisabled={isDisabled}
        customIconStyle={customIconStyle}
      />
    </PaperBox>
  );
};

export default ToggleSwitchPaperBox;
