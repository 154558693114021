import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

import QueryDropdown from "../../../QueryDropdown/QueryDropdown";
import { FILTER_KEYS } from "../../../Filters/types";
import { ResourceParams } from "../../../Schedules/types";

import { ID_TYPE, ScheduleOption } from "../../../../shared";

interface RemoveSchedulesDialogContentProps {
  selectedRows: string[];
  resourceParams: ResourceParams;
  schedules: ScheduleOption[];
  setSchedules: Dispatch<SetStateAction<ScheduleOption[]>>;
  setPayload: Dispatch<SetStateAction<object | null>>;
  isLoading: boolean;
}

const RemoveSchedulesDialogContent = ({
  selectedRows,
  resourceParams,
  schedules,
  setSchedules,
  setPayload,
  isLoading,
}: RemoveSchedulesDialogContentProps) => {
  const { t } = useTranslation();

  const { filterKey, apiResource } = resourceParams;

  const handleScheduleSelect = (scheduleValue: ScheduleOption[]) => {
    setSchedules(scheduleValue);

    const scheduleIdArray = scheduleValue?.map((item) => item.id);

    setPayload(
      scheduleIdArray.length
        ? {
            [FILTER_KEYS.ScheduleIds]: scheduleIdArray,
            [filterKey]: selectedRows,
          }
        : null
    );
  };

  return (
    <QueryDropdown
      data-testid="remove-schedules-dropdown"
      multiple
      resource={apiResource}
      idType={ID_TYPE.Id}
      value={schedules}
      size="medium"
      fullWidth
      change={(_, val) => handleScheduleSelect(val)}
      labelLeft={t("Dialog##select schedules")}
      textFieldParams={{
        placeholder: schedules.length > 0 ? "" : t("Dialog##select"),
      }}
      noCheckboxes
      isActionLoading={isLoading}
    />
  );
};

export default RemoveSchedulesDialogContent;
