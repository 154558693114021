import { useLayoutEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import ScheduleFormContent from "../ScheduleFormContent";

import { useScheduleFormInputs } from "../../hooks/useScheduleFormInputs";
import {
  REPEAT_DAY_VALUES,
  ResourceParams,
  SCHEDULE_PAYLOAD_NAMES,
  ScheduleDataDto,
  SCHEDULES_NAMES,
} from "../../types";

import Dialog from "../../../Dialog/Dialog";
import { DialogWidth } from "../../../Dialog/types/enums";

import useAxiosPrivate from "../../../../api/hooks/useAxiosPrivate";
import {
  DriverDataTypes,
  DRIVERS_ACTIONS,
  GeneralDialogProps,
  TABLE_NAMES,
  useMediaQueries,
  VehicleDataTypes,
  VEHICLES_ACTIONS,
} from "../../../../shared";
import DialogActions from "../../../../shared/components/buttons/DialogActions";
import useResponse from "../../../../shared/hooks/useResponse";

interface EditScheduleDialogProps extends GeneralDialogProps {
  resourceParams: ResourceParams;
  scheduleData: ScheduleDataDto | null;
  isGlobalSchedule: boolean;
  handleContentRefresh: (
    dialogName: VEHICLES_ACTIONS | DRIVERS_ACTIONS,
    successMessage: string,
    leaveRowsChecked?: boolean
  ) => Promise<void>;
  user: any;
}

const EditScheduleDialog = ({
  resourceParams,
  isOpen,
  scheduleData,
  isGlobalSchedule,
  onClose,
  handleContentRefresh,
  user,
}: EditScheduleDialogProps) => {
  const { toMd } = useMediaQueries();
  const { t } = useTranslation();

  const { putData, postData } = useAxiosPrivate();
  const { handleCommonError } = useResponse();

  const { resourceName, actionEdit, apiResource } = resourceParams;

  const {
    schedulePayload,
    repeatPeriod,
    activityDates,
    inputError,
    readyToSubmit,
    startDateTime,
    inputValues,
    setRepeatPeriod,
    setSchedulePayload,
    resetValueStates,
    validateScheduleName,
    validateStartDate,
    handleFormChange,
    handleTimeChange,
    hadleToggleGlobalLinkSwitch,
    isInheritanceEnabled,
    isAttachedToRoot,
  } = useScheduleFormInputs({
    scheduleData,
    resourceName,
  });

  const [isLoading, setIsLoading] = useState(false);

  useLayoutEffect(() => {
    isOpen && handleInitialEditInputs(scheduleData);
    // eslint-disable-next-line
  }, [scheduleData, isOpen]);

  const handleCloseDialog = () => onClose(actionEdit);

  const handleEdit = async () => {
    try {
      setIsLoading(true);

      const payloadCopy = { ...schedulePayload };

      if (scheduleData) {
        (payloadCopy as any).id = scheduleData.id;
      }

      const isNewGlobalSchedule = isGlobalSchedule && !scheduleData;

      await (isNewGlobalSchedule ? postData : putData)(
        apiResource,
        payloadCopy
      );

      await handleContentRefresh(
        actionEdit,
        t(`Alert##${isNewGlobalSchedule ? "global" : "edit"} schedule success`),
        true
      );
    } catch (error) {
      handleCommonError(error, t);
    }
    setIsLoading(false);
  };

  const handleInitialEditInputs = (scheduleData: ScheduleDataDto | null) => {
    if (scheduleData) {
      const {
        customRepeat,
        id,
        global,
        ...scheduleDataWithoutId
      } = scheduleData;

      const vehicleFileTypes: VehicleDataTypes[] = scheduleDataWithoutId.fileTypes.map(
        (type: string) =>
          VehicleDataTypes[type as keyof typeof VehicleDataTypes]
      );

      const driverFileTypes = resourceName === TABLE_NAMES.Drivers && [
        DriverDataTypes.DriverCard,
      ];

      setSchedulePayload({
        ...schedulePayload,
        ...scheduleDataWithoutId,
        customRepeat,
        global,
        fileTypes: driverFileTypes || vehicleFileTypes,
      });

      const repeatDaysValue = Object.entries(REPEAT_DAY_VALUES).find(
        ([, value]) => value === scheduleDataWithoutId.repeat
      );

      const daysValue = repeatDaysValue
        ? Number(repeatDaysValue[0])
        : customRepeat;

      setRepeatPeriod({
        days: daysValue,
        id: `${scheduleDataWithoutId.repeat}_schedule_period`,
        name: t(
          `Schedules##repeat period##${
            scheduleDataWithoutId[SCHEDULE_PAYLOAD_NAMES.Repeat]
          }`
        ),
        value: scheduleDataWithoutId[SCHEDULE_PAYLOAD_NAMES.Repeat],
      });
    } else {
      handleFormChange(
        SCHEDULE_PAYLOAD_NAMES.Name,
        SCHEDULES_NAMES.GlobalSchedule,
        true
      );
    }
  };

  return (
    <Dialog
      data-testid="edit-schedule-dialog"
      title={
        isGlobalSchedule
          ? t(`Schedules##edit schedule##title##global`)
          : t(`Schedules##edit schedule##title##not global`)
      }
      description={
        isGlobalSchedule
          ? t(`Schedules##edit schedule##description##${resourceName}##global`)
          : t(
              `Schedules##edit schedule##description##${resourceName}##not global`
            )
      }
      open={isOpen}
      isTitleSeparator
      isActionsSeparator
      width={!toMd ? DialogWidth.MaxWidth : undefined}
      close={handleCloseDialog}
      submit={handleEdit}
      TransitionProps={{
        onExited: () => resetValueStates(),
      }}
      actions={
        <DialogActions
          onClose={handleCloseDialog}
          isLoading={isLoading}
          isreadyToConfirm={readyToSubmit}
          confirmationButtonText={t("Button##save")}
          confirmationButtonTestId="edit-schedule-confirmation-button"
          cancelButtonText={t("Button##close")}
          cancelButtonTestId="edit-schedule-cancel-button"
        />
      }
    >
      <ScheduleFormContent
        inputError={inputError}
        inputValues={inputValues}
        repeatValue={repeatPeriod}
        startDateTime={startDateTime}
        selectedDataTypes={schedulePayload.fileTypes}
        activityDates={activityDates}
        isGlobalSchedule={isGlobalSchedule}
        scheduleData={scheduleData}
        handleTimeChange={handleTimeChange}
        handleFormChange={handleFormChange}
        validateScheduleName={validateScheduleName}
        validateStartDate={validateStartDate}
        isLoading={isLoading}
        hadleToggleGlobalLinkSwitch={hadleToggleGlobalLinkSwitch}
        isInheritanceEnabled={isInheritanceEnabled}
        isAttachedToRoot={isAttachedToRoot}
        user={user}
      />
    </Dialog>
  );
};

export default EditScheduleDialog;
