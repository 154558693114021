/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useContext } from "react";

import { useStyles } from "../styles";

import InputField from "../../InputField/InputField";

import { ThemeContext } from "../../../context/theme/ThemeContextProvider";

const TextField = ({ timeZoneExplanatory, ...restProps }: any) => {
  const {
    colors: { textPlaceholder },
  } = useContext(ThemeContext);

  const { error, helperText, InputProps, placeholder, value } = restProps;

  const { timeZoneExplanatorySpan } = useStyles();

  const isTimeZoneExplanatory =
    timeZoneExplanatory &&
    value.length !== 0 &&
    value !== placeholder &&
    !error;

  return (
    <div
      css={css({
        position: "relative",
      })}
    >
      <InputField
        {...restProps}
        fullWidth
        size="medium"
        readOnly={InputProps.readOnly}
        errorText={helperText}
      />

      {isTimeZoneExplanatory && (
        <span
          css={css([
            timeZoneExplanatorySpan,
            {
              color: textPlaceholder,
            },
          ])}
        >
          {timeZoneExplanatory}
        </span>
      )}
    </div>
  );
};

export default TextField;
