/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useTranslation } from "react-i18next";

import { useFilters } from "../hooks";
import { useStyles } from "../styles";
import { FILTER_NAMES } from "../types";
import FilterButton from "../components/FilterButton";
import FilterDropdown from "../FilterDropdown/FilterDropdown";
import FilterMenu from "../components/FilterMenu";

import { TABLE_NAMES } from "../../../shared";

interface SharedFiltersProps {
  tableName: TABLE_NAMES;
  translateStatus?: string;
}

const SharedFilters = ({ translateStatus, tableName }: SharedFiltersProps) => {
  const {
    anchorEl,
    handleOpenMenu,
    handleCloseMenu,
    isPartOfFilterMenu,
  } = useFilters();

  const { t } = useTranslation();

  const { filtersListBlock, separateFilterDropdown } = useStyles({});

  const hasMoreFilters = true;
  const isDriversPage = tableName === TABLE_NAMES.Drivers;

  return (
    <>
      {!isPartOfFilterMenu() && (
        <FilterDropdown
          filterName={FILTER_NAMES.Schedules}
          placeholder={t("Filter##schedule name")}
          css={css(separateFilterDropdown)}
        />
      )}
      <FilterButton
        onOpen={handleOpenMenu}
        hasLoadingCustomStyle
        hasMoreFilters={hasMoreFilters}
      />
      <FilterMenu
        isOpen={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        anchorEl={anchorEl}
        hasMoreFilters={hasMoreFilters}
      >
        <div css={css(filtersListBlock)}>
          {isPartOfFilterMenu() && (
            <FilterDropdown
              filterName={FILTER_NAMES.Schedules}
              placeholder={t("Filter##schedule name")}
            />
          )}

          <FilterDropdown
            filterName={FILTER_NAMES.Statuses}
            placeholder={
              isDriversPage
                ? t("Filter##last activity")
                : t("Filter##device status")
            }
            translateString={isDriversPage ? translateStatus : undefined}
          />

          <FilterDropdown
            filterName={FILTER_NAMES.LastDownloadAttempt}
            placeholder={t("Filter##last download attempt")}
          />
        </div>
      </FilterMenu>
    </>
  );
};

export default SharedFilters;
