import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";

import InputField from "../../../../../InputField/InputField";
import QueryDropdown from "../../../../../QueryDropdown/QueryDropdown";
import { ApiResources } from "../../../../../../api/resources";
import { MappedUsersDataDto } from "../../../../../../Pages/Users/types/types";
import {
  ID_TYPE,
  inputFieldMarginBottom,
  SelectedOptionObjectType,
} from "../../../../../../shared";
import {
  capitalizeStringOnlyFirstLetter,
  replaceStringWithSpacesToCamelCase,
} from "../../../../../../shared/functions/functions";
import { USER_ROLES_RAW_DATA } from "../../../../../../roles/types/enums";

interface IEditUserDialogContentProps {
  rowData: MappedUsersDataDto;
  initialRole: SelectedOptionObjectType | null;
  setPayload: Dispatch<any>;
  setIsReady: Dispatch<SetStateAction<boolean>>;
  isLoading: boolean;
}

const EditUserDialogContent = ({
  rowData,
  initialRole,
  setPayload,
  setIsReady,
  isLoading,
}: IEditUserDialogContentProps) => {
  const { t } = useTranslation();

  const [
    selectedCompany,
    setSelectedCompany,
  ] = useState<SelectedOptionObjectType | null>(rowData.company);

  const [
    selectedRole,
    setselectedRole,
  ] = useState<SelectedOptionObjectType | null>(initialRole);
  const [isCompanyChanged, setIsCompanyChanged] = useState<boolean>(false);
  const [isRoleChanged, setIsRoleChanged] = useState<boolean>(false);

  const hasAnyValueBeenChanged = isCompanyChanged || isRoleChanged;

  const isPayloadReadyToConfirm =
    Boolean(selectedCompany) && Boolean(selectedRole) && hasAnyValueBeenChanged;

  useEffect(() => {
    setPayload({
      userId: rowData.id,
      companyId: selectedCompany?.id,
      role:
        selectedRole && replaceStringWithSpacesToCamelCase(selectedRole?.name),
    });
    setIsReady(isPayloadReadyToConfirm);
  }, [
    isPayloadReadyToConfirm,
    rowData,
    selectedCompany?.id,
    selectedRole,
    selectedRole?.name,
    setIsReady,
    setPayload,
  ]);

  const handleCompanyDropdownChange = (chosenValue: any | null) => {
    setSelectedCompany(chosenValue);
    setIsCompanyChanged(true);
  };

  const handleRoleDropdownChange = (chosenValue: any | null) => {
    setselectedRole(chosenValue);
    setIsRoleChanged(true);
  };

  const showSupportRoleInDropdown =
    initialRole?.name ===
    capitalizeStringOnlyFirstLetter(USER_ROLES_RAW_DATA.Support);

  return (
    <>
      <InputField
        id={uuidv4()}
        size="medium"
        data-testid="edit-user-email"
        fullWidth
        readOnly
        labelLeft={t("Dialog##email")}
        placeholder={rowData.email}
        customStyle={inputFieldMarginBottom}
      />
      <QueryDropdown
        data-testid="edit-user-company-dropdown"
        resource={ApiResources.CompanyList}
        idType={ID_TYPE.Id}
        value={selectedCompany}
        size="medium"
        disableClearable
        fullWidth
        isRequired
        change={(_, val) => handleCompanyDropdownChange(val)}
        labelLeft={t("Dialog##company")}
        textFieldParams={{ placeholder: t("Dialog##select") }}
        customStyle={{ marginBottom: "16px" }}
        isDisabled={isLoading}
      />
      <QueryDropdown
        data-testid="edit-user-roles-dropdown"
        resource={ApiResources.UsersRoles}
        idType={ID_TYPE.Id}
        value={selectedRole}
        size="medium"
        disableClearable
        fullWidth
        isRequired
        showSupportRole={showSupportRoleInDropdown}
        change={(_, val) => handleRoleDropdownChange(val)}
        labelLeft={t("Dialog##role")}
        textFieldParams={{ placeholder: t("Dialog##select") }}
        customStyle={{ marginBottom: "16px" }}
        isDisabled={isLoading}
      />
    </>
  );
};

export default EditUserDialogContent;
