import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import { BANNER_MESSAGE_STATUSES } from "../../../../../BannerMessage/types";
import InputField from "../../../../../InputField/InputField";
import QueryDropdown from "../../../../../QueryDropdown/QueryDropdown";
import BannerMessage from "../../../../../BannerMessage/BannerMessage";
import { ApiResources } from "../../../../../../api/resources";
import { INPUT_FIELD } from "../../../../../../Pages/Auth/types";
import {
  EXAMPLE_EMAIL,
  ID_TYPE,
  SelectedOptionObjectType,
  useEmailValidation,
} from "../../../../../../shared";
import ExternalTextLink from "../../../../../../shared/components/textLinks/ExternalTextLink";
import { replaceStringWithSpacesToCamelCase } from "../../../../../../shared/functions/functions";

interface IInviteUserDialogContentProps {
  setPayload: Dispatch<any>;
  setIsReady: Dispatch<SetStateAction<boolean>>;
  isLoading: boolean;
}

const InviteUserDialogContent = ({
  setPayload,
  setIsReady,
  isLoading,
}: IInviteUserDialogContentProps) => {
  const { t } = useTranslation();

  const [
    selectedCompany,
    setSelectedCompany,
  ] = useState<SelectedOptionObjectType | null>(null);
  const [email, setEmail] = useState<string>("");
  const [
    selectedRole,
    setselectedRole,
  ] = useState<SelectedOptionObjectType | null>(null);

  const {
    emailIsValid,
    emailError,
    validateEmail,
    clearEmailError,
  } = useEmailValidation(email);

  const isPayloadReadyToConfirm =
    Boolean(selectedCompany) &&
    Boolean(email) &&
    emailIsValid &&
    Boolean(selectedRole);

  useEffect(() => {
    setPayload({
      email,
      companyId: selectedCompany?.id,
      role:
        // replace readable role into camelCase string to meet back-end requirements for payload
        selectedRole && replaceStringWithSpacesToCamelCase(selectedRole.name),
    });
    setIsReady(isPayloadReadyToConfirm);
  }, [
    email,
    isPayloadReadyToConfirm,
    selectedCompany,
    selectedRole,
    setIsReady,
    setPayload,
  ]);

  const handleCompanyDropdownChange = (chosenValue: any | null) => {
    setSelectedCompany(chosenValue);
  };
  const handleRoleDropdownChange = (chosenValue: any | null) => {
    setselectedRole(chosenValue);
  };

  const handleEmailInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    event.preventDefault();
    setEmail(event.target.value);
    validateEmail(event);
    clearEmailError();
  };

  return (
    <>
      <QueryDropdown
        data-testid="invite-user-company-dropdown"
        resource={ApiResources.CompanyList}
        idType={ID_TYPE.Id}
        value={selectedCompany}
        size="medium"
        fullWidth
        isRequired
        change={(_, val) => handleCompanyDropdownChange(val)}
        labelLeft={t("Dialog##company")}
        textFieldParams={{ placeholder: t("Dialog##select") }}
        customStyle={{ marginBottom: "16px" }}
        isDisabled={isLoading}
      />
      <InputField
        data-testid="invite-user-email-field"
        size="medium"
        id="standard-error-helper-text"
        fullWidth
        required
        name={INPUT_FIELD.Email}
        onBlur={validateEmail}
        errorText={emailIsValid ? "" : emailError}
        onChange={handleEmailInputChange}
        labelLeft={t("Auth##email")}
        placeholder={`${t("Auth##example")} ${EXAMPLE_EMAIL}`}
        customStyle={{ marginBottom: "16px" }}
        value={email}
        disabled={isLoading}
      />
      <QueryDropdown
        data-testid="invite-user-roles-dropdown"
        resource={ApiResources.UsersRoles}
        idType={ID_TYPE.Id}
        value={selectedRole}
        size="medium"
        fullWidth
        isRequired
        change={(_, val) => handleRoleDropdownChange(val)}
        labelLeft={t("Dialog##role")}
        textFieldParams={{ placeholder: t("Dialog##select") }}
        customStyle={{ marginBottom: "16px" }}
        isDisabled={isLoading}
      />
      <BannerMessage
        status={BANNER_MESSAGE_STATUSES.Info}
        title={t("Dialog##selected role info message title")}
        description={
          <Trans
            i18nKey="Dialog##selected role info message"
            components={{
              3: (
                <ExternalTextLink
                  linkUrl={
                    "https://wiki.teltonika-gps.com/view/WEB_Tacho_User_management"
                  }
                  linkText={"Actions##click here"}
                />
              ),
            }}
          />
        }
      />
    </>
  );
};

export default InviteUserDialogContent;
