import { useEffect, useState } from "react";

// Hook to animate dots
const useLoadingDots = (interval = 400) => {
  const [dots, setDots] = useState("");

  useEffect(() => {
    const dotSequence = ["", ".", "..", "..."];
    let index = 0;

    const timer = setInterval(() => {
      setDots(dotSequence[index]);
      index = (index + 1) % dotSequence.length;
    }, interval);

    return () => clearInterval(timer);
  }, [interval]);

  return dots;
};

export default useLoadingDots;
