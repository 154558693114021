/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useRecoilValue } from "recoil";
import { useContext, useState } from "react";
import { useLocation } from "react-router-dom";

import DetailsTitleArea from "./components/DetailsTitleArea";
import useDetailsContent from "./hooks/useDetailsContent";
import CompaniesDetailsContainer from "./components/CompaniesDetailsContainer/CompaniesDetailsContainer";

import { DESKTOP_DETAILS_MARGIN_TOP } from "../../constants";

import Separator from "../../../Separator/Separator";
import { pageGlobalStateObjectResult } from "../../../Table/functions";

import {
  mainBannerHeightState,
  tableDetails,
  tableDetailsLoading,
  tableLoading,
} from "../../../../atoms/atoms";
import { ROUTES, useMediaQueries } from "../../../../shared";
import { ThemeContext } from "../../../../context/theme/ThemeContextProvider";
import useGeneralHeights from "../../../../shared/hooks/useGeneralHeights";

interface IDetailsContentProps {
  onClose?: () => void;
}

const DetailsContent = ({ onClose }: IDetailsContentProps) => {
  const { colors } = useContext(ThemeContext);
  const { fromMd } = useMediaQueries();
  const location = useLocation();
  const mainBannerHeight = useRecoilValue(mainBannerHeightState)

  const {
    renderFileSyncContent,
    renderGeneralDetails,
    renderSchedules,
    renderCompanyCards,
    renderEmailTransferContent,
  } = useDetailsContent();
  const { NAVBAR_HEIGHT } = useGeneralHeights(mainBannerHeight);

  const isDetailsLoading = useRecoilValue(tableDetailsLoading);
  const isTableLoading = useRecoilValue(tableLoading);
  const detailsSectionData = useRecoilValue(tableDetails);

  const [titleAreaHeight, setTitleAreaHeight] = useState<number | null>(0);

  const detailsData = pageGlobalStateObjectResult(
    location.pathname,
    detailsSectionData
  );

  const companiesPage = location.pathname === ROUTES.Companies;

  const desktopDetailsTitleAreHeight = `calc(${titleAreaHeight}px - ${DESKTOP_DETAILS_MARGIN_TOP})`;

  const displayLoader = isDetailsLoading || isTableLoading;
  const detailsContentHeightToMd = `calc(100vh - ${desktopDetailsTitleAreHeight} - ${NAVBAR_HEIGHT})`;

  const detailsContentHeightFromMd = `calc(100vh - ${desktopDetailsTitleAreHeight}  - ${NAVBAR_HEIGHT} - ${DESKTOP_DETAILS_MARGIN_TOP})`;
  const tabsAreaHeight = "60px"; // 44px of tabs height and 16px margin top to tabs area

  // company details content does not have margin bottom
  const companyDetailsContentHeight = `calc(100vh - ${desktopDetailsTitleAreHeight}  - ${NAVBAR_HEIGHT} - ${DESKTOP_DETAILS_MARGIN_TOP} - ${tabsAreaHeight})`;

  const calculateDetailsContentHeight = () => {
    return fromMd ? detailsContentHeightFromMd : detailsContentHeightToMd;
  };

  return (
    <div
      css={css({
        width: `100%`,
        height: "100%",
      })}
    >
      <DetailsTitleArea
        displayLoader={displayLoader}
        detailsData={detailsData}
        onClose={onClose}
        location={location}
        setTitleAreaHeight={setTitleAreaHeight}
      />
      <Separator color={colors.gray200} />

      {companiesPage && detailsData && (
        <CompaniesDetailsContainer
          displayInitialLoader={displayLoader}
          companyId={detailsData.id}
          contentHeight={
            fromMd
              ? companyDetailsContentHeight
              : `calc(${detailsContentHeightToMd} - ${tabsAreaHeight})`
          }
          details={
            <>
              {renderGeneralDetails(detailsData)}
              {renderCompanyCards(detailsData, displayLoader)}
            </>
          }
          fileSyncContent={renderFileSyncContent()}
          emailTransferContent={renderEmailTransferContent}
        />
      )}

      {!companiesPage && (
        <div
          css={css({
            height: calculateDetailsContentHeight(),
            overflowY: "scroll",
          })}
        >
          {renderGeneralDetails(detailsData)}
          {renderSchedules(detailsData, displayLoader)}
        </div>
      )}
    </div>
  );
};

export default DetailsContent;
