import { ReactNode, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { AxiosError } from "axios";
import { useLocation } from "react-router-dom";

import ImportResourceDialogContent from "./ImportResourceDialogContent";

import Dialog from "../../../../../Dialog/Dialog";
import { DialogWidth, FILE_NAME } from "../../../../../Dialog/types/enums";
import useTableItemDetails from "../../../../../DetailsSection/hooks/useTableItemdetails";
import { FILE_ROW_LIMIT } from "../../../../../Dropzone/constants";

import {
  FORM_DATA_KEYS,
  GeneralDialogProps,
  useMediaQueries,
  DRIVERS_ACTIONS,
  getValueInSearchField,
  TABLE_NAMES,
  VEHICLES_ACTIONS,
} from "../../../../../../shared";
import useResponse from "../../../../../../shared/hooks/useResponse";
import DialogActions from "../../../../../../shared/components/buttons/DialogActions";
import DropzoneContext from "../../../../../../context/dropzone/dropzoneContext";
import useAxiosPrivate from "../../../../../../api/hooks/useAxiosPrivate";
import { ApiResources } from "../../../../../../api/resources";
import { ALERT_STATUS } from "../../../../../../context/alert/types";
import useBannerFunctions from "../../../../../../shared/hooks/useBannerFunctions";

export type ImportFileErrorMessagetype = {
  title: string;
  description: string | ReactNode;
  collapsedDescription?: string | ReactNode;
};

interface ImportResourceDialogProps {
  tableName: TABLE_NAMES;
}

interface ResourceValue {
  action: VEHICLES_ACTIONS | DRIVERS_ACTIONS;
  apiResource: ApiResources;
  fileName: FILE_NAME;
  formDataKey: FORM_DATA_KEYS;
  tooltipText: string;
}

const ImportResourceDialog = ({
  tableName,
  isOpen,
  onClose,
}: ImportResourceDialogProps & GeneralDialogProps) => {
  const { file, lineCount, removeAllFiles } = useContext(DropzoneContext);

  const { postData } = useAxiosPrivate();
  const { t, i18n } = useTranslation();

  const {
    generateErrorBannerEntries,
    maxErrorInitialItemsCount,
    generateCollapsedDescription,
    generateBannerDescriptionEntries,
  } = useBannerFunctions({ i18n, t });

  const location = useLocation();

  const { toMd } = useMediaQueries();

  const { getTableItemDetails } = useTableItemDetails();

  const { handleResponse } = useResponse();

  const valueInSearchField = getValueInSearchField(location);

  const [isLoading, setIsLoading] = useState(false);
  const [
    errorBannerMessage,
    setErrorbannerMessage,
  ] = useState<ImportFileErrorMessagetype | null>(null);
  const [showMoreText, setShowMoreText] = useState<boolean>(false);
  const [showExpandErrorsLink, setShowExpandErrorsLink] = useState<boolean>(
    false
  );

  const resourceValues: { [key: string]: ResourceValue } = {
    [TABLE_NAMES.Vehicles]: {
      action: VEHICLES_ACTIONS.ImportVehicle,
      apiResource: ApiResources.VehiclesImport,
      fileName: FILE_NAME.SampleVehiclesImportList,
      formDataKey: FORM_DATA_KEYS.VehiclesFiles,
      tooltipText: `${t("Dropzone##file syntax")} ${t("Dialog##imei")}*, ${t(
        "Dialog##vehicle number"
      )}*, ${t("Dialog##description")}
  `,
    },
    [TABLE_NAMES.Drivers]: {
      action: DRIVERS_ACTIONS.ImportDriver,
      apiResource: ApiResources.DriversImport,
      fileName: FILE_NAME.SampleDriversImportList,
      formDataKey: FORM_DATA_KEYS.DriversFiles,
      tooltipText: ` ${t("Dropzone##file syntax")} ${t("Dialog##name")}*, ${t(
        "Dialog##driver id"
      )}*, ${t("Dialog##description")}
  `,
    },
  };

  const resourceValueFromTableName = resourceValues[tableName];

  const handleCloseImportResourceDialog = () => {
    onClose(resourceValueFromTableName.action);
    setErrorbannerMessage(null);
  };

  const getFormData = () => {
    const formData = new FormData();
    formData.append(resourceValueFromTableName.formDataKey, file as Blob);
    return formData;
  };

  const isReady =
    file && lineCount > 0 && lineCount <= FILE_ROW_LIMIT && !errorBannerMessage;

  const handleSubmit = async () => {
    if (isReady) {
      try {
        setIsLoading(true);
        const formData = getFormData();

        await postData(resourceValueFromTableName.apiResource, formData);

        handleCloseImportResourceDialog();

        getTableItemDetails({
          itemId: null,
          successMessage: t("Alert##successfully imported", {
            resourceString: t(`General##${tableName}`),
          }),
          leaveTableDetailsNotUpdated: true,
          valueInSearchField: valueInSearchField,
        });
      } catch (error) {
        const err = error as AxiosError<any>;
        const errorEntries = err.response?.data?.entries;
        const displayShowMoreText =
          errorEntries?.length > maxErrorInitialItemsCount;
        setShowExpandErrorsLink(displayShowMoreText);

        setErrorbannerMessage({
          title: t("Alert##error##import failed"),
          description: generateBannerDescriptionEntries(
            generateErrorBannerEntries,
            errorEntries
          ),
          collapsedDescription: generateBannerDescriptionEntries(
            generateCollapsedDescription,
            errorEntries
          ),
        });

        return handleResponse(
          ALERT_STATUS.Critical,
          t("Alert##error##import failed")
        );
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <Dialog
      title={t(`Dialog##import ${tableName}`)}
      description={t(`Dialog##import ${tableName} description`)}
      data-testid={`import-${tableName}-dialog`}
      extendToMaxWidth={toMd}
      isTitleSeparator
      isActionsSeparator
      open={isOpen}
      close={handleCloseImportResourceDialog}
      submit={handleSubmit}
      width={!toMd ? DialogWidth.MinWidth : undefined}
      TransitionProps={{
        onExited: () => {
          removeAllFiles();
        },
      }}
      actions={
        <DialogActions
          onClose={handleCloseImportResourceDialog}
          isLoading={isLoading}
          isreadyToConfirm={isReady}
          confirmationButtonText={t("Button##add")}
          confirmationButtonTestId={`confirm-import-${tableName}-button`}
          cancelButtonText={t("Button##close")}
          cancelButtonTestId={`close-import-${tableName}-dialog-button`}
        />
      }
    >
      <ImportResourceDialogContent
        fileName={resourceValueFromTableName.fileName}
        tooltipText={resourceValueFromTableName.tooltipText}
        errorBannerMessage={errorBannerMessage}
        setErrorbannerMessage={setErrorbannerMessage}
        showMoreText={showMoreText}
        setShowMoreText={setShowMoreText}
        expandableBanner={showExpandErrorsLink}
        disabledDropzone={isLoading}
      />
    </Dialog>
  );
};

export default ImportResourceDialog;
