/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Grid } from "@mui/material";
import { useRecoilValue } from "recoil";

import TableView from "./TableView/TableView";
import { useStyles } from "../../styles/styles";
import Search from "../../../Search/Search";
import Filters from "../../../Filters/Filters";
import FilteredValues from "../../../Filters/components/FilteredValues";
import FilterSeparator from "../../../FilterSeparator/FilterSeparator";
import { useMediaQueries } from "../../../../shared";
import { filtersState, mainBannerHeightState } from "../../../../atoms/atoms";
import useInitialLoading from "../../../../shared/hooks/useInitialLoading";
import useGeneralHeights from "../../../../shared/hooks/useGeneralHeights";

interface ItableToolbarProps {
  toMd: boolean;
  hasTableView?: boolean;
  hasSearch?: boolean;
}

const TableToolbar = ({
  toMd,
  hasTableView,
  hasSearch = true,
}: ItableToolbarProps) => {
  const { fromSm } = useMediaQueries();
  const { isInitialLoading } = useInitialLoading();

  const mainBannerHeight = useRecoilValue(mainBannerHeightState);

  const { TABLE_TOOLBAR_PADDINGS } = useGeneralHeights(mainBannerHeight);

  const hideFilters = useRecoilValue(filtersState);

  const { tableToolbar, tableViewGridCSS } = useStyles(undefined, toMd);
  const showSeparator = fromSm && hasSearch && !hideFilters;

  return (
    <>
      <Grid
        container
        justifyContent="flex-end"
        data-testid="table-toolbar"
        css={css({ padding: TABLE_TOOLBAR_PADDINGS(toMd, hasTableView) })}
      >
        <Grid item xs={12} md={7} lg={6} css={css(tableToolbar)}>
          {hasSearch && <Search />}

          {showSeparator && (
            <FilterSeparator
              isInitialLoading={isInitialLoading}
              width={1}
              height={36}
            />
          )}

          {!hideFilters && <Filters />}
        </Grid>

        <Grid item xs={12} md={5} lg={6} css={css(tableViewGridCSS)}>
          {hasTableView && <TableView />}
        </Grid>
      </Grid>
      {<FilteredValues />}
    </>
  );
};

export default TableToolbar;
