import { useEffect } from "react";
import { useRecoilState } from "recoil";

import { VEHICLES_TABLE_COLUMNS } from "./config";

import PageWrapperWithDetails from "../wrappers/PageWrapperWithDetails";

import VehiclesHeaderActions from "../../components/PageHeader/actions/pageHeaderActions/VehiclesHeaderActions/VehiclesHeaderActions";
import Table from "../../components/Table/Table";
import { ROUTES, TABLE_NAMES, VEHICLES_ACTIONS } from "../../shared";
import LayoutWrapper from "../../components/Layout/LayoutWrapper";
import useTableData from "../../components/Table/hooks/useTableData";
import useVehicleInlineActions from "../../components/Table/actions/inlineActions/hooks/useVehicleInlineActions";
import EditVehicleDialog from "../../components/Table/actions/dialogs/Vehicles/EditVehicleDialog/EditVehicleDialog";
import { isTableCheckable } from "../../atoms/atoms";
import { useAuth } from "../../context/Auth/AuthProvider";
import { USER_PERMISSIONS } from "../../roles/types/enums";
import DownloadMonitoringDialog from "../../components/DetailsSection/components/DetailsContent/components/DownloadMonitoringDialog/DownloadMonitoringDialog";

export const mapVehiclesTableRowData = (data: any) =>
  data.map((dataItem: any) => {
    const schedules = dataItem.scheduleSummaries?.map(
      (schedule: { name: string; scope: number }) => schedule.name
    );

    return {
      ...dataItem,
      schedulesList: schedules,
    };
  });

const Vehicles = () => {
  const { getAccessStatus } = useAuth();

  useTableData(mapVehiclesTableRowData);
  const {
    inlineActions,
    rowData,
    isEditOpen,
    closeEditDialog,
    isDialogOpen,
    handleCloseDownloadStatusDialog,
  } = useVehicleInlineActions();

  const [, setIsCheckable] = useRecoilState(isTableCheckable);

  const allowedToManageVehicles = getAccessStatus(
    USER_PERMISSIONS.ManageVehicles
  );

  useEffect(() => {
    setIsCheckable(allowedToManageVehicles);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <PageWrapperWithDetails
        pathname={ROUTES.Vehicles}
        currTableColumns={VEHICLES_TABLE_COLUMNS}
        currTableName={TABLE_NAMES.Vehicles}
        inlineActions={allowedToManageVehicles ? inlineActions : []}
      >
        <LayoutWrapper showDetailsSection>
          {allowedToManageVehicles && <VehiclesHeaderActions />}
        </LayoutWrapper>
        <Table hasDetails hideFilters={false} hasTableView />
      </PageWrapperWithDetails>

      {/* inline action dialog */}
      {rowData && (
        <>
          <DownloadMonitoringDialog
            open={isDialogOpen[VEHICLES_ACTIONS.DownloadMonitoring]}
            onClose={handleCloseDownloadStatusDialog}
            detailsData={rowData as any}
          />
          <EditVehicleDialog
            isOpen={isEditOpen}
            onClose={closeEditDialog}
            rowData={rowData}
          />
        </>
      )}
    </>
  );
};

export default Vehicles;
