import { Location } from "react-router-dom";

import {
  detailsAndDrawerLayoutState,
  GenerateDesktopTableHeightFunctionType,
} from "./types";

import { ROUTES, TableRowData, TABLE_NAMES } from "../../shared";
import {
  FEATURE_NAMES,
  ReleaseFeaturesNames,
} from "../../shared/featureToggles/types";
import { replaceCamelCaseStringToSeparateWords } from "../../shared/functions/functions";
import { USER_ROLES_RAW_DATA } from "../../roles/types/enums";

export const deselectAllRows = (setSelectedRows: any) => {
  setSelectedRows([]);
};

export const deselectSelectedVisibleRows = (
  setSelectedRows: (val: any) => void,
  tableRows: TableRowData[]
) => {
  setSelectedRows((prev: any) =>
    prev.concat(tableRows.map((row: TableRowData) => row.id))
  );
};

export const selectAllVisibleRows = (
  setSelectedRows: (val: any) => void,
  visibleTableItemsIdArray: (string | number)[]
) => {
  setSelectedRows((prev: number[] | string[]) =>
    [...prev].filter(
      (item: number | string) =>
        !visibleTableItemsIdArray.includes(item as string)
    )
  );
};

export const deselectSingleRow = (
  setSelectedRows: any,
  id: number | string | undefined
) => {
  setSelectedRows((prev: number[] | string[]) =>
    [...prev].filter((item: number | string) => item !== id)
  );
};

export const selectSingleRow = (
  setSelectedRows: any,
  id: number | string | undefined
) => {
  setSelectedRows((prev: any) => [...prev, id]);
};

// The function below sets page params global state object values (currPage, searchParams)
export const getPageGlobalStateObjectValue = (
  pathname: string,
  prevState: any,
  value: any
) => {
  switch (pathname) {
    case ROUTES.Vehicles:
      return {
        ...prevState,
        vehiclesPage: value,
      };

    case ROUTES.Drivers:
      return {
        ...prevState,
        driversPage: value,
      };

    case ROUTES.VehicleFiles:
      return {
        ...prevState,
        vehicleFilesPage: value,
      };

    case ROUTES.DriverFiles:
      return {
        ...prevState,
        driverFilesPage: value,
      };

    case ROUTES.Users:
      return {
        ...prevState,
        usersPage: value,
      };

    case ROUTES.UsersInvitations:
      return {
        ...prevState,
        usersInvitationsPage: value,
      };

    case ROUTES.Companies:
      return {
        ...prevState,
        companiesPage: value,
      };

    default:
      return { ...prevState };
  }
};

// The function below returns value of page params global state object (currPage, pageToShow, rowsPerPage, searchParams, detailsSectionData)
export const pageGlobalStateObjectResult = (
  pathname: string,
  stateName: any
) => {
  switch (pathname) {
    case ROUTES.Vehicles:
      return stateName.vehiclesPage;
    case ROUTES.Drivers:
      return stateName.driversPage;
    case ROUTES.VehicleFiles:
      return stateName.vehicleFilesPage;
    case ROUTES.DriverFiles:
      return stateName.driverFilesPage;
    case ROUTES.Users:
      return stateName.usersPage;
    case ROUTES.UsersInvitations:
      return stateName.usersInvitationsPage;
    case ROUTES.Companies:
      return stateName.companiesPage;
    default:
      return null;
  }
};

export const generateDesktopTableHeight = ({
  hasTabFilters,
  toMd,
  toLg,
  hasSearch,
  hasFilteredValues,
  companiesPage,
  filtersHeightValue,
  PAGE_HEADER_HEIGHT,
  NAVBAR_HEIGHT,
  TAB_FILTERS_HEIGHT,
  TABLE_TOOLBAR_HEIGHT,
  DESKTOP_TABLE_FOOTER_HEIGHT,
}: GenerateDesktopTableHeightFunctionType) => {
  if (hasTabFilters) {
    if (hasFilteredValues) {
      return `calc(100vh - ${PAGE_HEADER_HEIGHT} - ${NAVBAR_HEIGHT} - ${TAB_FILTERS_HEIGHT} - ${TABLE_TOOLBAR_HEIGHT(
        toMd,
        hasSearch
      )} - ${DESKTOP_TABLE_FOOTER_HEIGHT(toLg)} - ${filtersHeightValue}px)`;
    }
    return `calc(100vh - ${PAGE_HEADER_HEIGHT} - ${NAVBAR_HEIGHT} - ${TAB_FILTERS_HEIGHT} - ${TABLE_TOOLBAR_HEIGHT(
      toMd,
      hasSearch
    )} - ${DESKTOP_TABLE_FOOTER_HEIGHT(toLg)})`;
  }
  if (companiesPage) {
    return `calc(100vh - ${PAGE_HEADER_HEIGHT} - ${NAVBAR_HEIGHT} - ${TABLE_TOOLBAR_HEIGHT(
      toMd,
      hasSearch
    )})`;
  }

  if (hasFilteredValues) {
    return `calc(100vh - ${PAGE_HEADER_HEIGHT} - ${NAVBAR_HEIGHT} - ${TABLE_TOOLBAR_HEIGHT(
      toMd,
      hasSearch
    )} - ${DESKTOP_TABLE_FOOTER_HEIGHT(toLg)} - ${filtersHeightValue}px)`;
  }

  return `calc(100vh - ${PAGE_HEADER_HEIGHT} - ${NAVBAR_HEIGHT} - ${TABLE_TOOLBAR_HEIGHT(
    toMd,
    hasSearch
  )} - ${DESKTOP_TABLE_FOOTER_HEIGHT(toLg)})`;
};

export const getSelectableRows = (
  name: TABLE_NAMES,
  rows: any[],
  user: any | null,
  isProductionEnv: boolean,
  isReleaseFeatureEnabledForUser: (
    featureName: ReleaseFeaturesNames,
    userRole: string
  ) => any
) => {
  if (name === TABLE_NAMES.Users) {
    const isUserHimself = (resourceId: string) => user?.id === resourceId;

    const getRowsBasedOnEnv = (id: string, role: string) => {
      const supportRole = replaceCamelCaseStringToSeparateWords(
        USER_ROLES_RAW_DATA.TeltonikaSupport
      );

      const isAllowedFullControlOfUsers = isReleaseFeatureEnabledForUser(
        FEATURE_NAMES.AllowedFullControlOfUsers,
        user?.email
      );

      if (isProductionEnv) {
        const isNotSupportUser = role !== supportRole;

        return isAllowedFullControlOfUsers
          ? !isUserHimself(id)
          : !isUserHimself(id) && isNotSupportUser;
      }

      return !isUserHimself(id);
    };

    return rows?.filter(({ id, role }: any) => getRowsBasedOnEnv(id, role));
  }
  if (name === TABLE_NAMES.Companies) {
    return rows?.filter(({ level }: any) => level !== 0);
  }

  return rows;
};

export const isRowDisabled = (selectableRows: any, rowData: any) =>
  !selectableRows.some((selectableRow: any) => selectableRow.id === rowData.id);

export const asDetailsAndDrawerLayoutState = (
  isDetailsOpen: boolean,
  isDesktopDrawerOpen: boolean
) => {
  if (isDetailsOpen && isDesktopDrawerOpen) {
    return detailsAndDrawerLayoutState.BothOpen;
  } else if (isDetailsOpen && !isDesktopDrawerOpen) {
    return detailsAndDrawerLayoutState.DetailsOnly;
  } else if (isDesktopDrawerOpen && !isDetailsOpen) {
    return detailsAndDrawerLayoutState.DrawerOnly;
  } else {
    return detailsAndDrawerLayoutState.BothClosed;
  }
};

export const getValuesFromObjectArray = (obj: any) =>
  Object.keys(obj).map((key) => obj[key]);

export const currentTableNameDueToRouteName = (
  currentTableName: TABLE_NAMES | undefined,
  location: Location
) => {
  if (currentTableName === TABLE_NAMES.Files) {
    return location.pathname === ROUTES.VehicleFiles
      ? ROUTES.VehicleFiles.slice(1)
      : ROUTES.DriverFiles.slice(1);
  } else {
    return currentTableName;
  }
};
