import { CSSObject } from "@emotion/react";

interface StylesInterface {
  maxHeight?: string;
}

export const useStyles = ({ maxHeight }: StylesInterface) =>
  ({
    dialogTitleWithTooltip: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
    dialogContentWrapper: {
      maxHeight: maxHeight,
      overflowY: "scroll",
      marginRight: "-16px",
      scrollbarGutter: "stable",
      paddingRight: "6px",
    },
    separator: {
      margin: "0",
      padding: "0",
      width: "100%",
      height: "1px",
      zIndex: 10,
    },
    actionButtons: {
      display: "grid",
      gridAutoFlow: "column",
      gridAutoColumns: "1fr",
      padding: "12px 16px 16px",
      columnGap: "12px",
      rowGap: "12px",
    },
  } as CSSObject);
